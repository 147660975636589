import { createStore, combineReducers } from "redux";
import AccessReducer from "./AccessReducer";
import ApplicationReducer from "./ApplicationReducer";
import PCPReducer from "./PCPReducer";
import SPMetadataReducer from "./SPMetadataReducer";
import SPReducer from "./SPReducer";
const isDev = () => {
    return window.location.href.indexOf("localhost") > -1;
}
const productionModeReducer = () => {
    return isDev()
}

const projectdetail = {
    "API": (isDev() ? "https://localhost:7067/api" : "/api"),
    "ProjectName": "Integrated Community Collaborative",
    "ProjectLogo": <img src="../logo.png" className="img-fluid logo" alt="Logo" height="80" />,
    "PoweredBy": "Bow and Baan",
    "PoweredByLink": "https://www.bowandbaan.com/",
    "ErrorMessage": "Something went wrong",
    "maxFileSize": 1048576 * 3,
};

const projectReducer = (detail = projectdetail, actions) => {
    return detail;
}

const rootReducer = combineReducers(
    {
        access: AccessReducer,
        project: ApplicationReducer,
        projectdetail: projectReducer,
        IsDevMode: productionModeReducer,
        pcp: PCPReducer,
        sp: SPReducer,
        spmetadata: SPMetadataReducer,
    }
);
export const store = createStore(rootReducer);
