import { memo, useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MultiSelectMasterData from "../../controls/MultiSelectMasterData";
import Icon from "../../controls/Icons/Icon";
import { PCPAction } from "../../../Reducer/PCPReducer";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";

const PCPHopeDreams = () => {
    const { fetchData } = useFetchWithCancellation();
    const navigate = useNavigate();
    const { id } = useParams();
    const masterDataType = 6;
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const masterDataRef = useRef();
    const formatAddParams = (params) => {
        params.fkMasterDataTypeId = masterDataType;
        return params;
    }
    const dispatch = useDispatch();
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    useEffect(() => {
        if (savedSuccessfully && !state.pcp.hasUnsavedChanges) {
            navigate("/admin/pcpform/" + id + "/goals");
        }
    }, [savedSuccessfully, state.pcp.hasUnsavedChanges]);
    const SaveData = (e, movenext) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        fetchData(state.projectdetail.API + "/pcp/SaveHopeDreams?clientId=" + id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(masterDataRef.current.getSelectedIds())
        }).then(response => response.json()).then((res) => {
            if (res.Succeeded) {
                window.toast(res.Message);
                setSavedSuccessfully(movenext);
                dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
            }
            else {
                window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("PCPHopeDreams.js ==> SaveData", null, null, err);
            window.alert(state.projectdetail.ErrorMessage);
        }).finally(() => {
            setLoading(false);
        });
    }
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/pcp/GetHopeDreams?clientId=" + id)
            .then(response => response.json()).then((res) => {                
                if (res && Array.isArray(res) && res.length > 0) {
                    let ids = res.map(function (row) {
                        return row.fkHopeDreamsId
                    });                    
                    masterDataRef.current.setSelected(ids);                    
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPHopeDreams.js ==> GetHopeDreams", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate>
            <Row>
                <Col>
                    <h5 className="d-inline-block">Hopes & Dreams</h5>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {loading ? <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
                    <MultiSelectMasterData ref={masterDataRef}
                        name="HopesDreams"
                        dataURL={state.projectdetail.API + "/masterdata/getall?type=" + masterDataType}
                        fetchType="GET"
                        labelField="Text"
                        valueField="Id"
                        addNewURL={state.projectdetail.API + "/masterdata/save"}
                        addNewMethodType="POST"
                        formatAddParams={formatAddParams}
                        onCheckBoxChange={() => { dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true }); }}
                    />
                </Col>
                <Col>

                </Col>
            </Row>
            <Row className="mb-2">
                <Col>


                </Col>

            </Row>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={loading} className="float-end">
                        Save & Next
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, false)} disabled={loading} className="float-end me-2">
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ErrorBoundary>
    )
}
export default (memo(PCPHopeDreams))