import React, { useState } from 'react';
import { useEffect } from 'react';
import { ToastContainer, Toast } from 'react-bootstrap'
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../ErrorHandler';


const ToastPrompt = ({ options }) => {
    if (!options.position)
        options.position = "bottom-center";
    if (!options.variant)
        options.variant = "Dark";
    const [show, setShow] = useState(options.show);
    useEffect(() => {
        setShow(options.show);
    }, [options.show])
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <ToastContainer className="position-fixed p-3" position={options.position}>
                <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide bg={options.variant.toLowerCase()}>
                    <Toast.Body className={options.variant === 'Dark' && 'text-white'}>{options.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </ErrorBoundary>
    );
}

export default (ToastPrompt);
