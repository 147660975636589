import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';

import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import Icon from '../../controls/Icons/Icon';
import ServerError from '../../controls/ServerError';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
function SpendingPlanCategoryForm({ _inputValue, afterSave, onCancel }) {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        Id: 0,
        ServiceName: "",
        ServiceCode: "",
        fkSPCategoryId: ""
    });
    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);

    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetchData(state.projectdetail.API + "/SPCategoryDetail/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputValue)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);

                    setInputValue({
                        Id: 0,
                        ServiceName: "",
                        ServiceCode: "",
                        fkSPCategoryId: ""
                    });
                    setInputValue(res);

                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("SpendingPlanCategoryForm.js ==> handleSubmit", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    })


    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Spending Plan Category Name<code className='required-symbol'>*</code></Form.Label>
                            <fieldset disabled={inputValue.Id}>
                                <DynamicDropdownSearchable className="form-control"
                                    name="fkSPCategoryId"
                                    defaultval={inputValue.fkSPCategoryId}
                                    addAttr={["fkSPCategoryId"]}
                                    onDropdownChange={handleChange}
                                    required={true} from={state.projectdetail.API + "/SPCategory/GetAll"}
                                    label="CategoryName" value="Id" fetchType="get" />
                            </fieldset>
                            {validated && !inputValue.fkSPCategoryId && <div><small className="text-danger">Spending Plan Category Name is required</small></div>}

                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Service Code<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required={true} type="number" max={99999} min={0} maxLength={5} name="ServiceCode" value={inputValue.ServiceCode} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Service Code is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Service Name<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required={true} type="text" name="ServiceName" value={inputValue.ServiceName} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Service Name is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>

            </Row>

            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                        Cancel
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={loading}>
                        Submit
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ErrorBoundary>
    )
}
export default (SpendingPlanCategoryForm);