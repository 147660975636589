import { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Spinner, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PhotoCropper from '../../controls/FileUpload/PhotoCropper';
import Icon from '../../controls/Icons/Icon';
import "./PCPProfilePicture.css";
import { PCPAction } from '../../../Reducer/PCPReducer';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
function PCPProfilePicture() {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);

    const navigate = useNavigate();
    const { id } = useParams();
    const picFolderPath = "client/" + id + "/pcp/profilepic/";
    const picphoto1Ref = useRef(null);
    const picphoto2Ref = useRef(null);
    const picphoto3Ref = useRef(null);
    const picphoto4Ref = useRef(null);
    const col1Ref = useRef(null);
    const col2Ref = useRef(null);
    const col3Ref = useRef(null);
    const col4Ref = useRef(null);
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({ Id: 0, fkClientId: id });
    const dispatch = useDispatch();
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    useEffect(() => {
        if (savedSuccessfully && !state.pcp.hasUnsavedChanges) {
            navigate("/admin/pcpform/" + id + "/wordsthatdescribeclientandinterests");
        }
    }, [savedSuccessfully, state.pcp.hasUnsavedChanges]);
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const SaveData = async (e, movenext) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        let obj = inputValue;
        obj.fkClientId = id;
        fetchData(state.projectdetail.API + "/pcp/SaveProfilePicture", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(obj)
        }).then(response => response.json()).then((res) => {
            if (res.Succeeded) {
                dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
                setSavedSuccessfully(movenext);
                setValidated(false);
                window.toast(res.Message);
            }
            else {
                window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("PCPProfilePicture.js ==> SaveData", null, null, err);
            window.alert(state.projectdetail.ErrorMessage);
        }).finally(() => {
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchData(state.projectdetail.API + "/pcp/GetProfilePicture?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    let profilePicture = res[0];
                    setInputValue(profilePicture);
                    if (profilePicture.PPic1) {
                        picphoto1Ref.current.setUploadedFile(profilePicture.PPic1);
                    }
                    if (profilePicture.PPic2) {
                        picphoto2Ref.current.setUploadedFile(profilePicture.PPic2);
                    }
                    if (profilePicture.PPic3) {
                        picphoto3Ref.current.setUploadedFile(profilePicture.PPic3);
                    }
                    if (profilePicture.PPic4) {
                        picphoto4Ref.current.setUploadedFile(profilePicture.PPic4);
                    }
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPProfilePicture.js ==> GetProfilePicture", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const formatSaveParams = (filename, fieldname) => {
        return {
            ...inputValue, [fieldname]: filename, fkClientId: id
        }
    }
    const afterSave = (filename, fieldname) => {
        setInputValue({ ...inputValue, [fieldname]: filename });
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <div ref={containerRef} style={{ position: "relative" }} >
                <Row>
                    <Col>
                        <h5 className="d-inline-block">Profile Page</h5>
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-end  mb-4">
                    <Col xs={"auto"}>
                        <Row className="justify-content-center align-items-top">
                            <Col className="profile-callout right bottom justify-content-center align-items-center">
                                <Form.Control placeholder="About this image" maxLength={100} rows={7} as="textarea" type="text" name="Text1" value={inputValue.Text1} onChange={handleChange} autoComplete="off" />
                            </Col>
                            <Col xs={"auto"}>
                                <PhotoCropper
                                    ref={picphoto1Ref} height={300} width={300} roundedCrop={true} uploadFolderPath={picFolderPath}
                                    autoSave={true}
                                    saveURL={state.projectdetail.API + "/pcp/SaveProfilePicture"}
                                    afterSave={(filename) => { afterSave(filename, "PPic1") }}
                                    formatSaveParams={(filename) => { return formatSaveParams(filename, "PPic1") }}
                                />
                            </Col>

                        </Row>
                    </Col>
                    <Col xs={"auto"}>
                        <Row className="justify-content-center align-items-start">
                            <Col xs={"auto"}>
                                <PhotoCropper ref={picphoto2Ref} height={250} width={250} roundedCrop={true} uploadFolderPath={picFolderPath}
                                    autoSave={true}
                                    saveURL={state.projectdetail.API + "/pcp/SaveProfilePicture"}
                                    afterSave={(filename) => { afterSave(filename, "PPic2") }}
                                    formatSaveParams={(filename) => { return formatSaveParams(filename, "PPic2") }}
                                />
                            </Col>
                            <Col className="profile-callout left bottom">
                                <Form.Control placeholder="About this image" rows={7} maxLength={100} as="textarea" type="text" name="Text2" value={inputValue.Text2} onChange={handleChange} autoComplete="off" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-end">
                    <Col xs={"auto"}>
                        <Row className="justify-content-center align-items-end">
                            <Col className="profile-callout right top">
                                <Form.Control placeholder="About this image" rows={7} maxLength={100} as="textarea" type="text" name="Text3" value={inputValue.Text3} onChange={handleChange} autoComplete="off" />
                            </Col>
                            <Col xs={"auto"}>
                                <PhotoCropper ref={picphoto3Ref} height={250} width={250} roundedCrop={true} uploadFolderPath={picFolderPath}
                                    autoSave={true}
                                    saveURL={state.projectdetail.API + "/pcp/SaveProfilePicture"}
                                    afterSave={(filename) => { afterSave(filename, "PPic3") }}
                                    formatSaveParams={(filename) => { return formatSaveParams(filename, "PPic3") }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={"auto"}>
                        <Row className="justify-content-center align-items-end">
                            <Col xs={"auto"}>
                                <PhotoCropper ref={picphoto4Ref} height={300} width={300} roundedCrop={true} uploadFolderPath={picFolderPath}
                                    autoSave={true}
                                    saveURL={state.projectdetail.API + "/pcp/SaveProfilePicture"}
                                    afterSave={(filename) => { afterSave(filename, "PPic4") }}
                                    formatSaveParams={(filename) => { return formatSaveParams(filename, "PPic4") }}
                                />
                            </Col>
                            <Col className="justify-content-center align-items-center profile-callout left top">
                                <Form.Control placeholder="About this image" rows={7} maxLength={100} as="textarea" type="text" name="Text4" value={inputValue.Text4} onChange={handleChange} autoComplete="off" />
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row className="mb-2  mb-bottom fixed-bottom" >
                    <Col xs={12} className="text-center">
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={loading} className="float-end">
                            Save & Next
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, false)} disabled={loading} className="float-end me-2">
                            Save
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}
export default (PCPProfilePicture);