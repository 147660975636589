import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap'

import Icon from '../Icons/Icon';
import { ErrorBoundary } from 'react-error-boundary';
import "./AlertPrompt.css";
import ErrorHandler from '../ErrorHandler';
const AlertPrompt = ({ options }) => {
    if (!options.ButtonText) {
        options.ButtonText = "Ok";
    }
    const handleOk = () => {
        window.hideAlert();
        if (typeof options.onYes == "function") {
            options.onYes();
        }
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Modal show={options.show} className={"alert-message " + options.varient} centered backdrop="static">
                <Modal.Body>
                    <Row className='mb-3'>
                        <Col className='alert-message-icon'>
                            {options.icon ? options.icon : <Icon name="BsCheckCircleFill" />}
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className='alert-message-message'>
                            {options.message}
                        </Col>
                    </Row>
                    {options.additionalcontent ? <Row className='mb-3'>
                        <Col className='alert-message-message'>
                            {options.additionalcontent}
                        </Col>
                    </Row> : ""}
                    <Row className='mb-3'>
                        <Col className='alert-message-buttons'>
                            <Button onClick={handleOk}>{options.ButtonText}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </ErrorBoundary>
    );
}

export default (AlertPrompt);
