export const SPMetadataAction = {
    setSPMetadata: "SETSPMETADATA",
};
const initialSPMetadata = {
    services: [],
    serviceLoading: false,
    serviceError: null,

    providers: [],
    providerLoading: false,
    providerError: null,

    units: [],
    unitLoading: false,
    unitError: null,

    unitPers: [],
    unitPerLoading: false,
    unitPerError: null,

    stateTaxs: [],
    stateTaxLoading: false,
    stateTaxError: null,

    employeeBurden: 0,
};

const SPMetadataReducer = (pcp = initialSPMetadata, actions) => {
    switch (actions.type) {
        case SPMetadataAction.setSPMetadata:
            return { ...pcp, ...actions.payload };
        default:
            return pcp;
    }
}
export default SPMetadataReducer;