import { memo } from "react";
import { Form } from "react-bootstrap";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../ErrorHandler";
const FormMobileNo = ({ name, required, errorMessage, onChange, value, ...otherProps }) => {
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form.Control
                pattern='^[1-9]\d{9}$'
                required={required}
                type="tel"
                onWheel={(e) => e.target.blur()} maxLength="10"
                name={name}
                value={value}
                onChange={onChange}
                autoComplete="off" {...otherProps} />
            <Form.Control.Feedback type="invalid">{errorMessage ? errorMessage : "Number is not valid"}</Form.Control.Feedback>
        </ErrorBoundary>
    )
}
export default (memo(FormMobileNo));