import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import './Home.css';
import ErrorHandler from '../../controls/ErrorHandler';
const Home = () => {

    const navigate = useNavigate();
    const login = (() => {
        navigate("/login");
    });
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Container className='home mt-3'>

                <Row className='d-flex min-vh-100 align-items-center'>
                    <Col md={12} className="text-center">
                        <Card>
                            <Card.Body>
                                <div className="mb-3">
                                    <img src="./logo.png" className="img-fluid home-img" />
                                </div>
                                <h1 className='mb-3 color-blue'>EXPRESS WAY - SDP</h1>
                                <h3 className='mb-3 text-uppercase color-blue'>Helping Individuals with Intellectual Disabilities</h3>
                                <div className='mb-4'>
                                    <Button onClick={login}>Log in</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </ErrorBoundary>
    );
}

export default (Home);
