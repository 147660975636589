
import { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Spinner, Button, Form, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import HTMLFlipBook from 'react-pageflip';
import { useEffect } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Icon from '../../controls/Icons/Icon';
import { fnGetFileNameFromContentDispostionHeader, getFileNamewithDate, validateFile } from '../../../Utils';
import "./PCPFlipbook.css";
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PCPFlipbook() {
    const { fetchData } = useFetchWithCancellation();
    const { id } = useParams();
    const [numPages, setNumPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [downloading, setUploading] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [PCPLink, setPCPLink] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [inputValue, setInputValue] = useState({});
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const uploadRef = useRef(null);
    const bookRef = useRef(null);
    const folderPath = "client/" + id + "/pcp/flipbook/";
    const formRef = useRef(null);
    const [pageWidth, setPageWidth] = useState(500);
    const [pageHeight, setPageHeight] = useState(705);
    const [pdfURL, setPdfURL] = useState(state.projectdetail.API + "/PCP/PreviewPDF?Id=" + id);


    /*const [pdfAvailable, setPdfAvailable] = useState(false);*/
    async function onDocumentLoadSuccess(docu) {
        if (docu.numPages > 0) {
            let firstPage = await docu.getPage(1);
            if (firstPage) {
                setPageHeight(firstPage.view[3]);
                setPageWidth(firstPage.view[2] + 50);
            }
        }
        setNumPages(docu.numPages);
        setLoading(false);
    }

    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        let arrPages = [];
        for (let i = 1; i <= numPages; i++) {
            arrPages.push(i);
        }
        setPages(arrPages);
    }, [numPages]);
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/PCPLink/GetByClient?Id=" + id).then(res => res.json()).then((res) => {
            if (res && res.length > 0) {
                setPCPLink(res[0]);
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [refresh])
    const onFlip = useCallback((e) => {
        setCurrentPage(e.data + 1);
    }, []);
    const downloadFile = () => {
        fetchData(pdfURL).then(async (res, a) => ({
            filename: fnGetFileNameFromContentDispostionHeader(res.headers.get('content-disposition')),
            blob: await res.blob()
        })).then((resObj) => {

            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        }).catch((er) => {
            if (er && er.name === 'AbortError') return;
            window.HandleError("PCPFlipbook.js ==> download", null, null, er);
            window.alert("Failed to download")
        });
    }
    const onFileChange = async (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (validateFile(files[0], ["pdf"])) {
            try {
                setUploading(true);
                let file = files[0];
                let fileNamewithDate = getFileNamewithDate(file.name);
                const formData = new FormData();
                formData.append(folderPath + fileNamewithDate, file, fileNamewithDate);
                let response = await fetchData(state.projectdetail.API + "/files/upload", {
                    method: "POST",
                    body: formData
                });
                let res = await response.json();
                if (res.Succeeded) {
                    let obj = { ...inputValue, FlipBook: res.Data.join(",") }
                    fetchData(state.projectdetail.API + "/pcp/SaveFlipbook", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(obj)
                    }).then(response => response.json()).then((res) => {
                        if (res.Succeeded) {
                            uploadRef.current.value = "";
                            setNumPages(0);
                            setInputValue(res.Data);
                            window.toast(res.Message);
                        }
                        else {
                            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                        }
                    }).catch((err) => {
                        if (err && err.name === 'AbortError') return;
                        window.HandleError("PCPFlipbook.js ==> SaveFlipbook", null, null, err);
                        window.alert(state.projectdetail.ErrorMessage);
                    }).finally(() => {
                        setUploading(false);
                    });
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
                setUploading(false);
            }
            catch (err) {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPFlipbook.js ==> SaveFlipbook", null, null, err);
                window.alert("Failed to upload", function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
        }
        else {
            window.alert("Flipbook should be less than 3MB with extensions(pdf)", function () { }, <Icon name='BsFillExclamationTriangleFill' />, "Ok", "danger");
        }
    }
    const GeneatePCP = () => {
        window.confirm("Are you sure you want to download PCP?", function () {
            setLoading(true);
            fetchData(state.projectdetail.API + "/PCP/GeneratePDF?Id=" + id).then(res => res.json()).then((res) => {
                if (res.Succeeded == true) {
                    fetchData(state.projectdetail.API + "/PCP/LoadPCP?Id=" + id).then(async (res, a) => ({
                        filename: fnGetFileNameFromContentDispostionHeader(res.headers.get('content-disposition')),
                        blob: await res.blob()
                    })).then((resObj) => {

                        const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob);
                        } else {
                            const objUrl = window.URL.createObjectURL(newBlob);
                            let link = document.createElement('a');
                            link.href = objUrl;
                            link.download = resObj.filename;
                            link.click();
                            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
                        }
                    }).catch((er) => {
                        if (er && er.name === 'AbortError') return;
                        window.HandleError("PCPFlipbook.js ==> LoadPCP", null, null, er);
                        window.alert("Failed to download")
                    });
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger")
                }
            }).catch(err => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPFlipbook.js ==> GeneratePDF", null, null, err);
                window.alert("Failed to generate", function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger")
            }).finally(() => {
                setLoading(false);
            });
        });
    }

    const GeneatePCPLink = () => {
        setGenerating(true);
        fetchData(state.projectdetail.API + "/PCPLink/GenerateLik?Id=" + id).then(res => res.json()).then((res) => {
            if (res.Succeeded) {
                window.toast(res.Message);
                setRefresh(c => !c);
            }
            else {
                window.alert(res.Message);
            }
        }).catch((er) => {
            if (er && er.name === 'AbortError') return;
            window.HandleError("PCPFlipbook.js ==> GeneatePCPLink", null, null, er);
            window.alert("Failed to geneate the PCP Link");
        }).finally(() => {
            setGenerating(false);
        });
    }
    const CopyLink = (e) => {
        let element = e.target;
        if (document.body.createTextRange) {
            let range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            let selection = window.getSelection();
            let range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }

        try {
            document.execCommand('copy');
            window.toast('Link copied');
        }
        catch (err) {
            if (err && err.name === 'AbortError') return;
            window.HandleError("PCPFlipbook.js ==> CopyLink", null, null, err);
            window.toast('Unable to copy lik');
        }
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate ref={formRef}>
                <Row className="mb-2">
                    <Col>
                        <h5 className="d-inline-block me-2">Flipbook</h5>
                        <Button size="sm" type="button" className="float-end ms-2" onClick={GeneatePCPLink} disabled={downloading}>
                            Generate New PCP Link
                            {generating ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                        <Button size="sm" type="button" className="float-end" onClick={GeneatePCP} disabled={downloading}>
                            <Icon name="BsDownload" /> Download PCP
                            {downloading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>

                    </Col>
                </Row>
                {PCPLink && PCPLink.LinkId &&
                    <Row>

                        <Col md={12} className="text-center">
                            <b>Copy Link : </b>  <a variant="link" className="border" onClick={CopyLink}>
                                {loading ? <span>Loading...<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /></span> :
                                    <span>
                                        {window.location.origin + "/pcpflipbookview/" + PCPLink.LinkId}
                                        {/*<Icon name="BiCopy" className="ms-2" />*/}
                                    </span>
                                }
                            </a>
                        </Col>
                    </Row>
                }
                {loading && <Row>
                    <Col className="text-center">
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </Col>
                </Row>
                }


                <>
                    <Row className="justify-content-center mb-2">
                        <Col className="col-auto">
                            <Button disabled={currentPage == 1} size="sm" className="me-2" onClick={() =>
                                bookRef.current.pageFlip().flipPrev()}>Previous Page</Button>
                            <span>[{currentPage} of {numPages}]</span>
                            <Button disabled={currentPage == numPages} size="sm" className="ms-2" onClick={() =>
                                bookRef.current.pageFlip().flipNext()}>Next Page</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
                                {pages.length > 0 && <HTMLFlipBook autoSize={false} className="flip-book" ref={bookRef} showCover={true} onFlip={onFlip} renderOnlyPageLengthChange={true}
                                    width={pageWidth} height={pageHeight}>
                                    {pages.map((pagenum) => {
                                        return <div className="page" key={pagenum}>
                                            <Page pageNumber={pagenum} />
                                        </div>
                                    })}
                                </HTMLFlipBook>}
                            </Document>
                        </Col>
                    </Row>
                </>



            </Form>
        </ErrorBoundary>
    );
}
export default (PCPFlipbook)