import React, { memo } from "react";
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "./controls/ErrorHandler";
function Footer() {
    const state = useSelector(state => state);
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <div className={"bg-dark text-light footer fixed-bottom"}>
                <span className="float-start p-2">Copyright &#169; {new Date().getFullYear()} </span>
                <span className="float-end p-2">Powered by <a className="text-decoration-none fw-bold" style={{ color: "#fab979" }} href={state.projectdetail.PoweredByLink} target="_blank">{state.projectdetail.PoweredBy}</a></span>
            </div>
        </ErrorBoundary>
    );
}
export default (memo(Footer));