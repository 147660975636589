import { useEffect, useState, memo } from "react";
import { Row, Col, Form, Button, Spinner, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";

import { anyToFloat, calculatePercentage } from "../../../Utils";
import CurrencyLabel from "../../controls/CurrencyLabel";
import ErrorHandler from "../../controls/ErrorHandler";
import CurrencyInputText from "../../controls/FormControls/CurrencyInput";
import { ErrorBoundary } from 'react-error-boundary';
import Icon from "../../controls/Icons/Icon";
import "./SPPlan.css";
function SPPlan({ category, categoryIndex, row, index, onChange, onDelete, ...otherProps }) {
    const state = useSelector(state => state);
    const [spdRow, setSpdRow] = useState(row);
    const unitData = {
        1: 336,
        2: 48,
        3: 11,
    };
    const handleChange = (e) => {
        const name = e.target.name;
        if (name == 'BaseRate') {

        }
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setSpdRow(c => ({ ...c, [name]: value }));
    }
    const CurrencyChange = (value, name) => {
        if (!typeof value || value == undefined) {
            value = 0;
        }
        setSpdRow(c => ({ ...c, [name]: value }));
    }
    const onProviderChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let IncludeStateTax = false;
        let IncludeSeparateTax = false;
        let IncludeShipingCharge = false;
        let text = "";
        if (value && e.target.selectedOptions.length > 0) {
            IncludeStateTax = e.target.selectedOptions[0].attributes['data-includestatetax'].value == "true";
            IncludeSeparateTax = e.target.selectedOptions[0].attributes['data-includeseparatetax'].value == "true";
            IncludeShipingCharge = e.target.selectedOptions[0].attributes['data-includeshipingcharge'].value == "true";
            text = e.target.selectedOptions[0].text;
        }
        let objProvider = {
            [name]: value,
            providerExpense: { Id: value, ProviderName: text, IncludeStateTax, IncludeSeparateTax, IncludeShipingCharge }
        };
        setSpdRow(c => ({ ...c, ...objProvider }));
    }
    const deleteRow = (delspdRow, delIndex, delcategory, delcategoryIndex) => {
        if (typeof onDelete == "function") {
            debugger
            onDelete(delspdRow, delIndex, delcategory, delcategoryIndex);
        }
    }
    useEffect(() => {
        setSpdRow(row);
    }, [index])
    useEffect(() => {
        if (typeof onChange == "function") {
            onChange(spdRow, index, category, categoryIndex);
        }
    }, [spdRow])

    useEffect(() => {
        let rowcal = JSON.parse(JSON.stringify(spdRow));
        if (spdRow.fkUnitPerId != 4) {
            rowcal.UnitForMultiply = anyToFloat(spdRow.For * spdRow.Per).toFixed(2);
        }
        //else if (spdRow.fkUnitPerId != 4) {
        //    rowcal.UnitForMultiply = anyToFloat(spdRow.For * unitData[spdRow.fkUnitPerId]).toFixed(2);
        //}
        else {
            rowcal.UnitForMultiply = spdRow.For;
        }
        //if (rowcal.BaseRate.toString().indexOf(",") > -1) {
        //    
        //}
        rowcal.PreTaxAmount = (anyToFloat(rowcal.UnitForMultiply) * anyToFloat(rowcal.BaseRate)).toFixed(2);

        if (rowcal.providerExpense.IncludeStateTax) {
            rowcal.EmployeeBurdenPer = state.spmetadata.employeeBurdenTax;
            rowcal.EmployeeBurdenAmount = calculatePercentage(rowcal.PreTaxAmount, state.spmetadata.employeeBurdenTax).toFixed(2);
        }
        else {
            rowcal.EmployeeBurdenPer = 0;
            rowcal.EmployeeBurdenAmount = 0;
        }
        if (rowcal.providerExpense.IncludeSeparateTax && rowcal.TaxPer) {
            rowcal.TaxAmount = calculatePercentage(rowcal.PreTaxAmount, rowcal.TaxPer).toFixed(2);
        }
        else {
            rowcal.TaxAmount = 0;
            rowcal.TaxPer = 0;
        }
        if (!rowcal.providerExpense.IncludeShipingCharge) {
            rowcal.ShippingAmount = 0;
        }
        rowcal.TaxShippingAmount = anyToFloat(anyToFloat(rowcal.TaxAmount) + anyToFloat(rowcal.ShippingAmount)).toFixed(2);
        rowcal.Total = anyToFloat(anyToFloat(rowcal.PreTaxAmount) + anyToFloat(rowcal.EmployeeBurdenAmount) + anyToFloat(rowcal.TaxAmount) + anyToFloat(rowcal.ShippingAmount)).toFixed(2);
        setSpdRow(rowcal);
    }, [state.spmetadata.employeeBurdenTax, spdRow.providerExpense.IncludeSeparateTax, spdRow.providerExpense.IncludeShipingCharge, spdRow.providerExpense.IncludeStateTax,
    spdRow.For, spdRow.fkUnitId, spdRow.fkUnitPerId, spdRow.Per, spdRow.BaseRate, spdRow.TaxPer, spdRow.ShippingAmount]);
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <div key={index} className="mb-2 p-2 border-bottom">
            <Row className="align-items-center">
                <Col md="auto">
                    <h6>{(categoryIndex + 1) + ". " + (index + 1)} <Button variant="link" onClick={() => { deleteRow(spdRow, index, category, categoryIndex) }} type="button" className="pt-0"><Icon name="BsTrash" className="fs-6" /></Button></h6>
                </Col>
                <Col>
                    <Row className="mb-2 align-items-center">
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Select service<code className='required-symbol'>*</code></Form.Label>
                                {!state.spmetadata.serviceError ? state.spmetadata.serviceLoading ? <div><Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /></div> :
                                    <Form.Select required disabled={true} value={spdRow.fkServiceId} name="fkServiceId" onChange={handleChange}>
                                        <option value="">--Select--</option>
                                        {state.spmetadata.services.map((service) => {
                                            return (<option key={service.Id}
                                                data-fkspcategoryid={service.fkSPCategoryId}
                                                data-spcategory-categoryname={service.spCategory.CategoryName}
                                                value={service.Id}>{service.ServiceDisplayName}</option>)
                                        })}
                                    </Form.Select>
                                    : <ErrorHandler error={state.spmetadata.serviceError} />
                                }
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Select Provider Expense<code className='required-symbol'>*</code></Form.Label>
                                {!state.spmetadata.providerError ? state.spmetadata.providerLoading ? <div><Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /></div> :
                                    <Form.Select required name="fkProviderId" value={spdRow.fkProviderId} onChange={onProviderChange}>
                                        <option value="">--Select--</option>
                                        {state.spmetadata.providers.map((provider) => {
                                            return (<option key={provider.Id}
                                                data-includestatetax={provider.IncludeStateTax}
                                                data-includeseparatetax={provider.IncludeSeparateTax}
                                                data-includeshipingcharge={provider.IncludeShipingCharge}
                                                value={provider.Id}>{provider.ProviderName}</option>)
                                        })}
                                    </Form.Select>
                                    : <ErrorHandler error={state.spmetadata.providerError} />
                                }
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Provider Name<code className='required-symbol'>*</code></Form.Label>
                                <Form.Control required name="Name" value={spdRow.Name} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Service Provided</Form.Label>
                                <Form.Control name="ServiceProvided" value={spdRow.ServiceProvided} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <Row className="mb-2 align-items-center">
                                <Col xs="auto" className="pe-0">
                                    For
                                </Col>
                                <Col xs="auto" className="pe-0">
                                    <Form.Control type="number" min="0" required className="sp-control sp-unit" name="For" value={spdRow.For} onChange={handleChange} />
                                </Col>
                                <Col xs="auto" className="pe-0">
                                    {!state.spmetadata.unitError ? state.spmetadata.unitLoading ? <div><Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /></div> :
                                        <Form.Select required name="fkUnitId" value={spdRow.fkUnitId} onChange={handleChange}>
                                            {state.spmetadata.units.map((unit) => {
                                                return (<option key={unit.Id}
                                                    data-includestatetax={unit.IncludeStateTax}
                                                    data-includeseparatetax={unit.IncludeSeparateTax}
                                                    data-includeshipingcharge={unit.IncludeShipingCharge}
                                                    value={unit.Id}>{unit.UnitName}</option>)
                                            })}
                                        </Form.Select>
                                        : <ErrorHandler error={state.spmetadata.unitError} />
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <Row className="mb-2 align-items-center">
                                <Col xs="auto" className="pe-0">
                                    Per
                                </Col>
                                <Col xs="auto" className="pe-0">
                                    {!state.spmetadata.unitPerError ? state.spmetadata.unitPerLoading ? <div><Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /></div> :
                                        <Form.Select required name="fkUnitPerId" value={spdRow.fkUnitPerId} onChange={handleChange}>
                                            {state.spmetadata.unitPers.map((unitPer) => {
                                                return (<option key={unitPer.Id}
                                                    data-includestatetax={unitPer.IncludeStateTax}
                                                    data-includeseparatetax={unitPer.IncludeSeparateTax}
                                                    data-includeshipingcharge={unitPer.IncludeShipingCharge}
                                                    value={unitPer.Id}>{unitPer.UnitName}</option>)
                                            })}
                                        </Form.Select>
                                        : <ErrorHandler error={state.spmetadata.unitPerError} />
                                    }
                                </Col>
                                {(spdRow.fkUnitPerId != 4) && <Col xs="auto" className="pe-0">
                                    <Form.Control type="number" required min="0" className="sp-control sp-unit" name="Per" value={spdRow.Per} onChange={handleChange} />
                                </Col>}
                                <Col xs="auto" className="pe-0">
                                    <Form.Control disabled={true} className="sp-control sp-curreny-input-unit" name="UnitForMultiply" value={spdRow.UnitForMultiply} onChange={handleChange} />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs="auto">
                            <Row className="mb-2 align-items-center">
                                <Col xs="auto" className="pe-0">
                                    At base rate
                                </Col>
                                <Col xs="auto" className="pe-0">
                                    <CurrencyInputText
                                        name="BaseRate"
                                        required={true}
                                        errorMessage="Pleae enter Base Rate"
                                        onChange={CurrencyChange}
                                        value={spdRow.BaseRate}
                                        containerClassName=""
                                        className="sp-curreny-inputsmall form-control"
                                        decimalsLimit={2}
                                        defaultValue={0}
                                        id="BaseRate"
                                    />



                                    {/*<CurrencyInputTextText type="number" min="0" width={ 50} required className="sp-control" containerClassName="sp-curreny-input" name="BaseRate" value={spdRow.BaseRate} onChange={handleChange} />*/}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto" className="pe-0">
                            Pretax Total &nbsp;
                            <Badge bg="light" className="text-dark fs-6"><CurrencyLabel value={spdRow.PreTaxAmount} /></Badge>
                        </Col>
                        <Col xs="auto" className="pe-0">
                            with
                        </Col>
                        {spdRow.providerExpense.IncludeStateTax && (state.spmetadata.stateTaxError ? <Col xs="auto" className="pe-0"><ErrorHandler error={state.spmetadata.stateTaxError} /></Col>
                            : state.spmetadata.stateTaxLoading ? <Col xs="auto" className="pe-0 "><Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /></Col>
                                : state.spmetadata.stateTaxs.map((stateTax, stateTaxindex) => {
                                    return (
                                        <Col xs="auto" key={stateTax.Id}>
                                            <span>{stateTax.stateTax.TaxName}({stateTax.Tax})%</span>
                                            <Badge bg="light" className="text-dark fs-6"><CurrencyLabel value={calculatePercentage(spdRow.PreTaxAmount, stateTax.Tax).toFixed(2)} /></Badge>
                                        </Col>
                                    )
                                }))
                        }

                        {spdRow.providerExpense.IncludeStateTax && !state.spmetadata.stateTaxError && !state.spmetadata.stateTaxLoading && <Col xs="auto">
                            <span>Employee Burden ({state.spmetadata.employeeBurdenTax})% </span>
                            <Badge bg="light" className="text-dark fs-6"><CurrencyLabel value={row.EmployeeBurdenAmount} /></Badge>
                        </Col>}
                        {spdRow.providerExpense.IncludeSeparateTax && <Col xs="auto" className="pe-0">
                            <Row>
                                <Col xs={6} className="pe-0"> <span>Tax %</span></Col>
                                <Col xs={6} className="pe-0"> <span>Tax Amount</span></Col>
                                <Col xs={6} className="pe-0">
                                    <Form.Control step="0.01" type="number" min="0" className="sp-curreny-input" name="TaxPer" value={spdRow.TaxPer} onChange={handleChange} />
                                </Col>
                                <Col xs={6} className="pe-0">
                                    <Badge bg="light" className="text-dark fs-6"><CurrencyLabel value={spdRow.TaxAmount} /></Badge>
                                </Col>
                            </Row>
                        </Col>}
                        {spdRow.providerExpense.IncludeShipingCharge && <Col xs="auto" className="pe-0">
                            <span>Shipping Charge</span>

                            <CurrencyInputText
                                name="ShippingAmount"
                                required={true}
                                errorMessage="Pleae enter Shipping Amount"
                                onChange={CurrencyChange}
                                value={spdRow.ShippingAmount}
                                containerClassName=""
                                className="sp-curreny-inputsmall form-control"
                                decimalsLimit={2}
                                defaultValue={0}
                                id="ShippingAmount"
                            />


                        </Col>}
                        {(spdRow.providerExpense.IncludeShipingCharge || spdRow.providerExpense.IncludeShipingCharge) && <Col xs="auto" className="pe-0">
                            {spdRow.providerExpense.IncludeSeparateTax && spdRow.providerExpense.IncludeShipingCharge && <span>Tax & Shipping Charge</span>}
                            {spdRow.providerExpense.IncludeSeparateTax && !spdRow.providerExpense.IncludeShipingCharge && <span>Tax Amount</span>}
                            {!spdRow.providerExpense.IncludeSeparateTax && spdRow.providerExpense.IncludeShipingCharge && <span>Shipping Amount</span>}
                            <div>
                                <Badge bg="light" className="text-dark fs-6"><CurrencyLabel value={spdRow.TaxShippingAmount} /></Badge>
                            </div>
                        </Col>}
                        <Col xs={12} >
                            <div className="float-end">
                                <span>Total </span>
                                <Badge bg="warning" className="text-dark fs-6"><CurrencyLabel value={spdRow.Total} /></Badge>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </ErrorBoundary>
    )
}
export default (memo(SPPlan));