import React from 'react';

import "./MenuSkeleton.css";
const MenuSkeleton = () => {
    return (
        <div className={'sk-menu'}>
            <div className="sk-menu-item right"></div>
            <div className="sk-menu-item right"></div>
            <div className="sk-menu-item right"></div>
        </div>
    );
}

export default (MenuSkeleton);
