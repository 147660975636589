import { useEffect, useState, useMemo } from "react";
import { Form, Row, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from "react-redux";
import DynamicDropdownSearchable from "../../controls/DynamicDropdownSearchable";
import ServerError from "../../controls/ServerError";
import Icon from "../../controls/Icons/Icon";
import { anyToFloat, fnGetFileNameFromContentDispostionHeader } from "../../../Utils";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";
const FMSForm = ({ _inputValue, afterSave, onCancel }) => {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [stateLoading, setStateLoading] = useState(false);
    const [stateError, setStateError] = useState(null);
    const [refresh, setrefresh] = useState(null);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        Id: 0,
        FMSName: "",
        Address: "",
        Zipcode: "",
        fkStateId: 5,
        total: 0,
        FMSStateTaxes: [],
        DeletedFMSStateTax: []
    });
    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const calculatedValue = useMemo(
        () => {
            inputValue.total = settotal();
        },
        [inputValue]
    )
    function settotal() {
        var total = 0;
        inputValue.FMSStateTaxes.forEach(taxelement => {
            console.log(taxelement.Tax);
            total += anyToFloat(taxelement.Tax);
        });
        return total;
    }
    const onStateChagne = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        loadStateTax({ ...inputValue }, inputValue.Id, value);
    }
    useEffect(() => {
        loadStateTax({ ...inputValue, ..._inputValue, fkStateId: 5 }, _inputValue.Id, 5);
    }, []);

    useEffect(() => {
        state.total = settotal();
    }, [inputValue]);

    const loadStateTax = (obj, fmsid, stateid) => {
        let _inputVal = JSON.parse(JSON.stringify(obj));
        _inputVal.fkStateId = stateid;
        if (!fmsid) {
            fmsid = 0;
        }
        _inputVal.FMSStateTaxes = [];
        if (stateid) {
            setStateLoading(true);
            fetchData(state.projectdetail.API + "/FMSStateTax/GetStateTaxesByFMSState?FmsId=" + fmsid + "&stateId=" + stateid, {
                method: "Get"
            }).then(response => response.json()).then((res) => {
                setStateError(null);
                if (res) {
                    if (res.length > 0) {
                        _inputVal.FMSStateTaxes = res;
                    }
                    else {
                        _inputVal.FMSStateTaxes = [];
                    }
                }
                else {
                    _inputVal.FMSStateTaxes = [];
                }
                setInputValue(_inputVal);
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("FMSForm.js ==> GetStateTaxesByFMSState", null, null, err);
                setStateError(err);
            }).finally(() => {
                setStateLoading(false);
            });
        }
        else {
            _inputVal.FMSStateTaxes = [];
            setInputValue(_inputVal)
        }
    }
    const onTaxChange = (e, row, index) => {
        let _inputVal = JSON.parse(JSON.stringify(inputValue));
        _inputVal.FMSStateTaxes = _inputVal.FMSStateTaxes.map((rr, rrindex) => {
            if (rrindex == index)
                return { ...rr, ["Tax"]: e.target.value }
            return { ...rr }

        });
        setInputValue(_inputVal);
    }
    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetchData(state.projectdetail.API + "/fms/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputValue)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    setInputValue({
                        Id: 0,
                        FMSName: "",
                        Address: "",
                        Zipcode: "",
                        fkStateId: "",
                        Total: 0,
                        FMSStateTaxes: [],
                        DeletedFMSStateTax: []
                    });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("FMSForm.js ==> save", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    })
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>FMS Name<code className='required-symbol'>*</code></Form.Label>
                                <Form.Control required type="text" name="FMSName" value={inputValue.FMSName} onChange={handleChange} autoComplete="off" />
                                <Form.Control.Feedback type="invalid">FMS Name is required</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control as={"textarea"} type="text" name="Address" value={inputValue.Address} onChange={handleChange} autoComplete="off" />
                            </Form.Group>
                        </Row>
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Zipcode</Form.Label>
                                <Form.Control type="text" type="number" maxLength={5} name="Zipcode" value={inputValue.Zipcode} onChange={handleChange} autoComplete="off" />
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>State<code className='required-symbol'>*</code></Form.Label>
                                <DynamicDropdownSearchable from={state.projectdetail.API + "/state/getall"} label="StateName" value="Id" fetchType="get" name="fkStateId" onDropdownChange={onStateChagne} defaultval={inputValue.fkStateId} />
                                {validated && !inputValue.fkStateId && <small className="text-danger">Please provide state</small>}
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>

                {inputValue.fkStateId &&
                    <Row>
                        <Col>
                            {stateLoading ? <Row>
                                <Col className="text-center">
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                </Col>
                            </Row> : (inputValue.FMSStateTaxes && inputValue.FMSStateTaxes.length > 0) ? <Row>
                                {inputValue.FMSStateTaxes.map((row, index) => {
                                    return (
                                        <Col md={6} key={row.stateTax.Id}>
                                            <Form.Group>
                                                <Form.Label>{row.stateTax.TaxName}<code className='required-symbol'>*</code></Form.Label>
                                                <Form.Control required type="text" name="Tax" value={row.Tax} onChange={(e) => { onTaxChange(e, row, index) }} autoComplete="off" />
                                                <Form.Control.Feedback type="invalid">Tax percentage required</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>)
                                })}
                            </Row> : <div className="mb-2"><Row className="p-2 text-center border"><Col><b>No Tax Categories found.Please Configure in Tax Categories Master!</b></Col></Row></div>}
                        </Col>
                    </Row>
                }
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Total Tax</Form.Label>
                                <Form.Control type="text" type="number" disabled maxLength={5} name="Zipcode" value={inputValue.total} onChange={handleChange} autoComplete="off" />
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <ServerError error={serverError} />
                    </Col>
                </Row>
                {inputValue.FMSStateTaxes.length > 0 ? <Row className="mb-3">
                    <Col className='text-center'>
                        <Button variant="outline-primary" onClick={onCancel} disabled={loading} className="me-2">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading}>
                            Submit
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                    </Col>
                </Row> : ""}
            </Form>
        </ErrorBoundary>
    )
}
export default (FMSForm);
