import { store } from './Reducer/store'
export const dateDisplayFormater = (input) => {
    let date = new Date(input);
    let datee = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (datee < 10) {
        datee = "0" + datee;
    }
    if (month < 10) {
        month = "0" + month;
    }
    return month + "-" + datee + "-" + year;
}
export const getFileSize = (size) => {
    return (size / (1024 * 1024)).toFixed(2) + " mb"
}
export const getExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
}
export const calculatePercentage = (amt, per) => {
    if (amt == undefined || amt == null)
        amt = 0;
    if (per == undefined || per == null)
        per = 0;
    return parseFloat((parseFloat(per) / 100) * parseFloat(amt));
}
export const getMonth = (date, onlyThreeChars) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    if (date) {
        date = new Date(date);
        if (date) {
            let month = months[date.getMonth()];
            if (onlyThreeChars) {
                return month.substring(0, 3);
            }
            return month;
        }
    }
    return "";
}
export const getFileNamewithDate = (filename) => {
    let strFileName = "";
    let strFileExtension = "";
    strFileName = filename.substr(0, filename.lastIndexOf('.'));
    strFileExtension = filename.replace(/^.*\./, '').toLowerCase();
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
    let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
    let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
    let milliseconds = "" + now.getMilliseconds(); if (milliseconds.length == 1) { milliseconds = "0" + milliseconds; }
    let ttt = day + "" + month + "" + year + "" + hour + "" + minute + "" + second + "" + milliseconds;
    return strFileName + "-" + ttt + "." + strFileExtension;
}
export const validateFile = (file, allowedExt) => {
    const state = store.getState();
    if (typeof allowedExt == "string") {
        allowedExt = allowedExt.split(",");
    }
    if (file) {
        let ext = file.name.split(".").pop().toLowerCase();
        return (allowedExt.indexOf(ext) != -1 && file.size <= state.projectdetail.maxFileSize);
    }
    return false
}
export const getDateSaveFormat = (date) => {
    let dd = new Date(date);
    let mon = dd.getMonth() + 1;
    let dat = dd.getDate();
    if (parseInt(mon) < 10) {
        mon = "0" + mon;
    }
    if (parseInt(dat) < 10) {
        dat = "0" + dat;
    }
    return dd.getFullYear() + "-" + (mon) + "-" + dat;
}

export const getTimeSaveFormat = (date) => {
    let dd = new Date(date);
    let Hours = dd.getHours();
    let Min = dd.getMinutes();
    if (parseInt(Hours) < 10) {
        Hours = "0" + Hours;
    }
    if (parseInt(Min) < 10) {
        Min = "0" + Min;
    }
    return (Hours) + ":" + Min + ":00";
}
export const CommingSoon = () => {
    window.alert("Comming Soon");
}
export const getFileIcon = (ext) => {
    if (ext == "pdf") {
        return "FaRegFilePdf";
    }
    return "FaRegFileImage";
}
export const minsToHHMMSS = (mins_num) => {
    mins_num = parseFloat(mins_num);
    var hours = Math.floor(mins_num / 60);
    var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    return hours + ':' + minutes;
}

export const anyToFloat = (input) => {
    if (typeof input == "string")
        input = input.toString().replace(",", "");
    if (input == "" || input == undefined || input == null) {
        return 0;
    }
    if (isNaN(parseFloat(input))) {
        return 0;
    }
    return parseFloat(input);
}

export const fnGetFileNameFromContentDispostionHeader = function (header) {
    let contentDispostion = header.split(';');
    const fileNameToken = `filename*=UTF-8''`;
    let fileName = 'downloaded.pdf';
    for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
            fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
            break;
        }
    }

    return fileName;
};
export const convertTimeTo12HourFormat = (time) => {
    var timeArray = time.split(':');
    var hours = parseInt(timeArray[0]);
    var minutes = parseInt(timeArray[1]);

    var period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be converted to 12

    // Formatting the time
    var formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + period;

    return formattedTime;
}