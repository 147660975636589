import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import Icon from '../../controls/Icons/Icon';
import ServerError from '../../controls/ServerError';
import FormEmail from '../../controls/FormControls/FormEmail';
import FormMobileNo from '../../controls/FormControls/FormMobileNo';
import { set } from 'date-fns';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
function UserForm({ _inputValue, afterSave, showPassword, disableForProfile, onCancel, DontClearsave }) {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const [showPasswordText, setShowPasswordText] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        Id: 0,
        firstName: "",
        lasttName: "",
        email: "",
        password: "",
        mobile: "",
        fkRoleId: "",
        active: 1,
    });
    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);

    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetchData(state.projectdetail.API + "/user/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputValue)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    if (!DontClearsave) {
                        setInputValue({
                            firstName: "",
                            lasttName: "",
                            email: "",
                            password: "",
                            mobile: "",
                            fkRoleId: "",
                        });
                        setInputValue(res);
                    }
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("UserForm.js ==> handleSubmit", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    })
    const GeneratePassword = () => {
        setShowPasswordText(true);
        const Allowed = {
            Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
            Lowers: "qwertyuiopasdfghjklzxcvbnm",
            Numbers: "1234567890",
            Symbols: "!@#$%^&*"
        }

        const getRandomCharFromString = (str) => str.charAt(Math.floor(Math.random() * str.length))
        const generatePassword = (length = 8) => {
            let pwd = "";
            pwd += getRandomCharFromString(Allowed.Uppers);
            pwd += getRandomCharFromString(Allowed.Lowers);
            pwd += getRandomCharFromString(Allowed.Numbers);
            pwd += getRandomCharFromString(Allowed.Symbols);
            for (let i = pwd.length; i < length; i++)
                pwd += getRandomCharFromString(Object.values(Allowed).join(''));
            return pwd
        }
        let obj = {};
        obj.password = generatePassword(8);
        setInputValue({ ...inputValue, ...obj });
    }
    const togglePasswordText = () => {
        setShowPasswordText(!showPasswordText);
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>First Name<code className='required-symbol'>*</code></Form.Label>
                                <Form.Control required type="text" name="firstName" value={inputValue.firstName} onChange={handleChange} autoComplete="off" />
                                <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="lastName" value={inputValue.lastName} onChange={handleChange} autoComplete="off" />
                            </Form.Group>
                        </Row>
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Role<code className='required-symbol'>*</code></Form.Label>
                                <fieldset disabled={disableForProfile}>
                                    <DynamicDropdownSearchable disabled={disableForProfile} from={state.projectdetail.API + "/role/getByActive?active=1"} label="roleName" value="Id" fetchType="get" name="fkRoleId" onDropdownChange={handleChange} defaultval={inputValue.fkRoleId} />
                                </fieldset>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Mobile Number<code className='required-symbol'>*</code></Form.Label>
                                <FormMobileNo
                                    required={true}
                                    errorMessage="Mobile number is required"
                                    name="mobile"
                                    value={inputValue.mobile}
                                    onChange={handleChange} />
                            </Form.Group>
                        </Row>
                    </Col>

                </Row>


                {showPassword ?
                    <Row className="mb-3">
                        <Col md={6}>
                            <Row>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    {!showPasswordText ?
                                        <InputGroup>
                                            <Form.Control disabled={inputValue.Id ? true : false} required type="password" name="password" value={inputValue.password} onChange={handleChange} autoComplete="off" />
                                            {!inputValue.Id ? <InputGroup.Text onClick={togglePasswordText}><Icon name="BsEye" /></InputGroup.Text> : ""}
                                        </InputGroup>
                                        :
                                        <InputGroup>
                                            <Form.Control disabled={inputValue.Id ? true : false} required type="text" name="password" value={inputValue.password} onChange={handleChange} autoComplete="off" />
                                            <InputGroup.Text onClick={togglePasswordText}><Icon name="BsEyeSlash" /></InputGroup.Text>
                                        </InputGroup>
                                    }
                                    <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Form.Group>
                                    {!inputValue.Id ? <Button onClick={GeneratePassword}>Generate Password</Button> : ""}
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    : ""}

                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Email Address<code className='required-symbol'>*</code></Form.Label>
                                <FormEmail
                                    required={true}
                                    name="email"
                                    value={inputValue.email}
                                    onChange={handleChange}
                                    errorMessage="Email Address is required" />
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <fieldset disabled={disableForProfile}>
                                    <Form.Select value={inputValue.active} name="active" onChange={handleChange}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                </fieldset>
                            </Form.Group>
                        </Row>
                    </Col>

                </Row>

                <Row className="mb-3">
                    <Col>
                        <ServerError error={serverError} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className='text-center'>
                        <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                            Cancel
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>&nbsp;&nbsp;
                        <Button variant="primary" type="submit" disabled={loading}>
                            Submit
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </ErrorBoundary>
    )
}
export default (UserForm);