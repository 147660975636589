import { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button, Spinner, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import { PCPAction } from "../../../Reducer/PCPReducer";
import { CommingSoon } from "../../../Utils";
import ErrorHandler from "../../controls/ErrorHandler";
import FormAutoComplete from "../../controls/FormControls/FormAutoComplete";
import Icon from "../../controls/Icons/Icon";
import "./PCPGoal.css";
function PCPGoals() {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [data, setData] = useState([]);
    const [deletedGoalType, setDeletedGoalType] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [validated, setValidated] = useState(false);
    const fromRef = useRef(null);
    const [goalType, setGoalType] = useState([]);
    const [goalTypeLoading, setGoalTypeLoading] = useState(false);
    const [goalTypeError, setGoalTypeError] = useState(null);
    const dispatch = useDispatch();
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    useEffect(() => {
        if (savedSuccessfully && !state.pcp.hasUnsavedChanges) {
            navigate("/admin/pcpform/" + id + "/continuedservicesunmetneed");
        }
    }, [savedSuccessfully, state.pcp.hasUnsavedChanges]);
    const [tags, setTags] = useState([
        {
            label: "{name}",
            value: "{name}",
        },
        {
            label: "{He/She}",
            value: "{He/She}",
        },
        {
            label: "{he/she}",
            value: "{he/she}",
        },
        {
            label: "{her/him}",
            value: "{her/him}",
        },
        {
            label: "{Her/Him}",
            value: "{Her/Him}",
        },
    ]);
    const defaultGoal = {
        Id: 0,
        fkPCPGoalTypeId: 0,
        fkGoalMasterDataId: 0,
        goalMasterData: { Id: 0, GoalText: "" }
    };
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/pcp/GetGoals?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    setData(res);
                    setDeletedGoalType([]);
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPGoals.js ==> GetGoals", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });

        setGoalTypeLoading(true);
        fetchData(state.projectdetail.API + "/GoalType/GetAll")
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    setGoalType(res);
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPGoals.js ==> GoalType", null, null, err);
                setGoalTypeError(err);
            }).finally(() => {
                setGoalTypeLoading(false);
            });
    }, [refresh]);

    const handleChange = (e) => {
        let value = e.target.value;
        if (value) {
            let goalType = e.target.selectedOptions[0].innerText
            let AlreadyExists = data.find((row) => {
                return row.fkGoalTypeId == value;
            })
            if (!AlreadyExists)
                setData(d => [...d, {
                    Id: 0,
                    fkClientId: id,
                    fkGoalTypeId: value,
                    goaltype: { Id: value, GoalTypeName: goalType },
                    Action: "",
                    Goals: [defaultGoal],
                    GoalsDeleted: []
                }]);
        }
        e.target.value = "";
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
    }
    const addGoal = (row, index) => {
        setData(data.map((drow, dindex) => {
            if (dindex == index) {
                drow.Goals.push(defaultGoal);
                return drow;
            }
            return drow;
        }));
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
    }
    const deleteGoal = (row, index, grow, gindex) => {
        window.confirm("Do you want to delete?", function () {
            setData(data.map((drow, dindex) => {
                if (dindex == index) {
                    drow.Goals = drow.Goals.filter((dgrow, dgindex) => {
                        if (dgindex == gindex && dgrow.Id) {
                            if (!drow.hasOwnProperty("GoalsDeleted") || !drow.GoalsDeleted) {
                                drow.GoalsDeleted = [];
                            }
                            drow.GoalsDeleted.push(dgrow.Id);
                        }
                        return dgindex != gindex
                    });
                    return drow;
                }
                return drow;
            }));
            dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
        });

    }

    const onGoalChange = (value, row, index, grow, gindex) => {
        setData(data.map((drow, dindex) => {
            if (dindex == index) {
                drow.Goals = drow.Goals.map((dgrow, dgindex) => {
                    if (dgindex == gindex) {
                        return { ...dgrow, ["goalMasterData"]: value };
                    }
                    return dgrow;
                });
                return drow;
            }
            return drow;
        }));
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
    }
    const handleActionChange = (e, row, index) => {
        let value = e.target.value;
        setData(data.map((drow, dindex) => {
            if (dindex == index) {
                return { ...drow, ["Action"]: value };
            }
            return drow;
        }));
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
    }
    const SaveData = (e, movenext) => {
        e.preventDefault();
        e.stopPropagation();
        if (fromRef.current.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setValidated(false);
            setSaveLoading(true);
            let obj = {
                GoalTypes: data,
                DeletedGoalTypes: deletedGoalType
            };
            fetchData(state.projectdetail.API + "/pcp/SaveGoals", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    window.toast(res.Message);
                    if (!movenext)
                        setRefresh(a => !a);
                    setSavedSuccessfully(movenext);
                    dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPGoals.js ==> SaveData", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setSaveLoading(false);
            });
        }
    }
    const deleteGoalType = (row, index) => {
        window.confirm("Do you want to delete?", function () {
            setData(data.filter((drow, dindex) => {
                return index != dindex
            }));
            if (row.Id > 0) {
                setDeletedGoalType(a => [...a, row.Id]);
                dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
            }
        });
    }
    function copy(e, row, text) {
        try {
            navigator.clipboard.writeText(text);
            e.target.innerText = "Copied";
            setTimeout(() => {
                e.target.innerText = text;
            }, 2000);
            window.toast("Copied to clipboard");
        }
        catch (err) {
            if (err && err.name === 'AbortError') return;
            window.HandleError("PCPGoals.js ==> copy", null, null, err);
            window.toast("Failed to copy");
        }

    }


    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate validated={validated} ref={fromRef}>
            <Row className="mb-2">
                <Col>
                    <h5 className="d-inline-block me-4">Goals</h5>
                    <Button type="button" name="BsMicFill" className='btn btn-warning' onClick={CommingSoon}>Import Goals</Button>
                </Col>
                <Col md={4}>
                    {data.length < goalType.length && <Form.Group className="row">
                        <Form.Label className="col-auto">Goal Type</Form.Label>
                        <div className="col">
                            {goalTypeLoading ? <Spinner className='dropdown-loading' as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                                : goalTypeError ? <ErrorHandler error={goalTypeError} /> : < Form.Select onChange={handleChange}>
                                    <option value="">--Select--</option>
                                    {goalType.map((gtrow) => {
                                        return <option key={gtrow.Id} value={gtrow.Id}>{gtrow.GoalTypeName}</option>
                                    })}
                                </Form.Select>}
                        </div>
                    </Form.Group>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {loading ?
                        <div className="text-center">
                            <Spinner className='dropdown-loading' as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                        </div> :

                        <Row>
                            <Col md={10}>
                                {data.length > 0 ?
                                    <div className="mb-2">
                                        {data.map((row, index) => {
                                            return (<Card key={index} className="border mb-2">
                                                <Card.Body>
                                                    <Row>
                                                        <Col>
                                                            <h4 className="mb-0 text-center">
                                                                {row.goaltype.GoalTypeName}
                                                                <Button className="float-end"
                                                                    onClick={() => deleteGoalType(row, index)}
                                                                    variant="link" title="Delete Goal Type" type="button"><Icon name="BsTrash" className='fs-5' /></Button>
                                                            </h4>
                                                        </Col>
                                                    </Row>
                                                    {row.Goals.map((grow, gindex) => {
                                                        return (<Row key={index.toString() + gindex.toString()} className="align-items-center mb-2">
                                                            <Col>
                                                                <Form.Group className="row">
                                                                    <Form.Label className="col-auto">
                                                                        Goal {gindex + 1}
                                                                        <div className="hidecontrol">Action Steps</div>
                                                                    </Form.Label>
                                                                    <div className="col">
                                                                        <FormAutoComplete required={true}
                                                                            onChange={(val) => { onGoalChange(val, row, index, grow, gindex) }}
                                                                            value={grow.goalMasterData}
                                                                            labelField="GoalText"
                                                                            valueField="Id"
                                                                            url={state.projectdetail.API + "/goalmasterdata/search"}
                                                                            ajaxOptions={{ method: "POST" }} as="textarea" rows={5} errorMessage="Goal is required" />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs="2">
                                                                <ButtonGroup aria-label="Basic example">
                                                                    {<Button variant="link" type="button" onClick={() => { deleteGoal(row, index, grow, gindex) }} className={row.Goals.length > 1 ? "" : "hidecontrol"}><Icon name="BsTrash" className='fs-5' /></Button>}
                                                                    {gindex == row.Goals.length - 1 && <Button variant="link" type="button" title="Add new goal" onClick={() => { addGoal(row, index) }}><Icon name="BsPlusLg" className='fs-5' /></Button>}
                                                                </ButtonGroup>
                                                            </Col>
                                                        </Row>)
                                                    })}
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="row">
                                                                <Form.Label className="col-md-auto">Action Steps</Form.Label>
                                                                <div className="col">
                                                                    <Form.Control required type="text" rows={10} as="textarea"
                                                                        name="Action" value={row.Action} onChange={(e) => { handleActionChange(e, row, index) }} autoComplete="off" />
                                                                    <Form.Control.Feedback type="invalid">Action is required</Form.Control.Feedback>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="2">
                                                            <Button variant="link" type="button" onClick={CommingSoon}><Icon name="BsMicFill" className='fs-5' /></Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>)
                                        })}
                                    </div> :
                                    <div className="mb-2">
                                        <Row className="p-2 text-center border">
                                            <Col>
                                                <b>No goals found</b>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </Col>
                            {data.length > 0 && < Col md={2}>
                                <div className="sticky-top">
                                    <h6>Tags</h6>
                                    <Dropdown.Menu show={true}>
                                        {tags.map((tag) => {
                                            return <Dropdown.Item
                                                onDragStart={(event) => {
                                                    event.dataTransfer.setData('text', tag.value);
                                                    event.target.classList.add('dragging');
                                                }}
                                                onDragEnd={(event) => {
                                                    event.target.classList.remove('dragging');
                                                }}
                                                className="border-bottom"
                                                onClick={(e) => { copy(e, tag, tag.value) }}
                                                key={tag.value}>{tag.label}</Dropdown.Item>
                                        })}

                                    </Dropdown.Menu>
                                </div>
                            </Col>}
                        </Row>
                    }
                </Col>
            </Row>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={saveLoading} className="float-end">
                        Save & Next
                        {saveLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, false)} disabled={saveLoading} className="float-end me-2">
                        Save
                        {saveLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ErrorBoundary>)
}
export default (PCPGoals)