import React from 'react';
import { useState, memo } from 'react';

import FPGetOTPForm from './FPGetOTPForm';
import FPResendOTPForm from './FPResendOTPForm';
import ResetPasswordForm from './ResetPasswordForm';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
const ForgotPasswordForm = ({ afterResetLogin }) => {
    
    const [mobileno, setMobileno] = useState("");
    const [showFPGetOTP, setShowFPGetOTP] = useState(true);
    const [showFPResendOTP, setShowFPResendOTP] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const handleOTP = ((mobileno) => {
        setShowFPGetOTP(false);
        setShowFPResendOTP(true);
        setShowResetPassword(false);
        setMobileno(mobileno);
    })
    const handleResetPassword = (() => {
        setShowFPGetOTP(false);
        setShowFPResendOTP(false);
        setShowResetPassword(true);
    })
    const afterReset = (() => {
        //navigate('/login');    
        afterResetLogin();
    });
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            {showFPGetOTP ? <FPGetOTPForm showHide={handleOTP} /> : null}
            {showFPResendOTP ? <FPResendOTPForm mobile={mobileno} showRP={handleResetPassword} /> : null}
            {showResetPassword ? <ResetPasswordForm mobile={mobileno} heading={"Create New Password"} afterReset={afterReset} /> : null}
        </ErrorBoundary>
    );
}

export default (memo(ForgotPasswordForm));
