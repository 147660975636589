import { useState } from "react"
import { Card, Button, Spinner, Row, Col } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import Icon from "../../controls/Icons/Icon";
import { useNavigate } from 'react-router-dom';
import { dateDisplayFormater, getFileNamewithDate } from "../../../Utils";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";
const Client = () => {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            field: "FirstName",
            title: "First Name",
            sortName: "FirstName",
            sortable: true,
        },
        {
            field: "LastName",
            title: "Last Name",
            sortable: true,
        },
        //{
        //    field: "DOB",
        //    title: "DOB",
        //    sortable: true,
        //    formatter: dateDisplayFormater
        //},
        {
            field: "MotherName",
            title: "Mother's Name",
            sortable: true,
        },
        {
            field: "FatherName",
            title: "Father's Name",
            sortable: true,
        },
        //{
        //    field: "MobileNumber",
        //    title: "Contact No.",
        //    sortable: true,
        //},
        {
            field: "user.name",
            title: "Created By",
            sortable: false,
        },
        {
            field: "CreatedDate",
            title: "Created Date",
            sortable: false,
            formatter: dateDisplayFormater
        },

        {
            dataField: "remove",
            title: "",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button text="PCP" variant="link" tooltip="PCP"
                        className="btn-xs me-2 p-0" variant="link"
                        onClick={() => handleEdit(row, 1)}> <Icon name="BsPeopleFill" title="PCP" className='fs-5' /></Button> : ""}

                    {state.access.edit ? <Button variant="link" text="Expense" tooltip="Spending Plan"
                        className="btn-xs p-0"
                        onClick={() => handleEdit(row, 2)}>
                        <Icon title="Spending Plan" name="BsPiggyBankFill" className='fs-5' />
                    </Button> : ""}
                    {state.access.delete && state.access.user.fkRoleId == 1 ? <Button variant="link"
                        className="btn-xs p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <Icon name="BsTrash" className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        }
    ];
    const handleEdit = (res, to) => {
        if (to == 1)
            navigate("/admin/pcpform/" + res.Id + "/personal");
        else
            navigate("/admin/pcpform/" + res.Id + "/spendingplangenerator");
    };

    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the Client and Client related data?", function () {
            setLoading(true);
            fetchData(state.projectdetail.API + "/Client/delete?Id=" + res.Id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                //body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    window.alert("Deleted successfuly");
                    setRefreshGrid(!refreshGrid);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("Client.js ==> delete", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        })

    });

    const handleAdd = () => {
        navigate("/admin/pcpform/0/personal");
    };
    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "CreatedDate",
        sortOrder: "desc",
        exportDocumetName: "Clients",
        toolbar: (state.access.add ? <div><Button onClick={handleAdd}>Add New PCP</Button> <Button onClick={handleAdd}>Add New Spending Plan</Button></div> : <div></div>)
    };
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Row>
                <Col>
                    <h4 className='form-heading'>
                        Client
                    </h4>
                </Col>
            </Row>

            <Card>
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/client/grid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
        </ErrorBoundary>
    );
}

export default (Client);
