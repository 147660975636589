import { memo } from "react";
import { Form, InputGroup } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../ErrorHandler";
const CurrencyInputText = ({ name, required, errorMessage, onChange, value, containerClassName, ...otherProps }) => {
    let className = (otherProps && otherProps.hasOwnProperty("className")) ? otherProps["className"] : "";
    className = className + " text-start"; 
    delete otherProps.className;
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <InputGroup className={containerClassName}>
                {/*<InputGroup.Text>$</InputGroup.Text>*/}
                <CurrencyInput className={className}
                    required={required}
                    type="text"
                    step=".01"                                      
                    name={name}                    
                    allowNegativeValue={false}                    
                    value={value}         
                    decimalsLimit={ 2}
                    onValueChange={onChange}
                    autoComplete="off" {...otherProps}
                    intlConfig={{ locale: 'en-US', currency: 'USD' }} 
                    prefix=""
                />
            </InputGroup>
            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
        </ErrorBoundary>
    )
}
export default (memo(CurrencyInputText));