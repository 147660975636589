import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, Modal, CloseButton, Spinner, Row, Col } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import PCPMeetingForm from './PCPMeetingForm';
import Icon from "../../controls/Icons/Icon";
import { convertTimeTo12HourFormat, dateDisplayFormater, minsToHHMMSS } from "../../../Utils";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";

const PCPMeeting = () => {
    const { fetchData } = useFetchWithCancellation();
    const navigate = useNavigate();
    const { id } = useParams();
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState({
    });
    const SaveData = async (e) => {
        navigate("/admin/pcpform/" + id + "/flipbook");
    }
    const [summary, setSummary] = useState({});
    const columns = [
        {
            field: "meetingdate",
            title: "Date",
            sortName: "meetingdate",
            sortable: true,
            formatter: dateDisplayFormater
        },
        {
            field: "fromtime",
            title: "From Time",
            sortable: true,
            formatter: convertTimeTo12HourFormat,
        },
        {
            field: "totime",
            title: "To Time",
            sortable: true,
            formatter: convertTimeTo12HourFormat,
        },
        {
            field: "agenda",
            title: "Agenda",
            sortable: true,
        },
        {
            field: "organizedby",
            title: "Organized By",
            sortable: true,
        },
        {
            field: "icc",
            title: "ICC",
            sortable: true,
        },
        {
            field: "family",
            title: "Family",
            sortable: true,
        },
        {
            dataField: "remove",
            title: "",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleEdit(row)}
                    ><Icon name="BsPencilFill" className='fs-5' /></Button> : ""}

                    {state.access.delete ? <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <Icon name="BsTrash" className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        }
    ];

    const handleEdit = (res) => {
        let row = JSON.parse(JSON.stringify(res));
        row.fromtime = new Date(row.meetingdate + " " + row.fromtime);
        row.totime = new Date(row.meetingdate + " " + row.totime);
        row.meetingdate = new Date(res.meetingdate);
        setInputValue(row);
        setShowModal(true);
    };

    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the Meetings?", function () {
            setLoading(true);
            fetchData(state.projectdetail.API + "/pcp/DeleteMeetings?Id=" + res.Id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    window.alert("Deleted successfuly");
                    setRefreshGrid(!refreshGrid);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }

            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPMeeting.js ==> handleDelete", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        })

    });
    const handleAdd = () => {
        setInputValue([])
        setShowModal(true)
    };
    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "meetingdate",
        sortOrder: "desc",
        exportDocumetName: "Meetings",
        toolbar: state.access.add ? <Button onClick={handleAdd}>Add PCP Meetings</Button> : <div></div>
    };
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const handleAfter = (res) => {
        if (res.Succeeded) {
            window.alert("Meetings saved successfully");
            handleClose();
            setRefreshGrid(!refreshGrid);
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/pcp/GetMeetingsSummary?clientId=" + id)
            .then(response => response.json()).then((res) => {
                setSummary(res);
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPMeeting.js ==> GetMeetingsSummary", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, [refreshGrid]);
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Row>
                <Col>
                    <h5 className="d-inline-block">Meetings</h5>
                </Col>
            </Row>
            <Row className="mb-8 justify-content-center">
                <Col xs="6" sm={3} md={2}>
                    <Card className="withborder">
                        <Card.Body className="p-2">
                            <Row className="align-items-center">
                                <Col xs="auto" className="text-center">
                                    <Icon name="MdGroups" className="text-success fs-2" />
                                </Col>
                                <Col className="text-center">
                                    <h5><b>{summary.TotalMeetings < 10 ? "0" + summary.TotalMeetings : summary.TotalMeetings}</b></h5>
                                    <span >Total<br></br>Meetings</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="6" sm={3} md={2}>
                    <Card className="withborder">
                        <Card.Body className="p-2">
                            <Row className="align-items-center">
                                <Col xs="auto" className="text-center">
                                    <Icon name="BsAlarmFill" className="text-info fs-2" />
                                </Col>
                                <Col className="text-center">
                                    <h4><b>{minsToHHMMSS(summary.TotalMinutes)}</b></h4>
                                    <span>Total<br></br> Hours</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card className="withborder">
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/pcp/grid?clientId=" + id} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    <h4>{inputValue.Id ? "Edit " : "Add "} Meetings</h4>
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <PCPMeetingForm afterSave={handleAfter} _inputValue={inputValue} onCancel={handleClose} />
                </Modal.Body>
            </Modal>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Button variant="primary" type="button" onClick={SaveData} disabled={loading} className="float-end">
                        Next
                    </Button>
                </Col>
            </Row>
        </ErrorBoundary>
    );
}
export default (PCPMeeting);
