import React, { useState, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { AccessAction } from '../Reducer/AccessReducer';
import ErrorHandler from './controls/ErrorHandler';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import AccessDenied from './controls/AccessDenied';
import PageSkeleton from './skeleton/PageSkeleton';
import { Card } from 'react-bootstrap'
import Menu from './controls/Menu/Menu';
import AlertPrompt from './controls/Prompt/AlertPrompt';
import ConfirmPrompt from './controls/Prompt/ConfirmPrompt';
import ToastPrompt from './controls/Prompt/ToastPrompt';
import Icon from './controls/Icons/Icon';
import { ErrorBoundary } from 'react-error-boundary';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import RefreshTokenPrompt from './controls/Prompt/RefreshTokenPrompt';

function Layout(props) {
    const loc = useLocation();
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [outlet, setOutlet] = useState("");
    const [showRefreshToken, setShowRefreshToken] = useState(false);

    const urlParams = useParams();
    Date.prototype.toJSON = function () {
        // Customize the date and time format as desired
        const year = this.getFullYear();
        const month = (this.getMonth() + 1).toString().padStart(2, '0');
        const day = this.getDate().toString().padStart(2, '0');
        const hours = this.getHours().toString().padStart(2, '0');
        const minutes = this.getMinutes().toString().padStart(2, '0');
        const seconds = this.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };

    const [alertOptions, setAlertOptions] = useState({
        message: "",
        icon: "",
        show: false,
        onYes: function () { },
        ButtonText: "OK",
        varient: "success",
    })

    const [confirmOptions, setConfirmOptions] = useState({
        message: "",
        icon: "",
        show: false,
        onYes: function () { },
        onNo: function () { },
        YesButtonText: "Yes",
        NoButtonText: "No"
    })
    const [toastOptions, setToastOptions] = useState({
        message: "",
        show: false,
        position: "bottom-center",
        variant: "light",
    });
    const onFocus = () => {
        let loggedUser = localStorage.getItem("user");
        if (loggedUser) {
            let objLoggedUser = JSON.parse(loggedUser);
            if (objLoggedUser.AccessTokenExpires && new Date(objLoggedUser.AccessTokenExpires) < new Date()) {
                setShowRefreshToken(true);
            }
        }
        else {
            window.alert("Your session expired", function () {
                localStorage.clear();
                navigate("/");
            })
        }
    }
    useEffect(() => {
        const originalFetch = window.fetch;
        const originalAlert = window.alert;
        const originalConfirm = window.confirm;
        window.addEventListener("focus", onFocus);
        window.fetch = async (...args) => {
            let [resource, config] = args;
            if (!config) {
                config = {};
            }
            config.credentials = "include";
            let loggedUser = localStorage.getItem("user");
            if (loggedUser) {
                let objLoggedUser = JSON.parse(loggedUser);
                if (config.headers && Object.keys(config.headers).length > 0) {
                    config.headers["Authorization"] = "Bearer " + objLoggedUser.AccessToken;
                }
                else {
                    config.headers = { "Authorization": "Bearer " + objLoggedUser.AccessToken };
                }
            }
            let response = await originalFetch.call(this, resource, config);
            if (response.status === 403) {
                localStorage.clear();
                navigate("/");
            }
            else if (response.status == 401) {
                window.alert("Your session expired", function () {
                    localStorage.clear();
                    navigate("/");
                })
                //let tokenRefreshing = localStorage.getItem("refreshToken");
                //if (tokenRefreshing != "1") {
                //    try {
                //        localStorage.setItem("refreshToken", "1");
                //        let objLoggedUserA = JSON.parse(loggedUser);
                //        let refreshModel = {
                //            email: objLoggedUserA.email,
                //            refreshToken: objLoggedUserA.RefreshToken,
                //            accessToken: objLoggedUserA.AccessToken
                //        };
                //        let refRes = await originalFetch.call(this, state.projectdetail.API + "/auth/refresh", {
                //            method: "POST",
                //            async: false,
                //            body: JSON.stringify(refreshModel),
                //            headers: {
                //                "Content-Type": "application/json",
                //            },
                //        });
                //        if (refRes) {
                //            let refResData = await refRes.json();
                //            if (refResData && refResData.Succeeded) {
                //                localStorage.setItem("user", JSON.stringify(refResData.Data));
                //                response = await originalFetch.call(this, resource, config);
                //                return response;
                //            }
                //            else {
                //                throw ("Refresh failed");
                //            }
                //        }
                //        else {
                //            throw ("Refresh failed");
                //        }
                //        localStorage.setItem("refreshToken", "0");
                //    }
                //    catch (error) {
                //        window.HandleError("Layout.js ==> RefreshToken", null, null, error);
                //        localStorage.clear();
                //        navigate("/");
                //    }
                //}
            }
            else if (response.status == 400) {
                window.alert("Some of the input is not valid, please verify your data", function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
            else if (response.status == 500) {
                window.alert(state.projectdetail.ErrorMessage, () => { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
            else {
                if (response.ok) {
                    localStorage.setItem("refreshToken", "0");
                    return response;
                }
                else {
                    console.log(response);
                    window.alert(state.projectdetail.ErrorMessage, () => { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }
        };
        window.HandleError = (source, lineno, colno, error) => {
            //console.error(`An error occurred: ${error.message}`);
            //console.error(`Source: ${source}`);
            //console.error(`Line: ${lineno}, Column: ${colno}`);
            //console.error(error);

            let obj = {};
            obj.message = error ? (error.message ? error.message : JSON.stringify(error)) : "";
            obj.source = source;
            obj.colno = JSON.stringify(colno);
            obj.lineno = JSON.stringify(lineno);

            var nVer = navigator.appVersion;
            var nAgt = navigator.userAgent;
            var browserName = navigator.appName;
            var fullVersion = '' + parseFloat(navigator.appVersion);
            var majorVersion = parseInt(navigator.appVersion, 10);
            var nameOffset, verOffset, ix;

            // In Opera, the true version is after "OPR" or after "Version"
            if ((verOffset = nAgt.indexOf("OPR")) != -1) {
                browserName = "Opera";
                fullVersion = nAgt.substring(verOffset + 4);
                if ((verOffset = nAgt.indexOf("Version")) != -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In MS Edge, the true version is after "Edg" in userAgent
            else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
                browserName = "Microsoft Edge";
                fullVersion = nAgt.substring(verOffset + 4);
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
                browserName = "Microsoft Internet Explorer";
                fullVersion = nAgt.substring(verOffset + 5);
            }
            // In Chrome, the true version is after "Chrome" 
            else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
                browserName = "Chrome";
                fullVersion = nAgt.substring(verOffset + 7);
            }
            // In Safari, the true version is after "Safari" or after "Version" 
            else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
                browserName = "Safari";
                fullVersion = nAgt.substring(verOffset + 7);
                if ((verOffset = nAgt.indexOf("Version")) != -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In Firefox, the true version is after "Firefox" 
            else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
                browserName = "Firefox";
                fullVersion = nAgt.substring(verOffset + 8);
            }
            // In most other browsers, "name/version" is at the end of userAgent 
            else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
                (verOffset = nAgt.lastIndexOf('/'))) {
                browserName = nAgt.substring(nameOffset, verOffset);
                fullVersion = nAgt.substring(verOffset + 1);
                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            // trim the fullVersion string at semicolon/space if present
            if ((ix = fullVersion.indexOf(";")) != -1)
                fullVersion = fullVersion.substring(0, ix);
            if ((ix = fullVersion.indexOf(" ")) != -1)
                fullVersion = fullVersion.substring(0, ix);

            majorVersion = parseInt('' + fullVersion, 10);
            if (isNaN(majorVersion)) {
                fullVersion = '' + parseFloat(navigator.appVersion);
                majorVersion = parseInt(navigator.appVersion, 10);
            }
            obj.browserName = JSON.stringify(browserName);
            obj.fullVersion = JSON.stringify(fullVersion);
            obj.majorVersion = JSON.stringify(majorVersion);
            obj.appName = JSON.stringify(navigator.appName);
            obj.userAgent = JSON.stringify(navigator.userAgent);
            fetch(state.projectdetail.API + "/FrontendError/Track", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            }).then((res) => {
                console.log("Error captured");
            }).catch((err) => {
                console.log("Error not captured");
            });
        }
        window.onerror = (message, source, lineno, colno, error) => {
            window.HandleError(source, lineno, colno, error);
        };
        window.alert = (msg, onYes, icon, ButtonText, varient) => {
            if (msg && msg.name && msg.name === "AbortError")
                return;
            if (msg && msg.message && msg.message)
                msg = msg.message;
            setAlertOptions(
                {
                    show: true,
                    message: (typeof msg !== "string" ? JSON.stringify(msg) : msg),
                    onYes: onYes,
                    icon: icon,
                    ButtonText: ButtonText,
                    varient: varient,
                }
            );
        }
        window.hideAlert = () => {
            setAlertOptions({ show: false, message: "", onYes: null, icon: "" });
        }


        window.confirm = (msg, onYes, onNo, icon, YesButtonText, NoButtonText, confirmCheckbox, confirmCheckboxText) => {
            setConfirmOptions(
                {
                    show: true,
                    message: (typeof msg !== "string" ? JSON.stringify(msg) : msg),
                    onYes: onYes,
                    onNo: onNo,
                    icon: icon,
                    YesButtonText: YesButtonText,
                    NoButtonText: NoButtonText,
                    confirmCheckbox: confirmCheckbox,
                    confirmCheckboxText: confirmCheckboxText
                }
            );
        }
        window.hideConfirm = () => {
            setConfirmOptions({ show: false, message: "", onYes: null, icon: "" });
        }
        window.toast = (msg, position, variant) => {
            setToastOptions(
                {
                    show: true,
                    message: (typeof msg !== "string" ? JSON.stringify(msg) : msg),
                    position: position,
                    variant: variant,
                }
            );
            setTimeout(function () {
                setToastOptions(c => ({ ...c, show: false }));
            }, 5000);
        }
        onFocus();
        //const sessionCheck = setTimeout(() => {
        //    onFocus();
        //}, 6000);
        return (() => {
            //if (sessionCheck)
            //    clearTimeout(sessionCheck);
            window.removeEventListener("focus", onFocus);
            window.fetch = originalFetch;
            window.alert = originalAlert;
            window.confirm = originalConfirm;
        })
    }, []);
    useEffect(() => {
        var location = loc.pathname;
        if (urlParams && Object.keys(urlParams).length > 0) {
            for (let urlParamKey in urlParams) {
                location = location.replace("/" + urlParams[urlParamKey], "/:" + urlParamKey);
            }
        }
        if (state.access.authorizedMenus) {
            let getCurrentMenu = {};
            for (let loop = 0; loop < state.access.authorizedMenus.length; loop++) {
                let m = state.access.authorizedMenus[loop];
                if (m.tblMenu.path && location) {
                    if (m.tblMenu.path.toLowerCase() == location.toLowerCase()) {
                        getCurrentMenu = { ...m };
                        break;
                    }
                }
            }
            if (Object.keys(getCurrentMenu).length == 0) {
                setOutlet(<PageNotFound menu={loc.pathname} />);
            }
            else if (!getCurrentMenu.view) {
                setOutlet(<AccessDenied menu={loc.pathname} options={getCurrentMenu} />);
            }
            else {
                getCurrentMenu.authorizedMenus = state.access.authorizedMenus;
                getCurrentMenu.authorizedModules = state.access.authorizedModules;
                dispatch({ type: AccessAction.setAccess, payload: { ...getCurrentMenu } });
                setOutlet(<Outlet />);
            }
        }
        else {
            setOutlet(<PageSkeleton />)

            fetch(state.projectdetail.API + "/roleaccess/currentaccess?path=" + location, { credentials: "include" }).then(res => res.json()).then((res) => {
                if (res && res.hasOwnProperty("menuAccess") && res.menuAccess) {
                    dispatch({ type: AccessAction.setAccess, payload: { ...res.menuAccess, "authorizedMenus": res.authorizedMenus, "user": res.user } });
                    if (res.menuAccess.authorizedMenu) {
                        if (res.menuAccess.sessionExpired) {
                            localStorage.clear();
                            state.access.showMenu = false;
                            navigate("/");
                        }
                        else {
                            if (!res.menuAccess.view) {
                                setOutlet(<AccessDenied />)
                            }
                            else {
                                setOutlet(<Outlet />);
                            }
                        }
                    }
                    else {
                        setOutlet(<Outlet />);
                    }
                }
                else {
                    setOutlet(<PageNotFound menu={loc.pathname} />)
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("Layout.js ==> currentaccess", null, null, err);
                setOutlet(<ErrorHandler error={err} />)
            });
        }
    }, [loc.pathname]);
    useEffect(() => {
        if (state && state.access && state.access.user && state.access.user.theme)
            document.body.setAttribute("data-theme", state.access.user.theme)
        else {
            document.body.setAttribute("data-theme", "turquoise")
        }
    }, [state])
    if (state.access.authorizedMenu) {
        if (state.access.sessionExpired) {
            return <></>
        }
        return (
            <div>
                {/*<SliderLayout outlet={outlet} />*/}
                <Card className="mb-3">
                    <Menu />
                </Card>
                <div className="container-fluid mt-2 mb-5">
                    {alertOptions.show ?
                        <AlertPrompt options={alertOptions} />
                        : ""}
                    <RefreshTokenPrompt showRefreshToken={showRefreshToken} />
                    {confirmOptions.show ?
                        <ConfirmPrompt options={confirmOptions} />
                        : ""}
                    {toastOptions.show && <ToastPrompt options={toastOptions} />}
                    {outlet}
                </div>
                <Footer />
            </div>
        )
    }
    else {
        return (
            <div>
                {alertOptions.show ?
                    <AlertPrompt options={alertOptions} />
                    : ""}
                {confirmOptions.show ?
                    <ConfirmPrompt options={confirmOptions} />
                    : ""}
                {outlet}
                <Footer />
            </div>
        )
    }
}

export default (memo(Layout));