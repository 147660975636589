export const PCPAction = {
    SetPersonalData: "SETPERSONAL",
    SetPCPHasUnsavedChanges: "SETHASUNSAVEDCHANGES",
};
const initialPCP = {
    personalData: {},
    hasUnsavedChanges: false,
};

const PCPReducer = (pcp = initialPCP, actions) => {
    switch (actions.type) {
        case PCPAction.SetPersonalData:
            return { ...pcp, personalData: actions.payload };
        case PCPAction.SetPCPHasUnsavedChanges:
            return { ...pcp, hasUnsavedChanges: actions.payload };
        default:
            return pcp;
    }
}
export default PCPReducer;