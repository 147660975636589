import { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button, Form } from 'react-bootstrap';
import PhotoCropper from '../../controls/FileUpload/PhotoCropper';
import { useSelector } from 'react-redux';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';


function PCPPicPage() {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const { id } = useParams();
    const picFolderPath = "client/" + id + "/pcp/frontpage/";
    const picphoto1Ref = useRef(null);
    const picphoto2Ref = useRef(null);
    const [loading, setLoading] = useState(false);
 
    const [inputValue, setInputValue] = useState({ Id: 0, fkClientId: id });
    const formatSaveParams = (filename, fieldname) => {
        return {
            ...inputValue, [fieldname]: filename, fkClientId: id
        }
    }
    const afterSave = (filename, fieldname) => {
        setInputValue({ ...inputValue, [fieldname]: filename });
    }
    const SaveData = () => {
        navigate("/admin/pcpform/" + id + "/letteroflove");        
    }
    useEffect(() => {
        fetchData(state.projectdetail.API + "/pcp/GetFrontPages?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    let frontPage = res[0];
                    setInputValue(frontPage);
                    if (frontPage.Pic1) {
                        picphoto1Ref.current.setUploadedFile(frontPage.Pic1);
                    }
                    if (frontPage.Pic2) {
                        picphoto2Ref.current.setUploadedFile(frontPage.Pic2);
                    }
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPPicPage.js ==> GetFrontPages", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate>
            <Row>
                <Col>
                    <h5>Front Page</h5>
                    <Form.Text>Upload at least 1 picture</Form.Text>
                </Col>                
            </Row>
            <Row className="justify-content-center">
                <Col md={"auto"}>
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <PhotoCropper ref={picphoto1Ref} height={300} width={250}
                                    roundedCrop={true}
                                    uploadFolderPath={picFolderPath}
                                    showDelete={false}

                                    autoSave={true}
                                    saveURL={state.projectdetail.API + "/pcp/SaveFrontPage"}
                                    afterSave={(filename) => { afterSave(filename, "Pic1") }}
                                    formatSaveParams={(filename) => { return formatSaveParams(filename, "Pic1") }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={"auto"}>
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <PhotoCropper ref={picphoto2Ref} height={300} width={250}
                                    roundedCrop={true}
                                    uploadFolderPath={picFolderPath}

                                    autoSave={true}
                                    saveURL={state.projectdetail.API + "/pcp/SaveFrontPage"}
                                    afterSave={(filename) => { afterSave(filename, "Pic2") }}
                                    formatSaveParams={(filename) => { return formatSaveParams(filename, "Pic2") }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Button variant="primary" type="button" onClick={SaveData} disabled={loading} className="float-end">
                        Next                        
                    </Button>
                </Col>
            </Row>
        </Form>
    </ErrorBoundary>
    );
}
export default (PCPPicPage);