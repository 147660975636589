
import { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Spinner, Button, Form, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import HTMLFlipBook from 'react-pageflip';
import { useEffect } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "./PCPFlipbook.css";
import Icon from '../../controls/Icons/Icon';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PCPFlipbookView() {
    const { fetchData } = useFetchWithCancellation();
    const { id } = useParams();
    const [numPages, setNumPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const state = useSelector(state => state);
    const bookRef = useRef(null);
    const [PCPLink, setPCPLink] = useState({});
    const [pageWidth, setPageWidth] = useState(500);
    const [pageHeight, setPageHeight] = useState(705);
    const [pdfURL, setPdfURL] = useState("");//state.projectdetail.API + "/PCP/PreviewPDF?Id=" + id

    async function onDocumentLoadSuccess(docu) {
        if (docu.numPages > 0) {
            let firstPage = await docu.getPage(1);
            if (firstPage) {
                setPageHeight(firstPage.view[3]);
                setPageWidth(firstPage.view[2] + 50);
            }
        }
        setNumPages(docu.numPages);
        setLoading(false);
    }

    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        let arrPages = [];
        for (let i = 1; i <= numPages; i++) {
            arrPages.push(i);
        }
        setPages(arrPages);
    }, [numPages]);
    const onFlip = useCallback((e) => {
        setCurrentPage(e.data + 1);
    }, []);
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/PCPLink/GetLink?Id=" + id).then(res => res.json()).then((res) => {
            if (res && res.length > 0) {
                setPCPLink(res[0]);
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [])
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Row>
                <Col className="text-center">

                </Col>
            </Row>
            {loading ? <Row>
                <Col className="text-center">
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </Col>
            </Row>
            :(PCPLink && PCPLink.LinkId) ? <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <div className="mb-3 text-center">
                                <img src="./logo.png" className="img-fluid home-img" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-2">
                        <Col className="col-auto">
                            <Button disabled={currentPage == 1} size="sm" className="me-2" onClick={() =>
                                bookRef.current.pageFlip().flipPrev()}>Previous Page</Button>
                            <span>[{currentPage} of {numPages}]</span>
                            <Button disabled={currentPage == numPages} size="sm" className="ms-2" onClick={() =>
                                bookRef.current.pageFlip().flipNext()}>Next Page</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Document file={state.projectdetail.API + "/PCPLink/PreviewPDF?Id=" + PCPLink.fkClientId} onLoadSuccess={onDocumentLoadSuccess}>
                                {pages.length > 0 && <HTMLFlipBook autoSize={false} className="flip-book" ref={bookRef} showCover={true} onFlip={onFlip} renderOnlyPageLengthChange={true}
                                    width={pageWidth} height={pageHeight}>
                                    {pages.map((pagenum) => {
                                        return <div className="page" key={pagenum}>
                                            <Page pageNumber={pagenum} />
                                        </div>
                                    })}
                                </HTMLFlipBook>}
                            </Document>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
                :
                <div className="">
                    <Row className="d-flex min-vh-100 align-items-center justify-content-center">
                        <Col md={4}>
                            <Card>
                                <Card.Body>

                                    <Row>
                                        <Col className="text-center">
                                            <div className="mb-3">
                                                <img src="./logo.png" className="img-fluid home-img" />
                                            </div>
                                            <div>
                                                <Icon name="BsLockFill" className="fs-1" /></div>
                                            <h3><b>Link is expired</b></h3>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
        </ErrorBoundary>
    );
}
export default (PCPFlipbookView);