import React, { useEffect, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ErrorHandler from '../ErrorHandler';
import { Nav, NavDropdown } from 'react-bootstrap';
import Icon from '../Icons/Icon';
import MenuSkeleton from '../../skeleton/MenuSkeleton';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';

import { ErrorBoundary } from 'react-error-boundary';
const MenuList = ({ onUserClick }) => {
    const { fetchData } = useFetchWithCancellation();
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const [menus, setMenus] = useState([]);
    const [submenus, setSubMenus] = useState([]);
    const [error, setError] = useState("");
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [openParentId, setOpenParentId] = useState(0);
    useEffect(() => {
        if (!state.access.sessionExpired) {
            setLoading(true);
            fetchData(state.projectdetail.API + "/roleaccess/authorizedMenu", { credentials: 'include', method: "POST" })
                .then(res => res.json()).then((res) => {
                    setError(null);
                    let mainMenus = [];
                    let subMenus1 = [];
                    res.forEach(function (r) {
                        if (r.tblMenu.parentId == null || r.tblMenu.parentId == undefined) {
                            mainMenus.push(r);
                        }
                        else {
                            if (loc.pathname == r.tblMenu.path) {
                                setOpenParentId(r.tblMenu.parentId);
                            }
                            subMenus1.push(r);
                        }
                    });
                    setSubMenus(subMenus1);
                    setMenus(mainMenus);
                    setLoading(false);
                })
                .catch((err) => {
                    if (err && err.name === 'AbortError') return;
                    window.HandleError("MenuList.js ==> fetch", null, null, err);
                    setLoading(false);
                    setError(err);
                });
        }
    }, [state.access.sessionExpired]);
    useEffect(() => {
        if (submenus && submenus.length > 0) {
            let mdata = [...submenus];
            let noSubmenu = true;
            mdata.forEach(function (r) {
                if (loc.pathname == r.tblMenu.path) {
                    if (r.tblMenu.parentId) {
                        setOpenParentId(r.tblMenu.parentId);
                        noSubmenu = false;
                    }
                }
            });
            if (noSubmenu) {
                setOpenParentId(-1)
            }
        }
    }, [submenus, loc.pathname]);
    const getSubmenu = function (id) {
        let menuData = submenus.filter(function (m) {
            return m.tblMenu.parentId == id;
        });
        return menuData
    }
    const logout = () => {
        window.confirm("Do you want to logout?", function () {
            fetchData(state.projectdetail.API + "/auth/logout", { method: "POST" }).then(() => {
                localStorage.removeItem("user");
                navigate("/");
            });
        });
    };

    const [parentOpenId, setParentOpenId] = useState(0);
    if (error) {
        return <ErrorHandler error={error} />
    }
    if (loading) {
        return <MenuSkeleton />
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Nav className="me-auto">
                {menus.map((menu, index) => {
                    if (menu.tblMenu.isParent) {
                        return (<NavDropdown key={menu.tblMenu.Id} title={menu.tblMenu.menuName} id="basic-nav-dropdown">
                            {getSubmenu(menu.tblMenu.Id).map((subMenu) => {
                                let cssclass = "";
                                if (loc.pathname == subMenu.tblMenu.path) {
                                    cssclass = "active"
                                }
                                return (
                                    <NavDropdown.Item key={subMenu.tblMenu.Id} as={Link} to={subMenu.tblMenu.path}><Icon name={subMenu.tblMenu.icon} />&nbsp;{subMenu.tblMenu.menuName}</NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>)
                    }
                    else {
                        let cssclass = "";
                        if (loc.pathname == menu.tblMenu.path) {
                            cssclass = "active"
                        }
                        return (<Nav.Item key={menu.tblMenu.Id} ><Nav.Link as={Link} to={menu.tblMenu.path} className={cssclass}><Icon name={menu.tblMenu.icon} />&nbsp;{menu.tblMenu.menuName}</Nav.Link></Nav.Item>)
                    }
                })}
            </Nav>
        </ErrorBoundary>
    );
}

export default (memo(MenuList));
