import React, { memo } from 'react';
import * as BootstrapIcon from 'react-icons/bs'
import * as BoxIcon from 'react-icons/bi'
import * as AntDesignIcons from 'react-icons/ai'
import * as MaterialDesignIcons from 'react-icons/md'
import * as Heroicons from "react-icons/hi"
import * as fa from 'react-icons/fa'
import * as im from 'react-icons/im'
import * as io from 'react-icons/io'
import * as io5 from "react-icons/io5";
import * as tb from "react-icons/tb";
import * as si from "react-icons/si";
import * as md from "react-icons/md";
import * as gi from "react-icons/gi";
import './Icon.css'
import { ErrorBoundary } from 'react-error-boundary';
const Icon = ({ name, className, ...otherProps }) => {
    if (name && BootstrapIcon[name]) {
        const icon = React.createElement(BootstrapIcon[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && BoxIcon[name]) {
        const icon = React.createElement(BoxIcon[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && AntDesignIcons[name]) {
        const icon = React.createElement(AntDesignIcons[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && MaterialDesignIcons[name]) {
        const icon = React.createElement(MaterialDesignIcons[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && Heroicons[name]) {
        const icon = React.createElement(Heroicons[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && fa[name]) {
        const icon = React.createElement(fa[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && im[name]) {
        const icon = React.createElement(im[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && io[name]) {
        const icon = React.createElement(io[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && io5[name]) {
        const icon = React.createElement(io5[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && tb[name]) {
        const icon = React.createElement(tb[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && si[name]) {
        const icon = React.createElement(si[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && md[name]) {
        const icon = React.createElement(md[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else if (name && gi[name]) {
        const icon = React.createElement(gi[name]);
        return (<span className={'icon ' + className} {...otherProps}>{icon}</span>);
    }
    else {
        return (
            <></>
        )
    }
}
export default (memo(Icon));