import { Spinner } from "react-bootstrap";
import { useState, useEffect, memo, useRef } from "react";
import ErrorHandler from './ErrorHandler'
import Multiselect from "multiselect-react-dropdown";
import useFetchWithCancellation from "../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
const DynamicMultiSelect = ({ reset, customData, from, label, value, fetchType, name, onDropdownChange, defaultval, required, addAttr, refresh }) => {
    const { fetchData } = useFetchWithCancellation();
    const [data, setData] = useState([]);
    const [refreshDropdown, setRefreshDropdown] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    var opts = {};
    if (required) {
        opts['required'] = 'required';
    }
    useEffect(() => {
        ref.current.resetSelectedValues();
    }, [reset]);

    useEffect(() => {
        try {
            setError(null);
            if (from) {
                if (!label) {
                    label = "label";
                }
                if (!value) {
                    value = "value";
                }
                let obj = {};
                obj.method = fetchType;
                obj.credentials = "include";
                if (obj.method.toLowerCase() == "POST") {
                    obj.headers = {
                        "Content-Type": "application/json"
                    };
                }

                setLoading(true);
                fetchData(from, obj).then(response => response.json()).then((res) => {
                    setError(null);
                    setData(res)
                }).finally(() => {
                    setLoading(false);
                });
            }
            else if (customData && customData.length > 0) {
                setData(customData);
            }
            else {
                setData([]);
            }
        }
        catch (error) {
            if (error && error.name === 'AbortError') return;
            window.HandleError("DynamicMultiSelect.js ==> fetch", null, null, error);
            setError(error);
        }
    }, [refreshDropdown, refresh]);

    const ref = useRef();
    if (error) {
        return <ErrorHandler error={error} />
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <div className="position-relative">
                <Multiselect ref={ref} onRemove={onDropdownChange} onSelect={onDropdownChange} avoidHighlightFirstOption={true} showCheckbox={true} displayValue={label} options={data} />
                {loading ? <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
            </div>
        </ErrorBoundary>
    );
}

export default (memo(DynamicMultiSelect))