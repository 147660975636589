import { useState, useEffect } from "react"
import { Card, Button, Modal, Dropdown, Spinner, Row, Col, Form, ButtonGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Icon from "../../controls/Icons/Icon";
import DynamicDropdownSearchable from "../../controls/DynamicDropdownSearchable";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";

const StateTax = () => {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState({
        Id: 0,
        TaxHead: [],
        StateName: "",
        DeletedTax: [],
        fkStateId: 5
    });
    useEffect(() => {
        fetchData(state.projectdetail.API + "/StateTax/GetByState?Id=" + 5, {
            method: "Get"
        }).then(response => response.json()).then((res) => {
            if (res) {
                if (res.TaxHead.length == 0) {
                    res.TaxHead.push({ Id: 0, TaxName: "", fkStateId: 5 })
                }
                setInputValue(res);

            }
            else {
                window.alert('Something went wrong', function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }

        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("StateTax.js ==> StateTax-GetByState", null, null, err);
            console.log(err)
        }).finally(() => {
            //setLoading(false);
        });
    }, [setrefresh]);

    const LoadTax = ((state) => {
        fetchData(state.projectdetail.API + "/StateTax/GetByState?Id=" + state, {
            method: "Get"
        }).then(response => response.json()).then((res) => {
            if (res) {
                if (res.TaxHead.length == 0) {
                    res.TaxHead.push({ Id: 0, TaxName: "", fkStateId: state })
                }
                setInputValue(res);

            }
            else {
                window.alert('Something went wrong', function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }

        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("StateTax.js ==> LoadTax StateTax-GetByState", null, null, err);
            console.log(err)
        }).finally(() => {
        });
    });

    const SaveData = ((e, movenext) => {
        e.preventDefault();
        e.stopPropagation();
        {
            let obj = inputValue;
            setLoading(true);
            if (hasDupes(obj.TaxHead)) {
                window.alert("Duplicate Tax Found");
                setLoading(false);
                return;
            }
            if (obj.TaxHead.length) {
                fetchData(state.projectdetail.API + "/statetax/Save", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj)
                }).then(response => response.json()).then((res) => {
                    if (res.Succeeded) {                        
                        window.toast("Tax Categories saved sucessfully");
                        setValidated(false);
                        setrefresh(!refresh)
                    }
                    else {
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }).catch((err) => {
                    if (err && err.name === 'AbortError') return;
                    window.HandleError("StateTax.js ==> SaveData", null, null, err);
                    window.alert(state.projectdetail.ErrorMessage);
                }).finally(() => {
                    setLoading(false);
                });
            }
            else {
                window.alert("Nothing to Save");
            }
        }
    });

  
    const handleDropdownChange = (e) => {
        let value = e.target.value;

        if (value) {
            fetchData(state.projectdetail.API + "/StateTax/GetByState?Id=" + value, {
                method: "Get"
            }).then(response => response.json()).then((res) => {
                if (res) {
                    if (res.TaxHead.length == 0) {
                        res.TaxHead.push({ Id: 0, TaxName: "", fkStateId: value })
                    }
                    setInputValue(res);

                }
                else {
                    window.alert('Something went wrong', function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }

            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("StateTax.js ==> handleDropdownChange", null, null, err);
                console.log(err)
            }).finally(() => {
                //setLoading(false);
            });
            setData([]);
        }
    }


    const addTax = () => {
        var _input = JSON.parse(JSON.stringify(inputValue));
        _input.TaxHead.push({ Id: 0, TaxName: "", fkStateId: _input.Id })
        setInputValue(_input);
    }
    const deleteTax = (index) => {
        window.confirm("Do you want to delete?", function () {
            var _input = JSON.parse(JSON.stringify(inputValue));
            _input.TaxHead = _input.TaxHead.filter(function (row, indexLocal) {
                if (index != indexLocal && row.Id != 0) {
                    _input.DeletedTax.push(row.Id)
                }
                return index != indexLocal
            })
            setInputValue(_input);
        });
    }
    function hasDupes(array) {
        var hash = Object.create(null);
        return array.some(function (a) {
            return a.TaxName && (hash[a.TaxName] || !(hash[a.TaxName] = true));
        });
    }

    const onTaxChange = (e, row, index) => {
        let value = e.target.value;
        var _input = JSON.parse(JSON.stringify(inputValue));
        _input.TaxHead = _input.TaxHead.map(function (row, indexLocal) {
            if (index == indexLocal) {
                row.TaxName = value;
            }
            return row;
        })
        setInputValue(_input);
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Row className="mb-2">
                <Col md={12}>
                    <h4 className='form-heading mb-12'>
                        Tax Categories
                        <div className='d-inline-block float-end'>
                            <Form.Group>
                                <Form.Label>State<code className='required-symbol'>*</code></Form.Label>
                                <DynamicDropdownSearchable onChange={handleDropdownChange} from={state.projectdetail.API + "/State/GetAll?active=1"} label="StateName" value="Id" fetchType="get" name="fkRoleId" onDropdownChange={handleDropdownChange} defaultval={inputValue.fkStateId} />
                            </Form.Group>
                        </div>
                    </h4>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            {loading ?
                                <div className="text-center">
                                    <Spinner className='dropdown-loading' as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                                </div> :
                                <Row>
                                    <Col md={10}>
                                        <div className="mb-2">


                                            <Row>
                                                <Col className="text-center">
                                                    <h3>
                                                        {inputValue.StateName}
                                                    </h3>
                                                </Col>
                                            </Row>
                                            {

                                                inputValue.TaxHead.map(function (row, rowindex) {
                                                    return (<Row className="mb-2">
                                                        <Col>
                                                            <Form.Label className="col-auto">
                                                                Tax Name {rowindex + 1}
                                                            </Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Control required={true} type="text" name="MotherName" value={row.TaxName} onChange={(e) => {
                                                                    onTaxChange(e, row, rowindex)
                                                                }} autoComplete="off" />
                                                                <Form.Control.Feedback type="invalid">Please provide Tax Name</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="2">
                                                            <ButtonGroup aria-label="Basic example">
                                                                {<Button variant="link" onClick={function (e) { deleteTax(rowindex) }} type="button" ><Icon name="BsTrash" className='fs-5' /></Button>}
                                                                {rowindex == inputValue.TaxHead.length - 1 && <Button variant="link" type="button" title="Add new Tax" onClick={() => { addTax() }}><Icon name="BsPlusLg" className='fs-5' /></Button>}
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Form.Group>
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, false)} disabled={loading} className="float-end me-2">
                            Save
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </ErrorBoundary>
    );
}
export default (StateTax);
