import { useImperativeHandle } from "react";
import { forwardRef, memo, useEffect, useState } from "react";
import { Row, Col, Form, Spinner, Button } from "react-bootstrap";
import useFetchWithCancellation from "../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './ErrorHandler'

const MultiSelectMasterData = forwardRef(({ name, customData, dataURL, fetchType, labelField, valueField, addNewURL, addNewMethodType, formatAddParams, onCheckBoxChange }, ref) => {
    const { fetchData } = useFetchWithCancellation();
    useImperativeHandle(ref, () => ({
        getSelectedIds() {
            return selected;
        },
        getSelectedData() {
            return selectedList;
        },
        setSelected(dataToSelect) {
            setSelected(dataToSelect);
        }
    }));
    const [selected, setSelected] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [allList, setAllList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const sortJSON = (data, key) => {
        return data.sort((a, b) => {
            const labelA = a[key].toLowerCase();
            const labelB = b[key].toLowerCase();
            if (labelA < labelB)
                return -1
            if (labelA > labelB)
                return 1
            return 0;
        })
    }

    const [data, setData] = useState([]);
    useEffect(() => {
        try {
            setError(null)
            if (dataURL) {
                if (!labelField) {
                    labelField = "label";
                }
                if (!valueField) {
                    valueField = "value";
                }
                let obj = {};
                obj.method = fetchType;
                obj.credentials = "include";
                if (obj.method.toLowerCase() == "POST") {
                    obj.headers = {
                        "Content-Type": "application/json"
                    };
                }

                setLoading(true);
                fetchData(dataURL, obj).then(response => response.json()).then((res) => {
                    setError(null)
                    setData(res);
                }).finally(() => {
                    setLoading(false);
                });
            }
            else if (customData && customData.length > 0) {
                setData(customData);
            }
            else {
                setData([]);
            }
        }
        catch (error) {
            if (error && error.name === 'AbortError') return;
            window.HandleError("MultiSelectMasterData.js ==> fetch", null, null, error);
            setError(error);
        }
    }, []);
    useEffect(() => {
        setAllList(data);
    }, [data]);
    useEffect(() => {
        if (data && data.length > 0 && selected && selected.length > 0 && (selectedList.length == 0)) {
            setSelectedList(data.filter((drow) => {
                return selected.indexOf(drow[valueField]) > -1;
            }));
        }
    }, [data, selected]);

    const onCheckBoxValChange = (e) => {
        const value = e.target.checked;
        const currentId = parseInt(e.target.attributes['data-id'].value);
        const selectObj = allList.filter((row) => {
            return (row[valueField] == currentId)
        });
        if (value && selected.indexOf(currentId) == -1) {
            setSelected(c => ([...c, currentId]));
            if (selectObj && selectObj.length > 0 && selectObj[0]) {
                setSelectedList(c => ([...c, selectObj[0]]));
            }
        }
        else {
            setSelected(selected.filter((row) => {
                return row != currentId
            }));
            setSelectedList(selectedList.filter((row) => {
                return row[valueField] != currentId
            }));
        }
        if (typeof onCheckBoxChange == "function") {
            onCheckBoxChange();
        }
    }
    const onSearch = (e) => {
        let value = e.target.value;
        setSearchText(value);
    }
    const getSelectedList = () => {
        let sortedData = sortJSON(selectedList, labelField);
        if (searchText && searchText.trim()) {
            sortedData = sortedData.filter((row) => {
                return (row[labelField].toLowerCase().indexOf(searchText.trim().toLowerCase()) > -1)
            });
        }
        return sortedData;
    }
    const getAllList = () => {
        let sortedData = sortJSON(allList, labelField);
        if (searchText && searchText.trim()) {
            sortedData = sortedData.filter((row) => {
                return (row[labelField].toLowerCase().indexOf(searchText.trim().toLowerCase()) > -1)
            });
        }
        return sortedData;
    }

    const addToList = () => {
        if (addNewURL) {
            let obj = {};
            obj.method = addNewMethodType;
            obj.credentials = "include";
            let addBody = { [labelField]: searchText.trim(), [valueField]: 0 };
            if (typeof formatAddParams == "function") {
                addBody = formatAddParams(addBody);
            }
            obj.body = JSON.stringify(addBody);
            if (obj.method.toLowerCase() == "post") {
                obj.headers = {
                    "Content-Type": "application/json"
                };
            }
            setLoadingAdd(true);
            fetchData(addNewURL, obj).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    window.toast(res.Message);
                    setSelected(c => ([...c, res.Data.Id]));
                    setSelectedList(c => ([...c, res.Data]));
                    setData(c => ([...c, res.Data]));
                    setSearchText("");
                }
            }).finally(() => {
                setLoadingAdd(false);
            });
        }
    }


    if (error) {
        return <ErrorHandler error={error} />
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Row className="mb-2">
                <Col md={6}>
                    <Form.Control placeholder="Search" onChange={onSearch} value={searchText} />
                    {getSelectedList().length == 0 && getAllList().length == 0 && searchText.trim() &&
                        <div className="float-end mt-2">Not found,
                            <Button onClick={addToList} type="button" variant="link" className="btn btn-sm btn-link px-0 pt-0"><u>Add?</u></Button>
                            {loadingAdd && <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        </div>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {loading ? <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
                    {getSelectedList().map((row) => {
                        return (
                            <Form.Group key={row[valueField]}>
                                <Form.Check checked={true} onChange={onCheckBoxValChange} data-id={row[valueField]} name={name + row[valueField]} type="checkbox" id={name + row[valueField]} label={row[labelField]} />
                            </Form.Group>
                        )
                    })}
                    {getAllList().map((row) => {
                        if (selected.indexOf(parseFloat(row[valueField])) == -1)
                            return (
                                <Form.Group key={row[valueField]}>
                                    <Form.Check onChange={onCheckBoxValChange} data-id={row[valueField]} name={name + row[valueField]} type="checkbox" id={name + row[valueField]} label={row[labelField]} />
                                </Form.Group>
                            )
                        else
                            return <></>
                    })}
                </Col>
            </Row>
        </ErrorBoundary>
    )
});
export default (memo(MultiSelectMasterData));