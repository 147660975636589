import { memo } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
function ErrorHandler({ error }) {
    const state = useSelector(state => state);
    return (
        <Alert variant="light">
            <Alert.Heading>Something went wrong.</Alert.Heading>
            <hr />
            <p>
                {state.IsDevMode ? (error.message ? JSON.stringify(error.message) : "Please try after some time") : "Please try after some time"}
            </p>
        </Alert>
    )
}
export default memo(ErrorHandler);