import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import Icon from '../../controls/Icons/Icon';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate } from "react-router-dom";
import { getDateSaveFormat, getTimeSaveFormat } from "../../../Utils.js";
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';

function PCPMeetingForm({ _inputValue, afterSave }) {
    const { fetchData } = useFetchWithCancellation();
    const navigate = useNavigate();
    const { id } = useParams();
    const formRef = useRef();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const state = useSelector(state => state);

    const [inputValue, setInputValue] = useState({
        Id: 0,
        meetingdate: null,
        fromtime: null,
        totime: null,
        agenda: "",
        organizedby: [],
        icc: [],
        family: [],
    });


    const SaveData = ((e, movenext) => {
        e.preventDefault();
        e.stopPropagation();

        if (formRef.current.checkValidity() === false) {
            setValidated(true);

        }
        else {
            if (!inputValue.meetingdate) {
                setValidated(true);
                return false;
            }
            let obj = JSON.parse(JSON.stringify(inputValue));
            obj.meetingdate = getDateSaveFormat(inputValue.meetingdate);
            //obj.fromtime = Moment(obj.fromtime).toDate();
            //obj.totime = Moment(obj.totime).toDate();
            if (obj.totime < obj.fromtime) {
                setValidated(true);
                return false;
            }
            obj.fromtime = getTimeSaveFormat(inputValue.fromtime);
            obj.totime = getTimeSaveFormat(inputValue.totime);


            obj.fkClientId = id;
            setLoading(true);
            fetchData(state.projectdetail.API + "/pcp/SaveMeetings?clientId=" + id, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setInputValue({
                        Id: 0,
                        meetingdate: new Date(),
                        fromtime: null,
                        totime: null,
                        agenda: "",
                        organizedby: [],
                        icc: [],
                        family: [],
                    })
                    window.toast("Meetings saved sucessfully");
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPMeetingForm.js ==> SaveMeetings", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    });

    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const onTagsChange = (tags, value, c, name) => {
        setInputValue(c => ({ ...c, [name]: tags }));
    }
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);


    const handleDatePickerChange = ((value, name) => {
        setInputValue(c => ({ ...c, [name]: value }));
    })

    const handleTimePickerFromChange = ((value, name) => {
        setInputValue(c => ({ ...c, [name]: value }));
    })

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate validated={validated} ref={formRef} >
                <Row className="mb-6">
                    <Col md="12">
                        <Row className="mb-6">
                            <Col>
                                <h5 className="d-inline-block">Meetings</h5>
                            </Col>
                            <Col md="auto">

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-6">
                    <Col md="12">
                        <Row className="mb-3">
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Date<code className='required-symbol'>*</code></Form.Label>
                                    <DatePicker className="form-control" required
                                        dateFormat="MM-dd-yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        selected={inputValue.meetingdate}
                                        onChange={(val) => { handleDatePickerChange(val, "meetingdate") }}
                                        scrollableMonthYearDropdown
                                    />
                                    <Form.Control.Feedback type="invalid">Please provide Date</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>From Time<code className='required-symbol'>*</code></Form.Label>
                                    <DatePicker className="form-control" required
                                        selected={inputValue.fromtime}
                                        use12Hours={true}
                                        timeFormat="hh:mm a"
                                        showTimeSelectOnly={true} includeDates={false}
                                        showTimeInput={false}
                                        showTimeSelect={true}
                                        showTimeSelectOnly={true}
                                        dropdownMode={'select'}
                                        timeIntervals={15}
                                        dateFormat="hh:mm a"
                                        onChangeRaw={(e) => e.preventDefault()}
                                        onChange={(val) => { handleTimePickerFromChange(val, "fromtime") }}
                                    />
                                    <Form.Control.Feedback type="invalid">Please provide last name</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>To Time<code className='required-symbol'>*</code></Form.Label>
                                    <DatePicker className="form-control" required
                                        use12Hours={true}
                                        timeFormat="hh:mm a"
                                        showTimeSelectOnly={true} includeDates={false}
                                        selected={inputValue.totime}
                                        showTimeInput={false}
                                        showTimeSelect={true}
                                        showTimeSelectOnly={true}
                                        dropdownMode={'select'}
                                        timeIntervals={15}
                                        dateFormat="hh:mm a"
                                        onChangeRaw={(e) => e.preventDefault()}
                                        onChange={(val) => { handleTimePickerFromChange(val, "totime") }}
                                    />
                                    <Form.Control.Feedback type="invalid">Please provide To Time</Form.Control.Feedback>
                                    {validated && inputValue.fromtime && inputValue.totime && inputValue.fromtime > inputValue.totime && <span type="invalid" className="text-danger">To time should be greater than from time</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>Agenda<code className='required-symbol'>*</code></Form.Label>
                                    <Form.Control as="textarea" row="8" required type="text" name="agenda" value={inputValue.agenda} onChange={handleChange} autoComplete="off" />
                                    <Form.Control.Feedback type="invalid">Please provide Agenda</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <h5 >Attendees</h5>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Organized By<code className='required-symbol'>*</code></Form.Label>
                                    <Form.Control required name="organizedby" as="textarea" value={inputValue.organizedby} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">Please provide Organized By</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>ICC<code className='required-symbol'>*</code></Form.Label>
                                    <Form.Control required name="icc" as="textarea" value={inputValue.icc} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">Please provide ICC</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Family<code className='required-symbol'>*</code></Form.Label>
                                    <Form.Control required name="family" as="textarea" value={inputValue.family} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">Please provide Family</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2" >
                            <Col md={12} className="text-center">
                                <Form.Group>
                                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={loading} className="float-end">
                                        Save
                                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Form>
        </ErrorBoundary>
    )
}
export default (PCPMeetingForm)