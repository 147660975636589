import { Form, Spinner } from "react-bootstrap";
import { useState, useEffect, memo } from "react";
import ErrorHandler from './ErrorHandler'
import useFetchWithCancellation from "../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
const DynamicDropdownSearchable = ({ customData, from, label, value, fetchType, name, onDropdownChange, defaultval, required, addAttr, refresh, disabled, parseLabel, afterFetch }) => {
    const { fetchData } = useFetchWithCancellation();
    const [data, setData] = useState([]);
    const [refreshDropdown, setRefreshDropdown] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    var opts = {};
    if (required) {
        opts['required'] = 'required';
    }
    useEffect(() => {
        try {
            setError(null);
            if (from) {
                if (!label) {
                    label = "label";
                }
                if (!value) {
                    value = "value";
                }
                let obj = {};
                obj.method = fetchType;
                obj.credentials = "include";
                if (obj.method.toLowerCase() == "post") {
                    obj.headers = {
                        "Content-Type": "application/json"
                    };
                }

                setLoading(true);
                fetchData(from, obj).then(response => response.json()).then((res) => {
                    setError(null);
                    setData(res);
                    if (typeof afterFetch == "function")
                        afterFetch(res);
                }).finally(() => {
                    setLoading(false);
                });
            }
            else if (customData && customData.length > 0) {
                setData(customData);
            }
            else {
                setData([]);
            }
        }
        catch (error) {
            if (error && error.name === 'AbortError') return;
            window.HandleError("DynamicDropdownSearchable.js ==> fetch", null, null, error);
            setError(error);
        }
    }, [refreshDropdown, refresh]);

    const getValue = (obj, key) => {
        if (typeof parseLabel == "function") {
            return parseLabel(obj, key, label, value);
        }
        if (key.indexOf(".") > 0) {
            let keys = key.split(".");
            let nextKeys = JSON.parse(JSON.stringify(keys));
            nextKeys.shift();
            return getValue(obj[keys[0]], nextKeys.join("."));
        }
        else {
            if (obj.hasOwnProperty(key)) {
                return obj[key];
            }
            return "";
        }
    }
    const getExactVaue = (row, key) => {
        if (key.indexOf(".") > -1) {
            let keys = key.split(".");
            let firstKey = keys[0];
            keys.shift();
            return getExactVaue(row[firstKey], keys.join("."));
        }
        else {
            return row[key];
        }
    }
    if (error) {
        return <ErrorHandler error={error} />
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <div className="position-relative">
                <Form.Select disabled={(disabled || loading)} aria-label="Default select example" name={name} {...opts} onChange={onDropdownChange} value={defaultval}>
                    <option value="">--Select--</option>
                    {data.map((row) => {
                        if (addAttr) {
                            let attr = {};
                            addAttr.forEach(function (attrKey) {
                                if (attrKey.indexOf(".") > -1) {
                                    attr["data-" + attrKey.toLowerCase().replace(".", "-")] = getExactVaue(row, attrKey);
                                }
                                else {
                                    attr["data-" + attrKey.toLowerCase()] = row[attrKey];
                                }
                            });
                            return <option {...attr} value={getValue(row, value)} key={getValue(row, value)}>{getValue(row, label)}</option>
                        }
                        else {
                            return <option value={getValue(row, value)} key={getValue(row, value)}>{getValue(row, label)}</option>
                        }

                    })}
                </Form.Select>
                {loading ? <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
            </div>
        </ErrorBoundary>
    );
}

export default (memo(DynamicDropdownSearchable))