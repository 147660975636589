import React from 'react';
import { memo } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import './PageNotFound.css';
import ErrorHandler from '../../controls/ErrorHandler';
const PageNotFound = () => {
    const navigate = useNavigate();
    const login = (() => {
        navigate("/");
    });
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>            
            <Container className='home mt-3'>
                <Row className='d-flex min-vh-100 align-items-center'>
                    <Col md={12} className="text-center">
                        <Card>
                            <Card.Body> 
                                <div className="mb-3">
                                    <img src="./logo.png" className="img-fluid home-img" />
                                </div>
                                <h1 className='mb-3 color-blue'>404</h1>
                                <h4 className='mb-3 text-uppercase color-blue'>SORRY! WE COULD NOT FIND WHAT YOU ARE LOOKING FOR.</h4>
                                <div className='mb-4'>
                                    <Button onClick={login}>Go back to Home</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </ErrorBoundary>
    );
}

export default (memo(PageNotFound));
