import { memo } from 'react';
import { Alert } from 'react-bootstrap';
function RouteErrorElement({ name, ...otherProps }) {
    return (
        <Alert variant="danger">
            <Alert.Heading>Something went wrong.</Alert.Heading>
            <hr />
            <p>
                {name}
            </p>
        </Alert>
    )
}
export default memo(RouteErrorElement);