import { useEffect, useCallback, useRef, useState, memo } from "react";
import {
    useBeforeUnload,
    unstable_useBlocker as useBlocker,
    useNavigate,
} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';



const FormPrompt = ({ hasUnsavedChanges }) => {
    const navigate = useNavigate();
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    useEffect(() => {
        setConfirmedNavigation(false);
    }, [hasUnsavedChanges])
    const onLocationChange = useCallback(
        ({ nextLocation }) => {
            if (hasUnsavedChanges && !confirmedNavigation) {
                setConfirmedNavigation(true);
                window.confirm("You have unsaved changes, are you sure you want to leave?", function () {                                        
                    navigate(nextLocation.pathname);
                }, function () { 
                    setConfirmedNavigation(false);
                });
                return true;
            }
            else {
                return false;
            }
        },
        [hasUnsavedChanges, confirmedNavigation]
    );

    usePrompt(onLocationChange, hasUnsavedChanges);
    useBeforeUnload(
        useCallback(
            (event) => {
                if (hasUnsavedChanges) {
                    event.preventDefault();
                    event.returnValue = "";
                }
            },
            [hasUnsavedChanges]
        ),
        { capture: true }
    );

    return null;
};


function usePrompt(onLocationChange, hasUnsavedChanges) {
    const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
    const prevState = useRef(blocker.state);

    useEffect(() => {
        if (blocker.state === "blocked") {
            blocker.reset();
        }
        prevState.current = blocker.state;
    }, [blocker]);
}
export default (memo(FormPrompt));