import { useState } from "react"
import { Card, Button, Modal, CloseButton, Spinner } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import UserForm from './UserForm';
import Icon from "../../controls/Icons/Icon";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";

const User = () => {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState({
    });
    const columns = [
        {
            field: "name",
            title: "Username",
            sortName: "firstName",
            sortable: true,
        },
        {
            field: "mobile",
            title: "Mobile Number",
            sortable: true,
        },
        {
            field: "email",
            title: "Email Address",
            sortable: true,
        },
        {
            field: "active",
            title: "Status",
            sortable: true,
            formatter: function (value) {
                if (value)
                    return "Active";
                else
                    return "Inactive";
            },
        },
        {
            dataField: "remove",
            title: "",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleEdit(row)}
                    ><Icon name="BsPencilFill" className='fs-5' /></Button> : ""}

                    {state.access.delete ? <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <Icon name="BsTrash" className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        }
    ];

    const handleEdit = (res) => {
        setInputValue(res)
        setShowModal(true)
    };

    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the user?", function () {
            setLoading(true);
            fetchData(state.projectdetail.API + "/user/delete?Id=" + res.Id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    window.alert("Deleted successfuly");
                    setRefreshGrid(!refreshGrid);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("User.js ==> handleDelete", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        })

    });

    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "firstName",
        sortOrder: "asc",
    };
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleAdd = () => {
        setInputValue([])
        setShowModal(true)
    };
    const handleAfter = (res) => {
        if (res.Succeeded) {
            window.alert("User saved successfully");
            handleClose();
            setRefreshGrid(!refreshGrid);
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <h4 className='form-heading mb-4'>
                User
                <div className='d-inline-block float-end'>
                    {state.access.add && <Button onClick={handleAdd}>Add New User</Button>}&nbsp;
                </div>
            </h4>
            <Card>
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/user/grid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    <h4>{inputValue.Id ? "Edit " : "Add "} User</h4>
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <UserForm afterSave={handleAfter} _inputValue={inputValue} showPassword={true} onCancel={handleClose} />
                </Modal.Body>
            </Modal>
        </ErrorBoundary>
    );
}
export default (User);
