import { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button, Spinner, Card, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { anyToFloat, fnGetFileNameFromContentDispostionHeader } from "../../../Utils";
import DatePicker from "react-datepicker";
import DynamicDropdownSearchable from "../../controls/DynamicDropdownSearchable";
import Icon from "../../controls/Icons/Icon";
import CurrencyInputText from "../../controls/FormControls/CurrencyInput";
import CurrencyLabel from "../../controls/CurrencyLabel";
import ErrorHandler from "../../controls/ErrorHandler";
import { SPMetadataAction } from "../../../Reducer/SPMetadataReducer";
import SPPlan from "./SPPlan";
import "./SpendingPlanGenerator.css";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
function SpendingPlanGenerator() {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);

    const { id } = useParams();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [validated, setValidated] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [addValidated, setAddValidated] = useState(false);
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(null);
    const [pdfAvailable, setPdfAvailable] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const setEmployeeBurdenTax = (val) => {
        if (val) {
            if (typeof val == "string") {
                val = parseFloat(val);
            }
            val = val.toFixed(2);
        }
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { employeeBurdenTax: val } })
    }
    const setServices = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { services: val } })
    }
    const setServiceError = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { serviceError: val } })
    }
    const setServiceLoading = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { serviceLoading: val } })
    }

    const setProviders = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { providers: val } })
    }
    const setProviderError = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { providerError: val } })
    }
    const setProviderLoading = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { providerLoading: val } })
    }

    const setUnits = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { units: val } })
    }
    const setUnitError = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { unitError: val } })
    }
    const setUnitLoading = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { unitLoading: val } })
    }

    const setUnitPers = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { unitPers: val } })
    }
    const setUnitPerError = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { unitPerError: val } })
    }
    const setUnitPerLoading = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { unitPerLoading: val } })
    }


    const setStateTaxs = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { stateTaxs: val } })
    }
    const setStateTaxError = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { stateTaxError: val } })
    }
    const setStateTaxLoading = (val) => {
        dispatch({ type: SPMetadataAction.setSPMetadata, payload: { stateTaxLoading: val } })
    }


    const dataDefault = {
        fkClientId: id,
        fkFMSId: null,
        fkSelfDeterminationId: 1,
        BudgetApproved: null,
        BudgetBegins: null,
        BudgetEnds: null,
        PlannedBudget: 0,
        SpendingPlanDetails: [],
        DeletedSpendingPlanDetails: [],
        Id: 0,
        fms: {},
    };
    const [data, setData] = useState(JSON.parse(JSON.stringify(dataDefault)));
    const defaultDetailValue = {
        key: "",
        fkSpendingPlanId: 0,
        fkServiceId: 0,
        fkProviderId: 0,
        Name: "",
        For: null,
        fkUnitId: 2,
        fkUnitPerId: 1,
        Per: null,
        UnitForMultiply: null,
        BaseRate: 0,
        PreTaxAmount: null,
        EmployeeBurdenPer: null,
        EmployeeBurdenAmount: null,
        TaxPer: null,
        TaxAmount: null,
        ShippingAmount: null,
        Total: null,
        service: {},
        providerExpense: {},
    };
    const [deletedPlans, setDeletedPlans] = useState([]);
    const [addValue, setAddValue] = useState(JSON.parse(JSON.stringify(defaultDetailValue)));

    useEffect(() => {
        setServiceLoading(true);
        fetchData(state.projectdetail.API + "/spcategorydetail/GetAll", {
            method: "Get"
        }).then(response => response.json()).then((res) => {
            if (Array.isArray(res)) {
                setServices(res);
            }
            else {
                setServiceError("Failed to Load");
            }
        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("SpendingPlanGenerator.js ==> spcategorydetail-GetAll", null, null, err);
            setServiceError(err);
        }).finally(() => {
            setServiceLoading(false);
        });

        setProviderLoading(true);
        fetchData(state.projectdetail.API + "/ProviderExpense/GetAll", {
            method: "Get"
        }).then(response => response.json()).then((res) => {
            if (Array.isArray(res)) {
                setProviders(res);
            }
            else {
                setProviderError("Failed to Load");
            }
        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("SpendingPlanGenerator.js ==> ProviderExpense-GetAll", null, null, err);
            setProviderError(err);
        }).finally(() => {
            setProviderLoading(false);
        });

        setUnitLoading(true);
        fetchData(state.projectdetail.API + "/ServiceUnit/GetAll", {
            method: "Get"
        }).then(response => response.json()).then((res) => {
            if (Array.isArray(res)) {
                setUnits(res);
            }
            else {
                setUnitError("Failed to Load");
            }
        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("SpendingPlanGenerator.js ==> ServiceUnit-GetAll", null, null, err);
            setUnitError(err);
        }).finally(() => {
            setUnitLoading(false);
        });

        setUnitPerLoading(true);
        fetchData(state.projectdetail.API + "/ServiceUnitPer/GetAll", {
            method: "Get"
        }).then(response => response.json()).then((res) => {
            if (Array.isArray(res)) {
                setUnitPers(res);
            }
            else {
                setUnitPerError("Failed to Load");
            }
        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("SpendingPlanGenerator.js ==> ServiceUnitPer-GetAll", null, null, err);
            setUnitPerError(err);
        }).finally(() => {
            setUnitPerLoading(false);
        });

        //setPdfLoading(true);
        //fetchData(state.projectdetail.API + "/SpendingPlan/CheckSP?clientId=" + id).then(res => res.json()).then((res) => {
        //    setPdfAvailable(res.Succeeded);
        //}).finally(() => {
        //    setPdfLoading(false);
        //});
    }, []);
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/SpendingPlan/Get?clientId=" + id, {
            method: "Get"
        }).then(response => response.json()).then((res) => {
            if (Array.isArray(res) && res.length > 0) {
                setPdfAvailable(true);
                setError(null)
                if (res[0].BudgetBegins)
                    res[0].BudgetBegins = new Date(res[0].BudgetBegins);
                if (res[0].BudgetEnds)
                    res[0].BudgetEnds = new Date(res[0].BudgetEnds);
                if (res[0].SpendingPlanDetails) {
                    let abcddata = JSON.parse(JSON.stringify(categories));
                    res[0].SpendingPlanDetails.forEach((sprow, spindex) => {
                        let cat = abcddata.find((crow) => {
                            return crow.Id == sprow.service.fkSPCategoryId;
                        });
                        if (cat) {
                            cat.SpendingPlanDetails.push(sprow);
                        }
                        else {
                            abcddata.push({ ...sprow.service.spCategory, SpendingPlanDetails: [sprow] });
                        }
                    });
                    abcddata = calCaegoryTotal(abcddata);
                    setCategories(abcddata);
                }
                setData(res[0]);
            }
        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            window.HandleError("SpendingPlanGenerator.js ==> SpendingPlan-Get", null, null, err);
            setError(err);
        }).finally(() => {
            setLoading(false);
        });
    }, [refresh])
    useEffect(() => {
        if (data.fkFMSId) {
            setStateTaxLoading(true);
            fetchData(state.projectdetail.API + "/FMSStateTax/GetFMSStateTaxes?FmsId=" + data.fkFMSId)
                .then(response => response.json()).then((res) => {
                    if (Array.isArray(res) && res.length > 0) {
                        let ttax = 0;
                        res.forEach((row) => {
                            ttax += row.Tax;
                        });
                        setEmployeeBurdenTax(ttax);
                        setStateTaxs(res);
                    }
                    else {
                        setStateTaxs([]);
                        setEmployeeBurdenTax(0);
                    }
                }).catch((err) => {
                    if (err && err.name === 'AbortError') return;
                    window.HandleError("SpendingPlanGenerator.js ==> FMSStateTax-GetFMSStateTaxes", null, null, err);
                    setStateTaxError(err);
                }).finally(() => {
                    setStateTaxLoading(false);
                });
        }
        else {
            setStateTaxs([]);
            setEmployeeBurdenTax(0);
        }
    }, [data.fkFMSId])
    useEffect(() => {
        let Variance = anyToFloat(anyToFloat(data.BudgetApproved) - anyToFloat(data.PlannedBudget)).toFixed(2);
        setData(d => ({ ...d, Variance: Variance }));
    }, [data.BudgetApproved, data.PlannedBudget]);

    const CurrencyChange = (value, name) => {
        if (!typeof value || value == undefined) {
            value = 0;
        }
        setData(c => ({ ...c, [name]: value }));
    }

    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }

        setData(c => ({ ...c, [name]: value }));
    });
    const handleDatePickerChange = ((value, name) => {
        let dataObj = { ...data };
        dataObj[name] = value;
        if (name == "BudgetBegins" && !dataObj.BudgetEnds && value) {
            let NewDate = new Date(value);
            NewDate.setFullYear(NewDate.getFullYear() + 1);
            NewDate.setDate(NewDate.getDate() - 1);
            dataObj["BudgetEnds"] = NewDate;
        }
        setData(c => ({ ...c, ...dataObj }));
    })
    const onFMSChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let fkstateid = 0;
        let text = "";
        if (value && e.target.selectedOptions.length > 0) {
            fkstateid = e.target.selectedOptions[0].attributes['data-fkstateid'].value;
            text = e.target.selectedOptions[0].text;
        }
        let objFMS = {
            [name]: value,
            fms: { Id: value, fkStateId: fkstateid, FMSName: text }
        };

        setData(c => ({ ...c, ...objFMS }));
    }

    const onAddChangeService = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let fkSPCategoryId = 0;
        let CategoryName = "";
        let text = "";
        if (value && e.target.selectedOptions.length > 0) {
            fkSPCategoryId = e.target.selectedOptions[0].attributes['data-fkspcategoryid'].value;
            CategoryName = e.target.selectedOptions[0].attributes['data-spcategory-categoryname'].value;
            text = e.target.selectedOptions[0].text;
        }
        let objService = {
            [name]: value,
            service: {
                Id: value, ServiceName: text, serviceCode: text, fkSPCategoryId, spCategory: { Id: fkSPCategoryId, CategoryName }
            }
        };
        setAddValue(c => ({ ...c, ...objService }));
    }


    const onProviderChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let IncludeStateTax = false;
        let IncludeSeparateTax = false;
        let IncludeShipingCharge = false;
        let text = "";
        if (value && e.target.selectedOptions.length > 0) {
            IncludeStateTax = e.target.selectedOptions[0].attributes['data-includestatetax'].value == "true";
            IncludeSeparateTax = e.target.selectedOptions[0].attributes['data-includeseparatetax'].value == "true";
            IncludeShipingCharge = e.target.selectedOptions[0].attributes['data-includeshipingcharge'].value == "true";
            text = e.target.selectedOptions[0].text;
        }
        let objProvider = {
            [name]: value,
            providerExpense: { Id: value, ProviderName: text, IncludeStateTax, IncludeSeparateTax, IncludeShipingCharge }
        };
        setAddValue(c => ({ ...c, ...objProvider }));
    }
    const addPlan = (e) => {
        if (!addValue.fkProviderId || !addValue.fkServiceId) {
            setAddValidated(true);
            return false;
        }
        setAddValidated(false);
        let dd = { ...data };
        if (dd.BudgetBegins) {
            dd.BudgetBegins = new Date(dd.BudgetBegins);
        }
        if (dd.BudgetEnds) {
            dd.BudgetEnds = new Date(dd.BudgetEnds);
        }
        let abcddata = JSON.parse(JSON.stringify(categories));
        var cat = abcddata.find((crow) => {
            return crow.Id == addValue.service.fkSPCategoryId;
        });
        if (!cat) {
            abcddata.push({ ...addValue.service.spCategory, SpendingPlanDetails: [addValue] });
        }
        else {
            cat.SpendingPlanDetails.push(addValue);
        }
        abcddata = calCaegoryTotal(abcddata);
        setCategories(abcddata);
        dd.SpendingPlanDetails.push(addValue);
        setData(dd);
        let detailVall = JSON.parse(JSON.stringify(defaultDetailValue));
        detailVall.key = new Date().getTime();
        setAddValue(detailVall);
    }

    const onPlanChange = (row, index, category, categoryIndex) => {
        let cdata = JSON.parse(JSON.stringify(categories));
        cdata[categoryIndex].SpendingPlanDetails[index] = row;
        cdata[categoryIndex].Total = 0;
        cdata[categoryIndex].SpendingPlanDetails.forEach((spd) => {
            cdata[categoryIndex].Total += anyToFloat(spd.Total);
        })
        cdata = calCaegoryTotal(cdata);
        setCategories(cdata);
    }
    const onPlanDelete = (row, index, category, categoryIndex) => {
        let cdata = JSON.parse(JSON.stringify(categories));
        if (row.Id) {
            setDeletedPlans(c => ([...c, row.Id]));
        }
        cdata[categoryIndex].SpendingPlanDetails = cdata[categoryIndex].SpendingPlanDetails.filter((sprow, spindex) => {
            return spindex != index;
        });
        cdata = calCaegoryTotal(cdata);
        setCategories(cdata);
    }
    const SaveData = (e, downlod) => {
        e.preventDefault();
        e.stopPropagation();
        if (formRef.current.checkValidity() === false) {
            setValidated(true);
        }
        else {
            if (!data.fkFMSId || !data.fkSelfDeterminationId) {
                setValidated(true);
            }
            let obj = { ...data };
            if (obj.BudgetBegins) {
                obj.BudgetBegins = new Date(obj.BudgetBegins);
            }
            if (obj.BudgetEnds) {
                obj.BudgetEnds = new Date(obj.BudgetEnds);
            }
            if (obj.BudgetEnds < obj.BudgetBegins) {
                setValidated(true);
                return false;
            }
            const callSave = () => {
                obj.SpendingPlanDetails = [];
                categories.forEach((crow) => {
                    obj.SpendingPlanDetails = obj.SpendingPlanDetails.concat(crow.SpendingPlanDetails);
                });
                obj.DeletedSpendingPlanDetails = deletedPlans;

                setSaveLoading(true);
                fetchData(state.projectdetail.API + "/SpendingPlan/save", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj)
                }).then(response => response.json()).then((res) => {
                    if (res.Succeeded) {
                        //if (downlod) {
                        //    fetchData(state.projectdetail.API + "/SpendingPlan/GeneratePDF?clientId=" + id).then(res => res.json()).then((res) => {
                        //        if (res.Succeeded)
                        //            setPdfAvailable(true);
                        //    }).catch((er) => {
                        //        if (er && er.name === 'AbortError') return;
                        //        window.HandleError("SpendingPlanGenerator.js ==> SpendingPlan-GeneratePDF", null, null, er);
                        //        window.alert("Failed to download")
                        //    }).finally(() => {
                        //        setSaveLoading(false);
                        //    });
                        //}
                        //else {
                        //    setSaveLoading(false);
                        //}
                        setSaveLoading(false);
                        setCategories([]);
                        setData(JSON.parse(JSON.stringify(dataDefault)));
                        setValidated(false);
                        setAddValidated(false);
                        window.toast(res.Message);
                        setRefresh(c => !c);
                    }
                    else {
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }).catch((err) => {
                    if (err && err.name === 'AbortError') return;
                    window.HandleError("SpendingPlanGenerator.js ==> SpendingPlan-save", null, null, err);
                    window.alert(state.projectdetail.ErrorMessage);
                }).finally(() => {

                });
            }
            if (obj.Variance < 0) {
                window.confirm("Variance is negative, Do you wish to proceed? ", function () {
                    callSave()
                });
            }
            else {
                callSave();
            }


        }
    }
    const calCaegoryTotal = (cdata) => {
        let dataTotal = 0;
        cdata.forEach((category, categoryIndex) => {
            cdata[categoryIndex].Total = 0;
            cdata[categoryIndex].SpendingPlanDetails.forEach((spd) => {
                if (spd.Total)
                    cdata[categoryIndex].Total += anyToFloat(spd.Total);
            });
            cdata[categoryIndex].Total = anyToFloat(cdata[categoryIndex].Total).toFixed(2);
            dataTotal += anyToFloat(cdata[categoryIndex].Total);
        });
        let Variance = anyToFloat(anyToFloat(data.BudgetApproved) - anyToFloat(dataTotal)).toFixed(2);
        setData(d => ({ ...d, ["PlannedBudget"]: dataTotal.toFixed(2), Variance: Variance }));
        return cdata;
    }
    const DowanloadSpendingPlan = () => {
        setDownloading(true);
        fetchData(state.projectdetail.API + "/SpendingPlan/LoadSP?clientId=" + id).then(async (res, a) => ({
            filename: fnGetFileNameFromContentDispostionHeader(res.headers.get('content-disposition')),
            blob: await res.blob()
        })).then((resObj) => {

            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        }).catch((er) => {
            if (er && er.name === 'AbortError') return;
            window.HandleError("SpendingPlanGenerator.js ==> SpendingPlan-LoadSP", null, null, er);
            window.alert("Failed to download")
        }).finally(() => {
            setDownloading(false);
        });
    }
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate validated={validated} ref={formRef}>
            <Row className="mb-2">
                <Col>
                    <h5 className="d-inline-block">Spending Plan Generator</h5>
                </Col>
                <Col xs="auto">
                    <Form.Group>
                        <DynamicDropdownSearchable name="fkSelfDeterminationId" defaultval={data.fkSelfDeterminationId}
                            required={true} from={state.projectdetail.API + "/SelfDetermination/GetAll"}
                            label="SelfDeterminationName" value="Id" fetchType="get" onDropdownChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {error ? <ErrorHandler error={error} /> :
                loading ? <div className="text-center"><Spinner className='dropdown-loading' as="span" animation="border" size="lg" role="status" aria-hidden="true" /></div> :
                    <div>
                        <Row className="mb-2">
                            <Col>
                                <Card className="border ">
                                    <Card.Body>
                                        <Row>
                                            <Col md={3}>
                                                <Form.Label>Select FMS<code className='required-symbol'>*</code></Form.Label>
                                                <DynamicDropdownSearchable className="form-control"
                                                    name="fkFMSId"
                                                    defaultval={data.fkFMSId}
                                                    addAttr={["fkStateId"]}
                                                    onDropdownChange={onFMSChange}
                                                    required={true} from={state.projectdetail.API + "/FMS/GetAll"}
                                                    label="FMSName" value="Id" fetchType="get"
                                                />
                                                <Form.Text className="text-muted">
                                                    Employee Burden {state.spmetadata.employeeBurdenTax}%
                                                </Form.Text>
                                                {validated && !data.fkFMSId && <div><small className="text-danger">Please select FMS</small></div>}
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Select Duration Begins<code className='required-symbol'>*</code></Form.Label>
                                                    <DatePicker required={true} className="form-control" selected={data.BudgetBegins} onChange={(val) => (handleDatePickerChange(val, "BudgetBegins"))}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dateFormat="MM-dd-yyyy"
                                                    />
                                                    {validated && !data.BudgetBegins && <div><small className="text-danger">Please select duration begins</small></div>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Select Duration Ends<code className='required-symbol'>*</code></Form.Label>
                                                    <DatePicker required={true} className="form-control" selected={data.BudgetEnds} onChange={(val) => (handleDatePickerChange(val, "BudgetEnds"))}
                                                        dateFormat="MM-dd-yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                    />
                                                    {validated && data.BudgetEnds && data.BudgetBegins && data.BudgetEnds < data.BudgetBegins && <div><small className="text-danger"></small> Duration ends should be greater than duration begins</div>}
                                                    {validated && !data.BudgetEnds && <div><small className="text-danger">Please select duration ends</small></div>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>

                                                    {/*<CurrencyInputText name="budge" required={true} value={data.BudgetApproved}*/}
                                                    {/*    name="BudgetApproved" onChange={handleChange}*/}
                                                    {/*    errorMessage="Pleae enter approved budget" />*/}

                                                    <Form.Label>Enter Approved Budget<code className='required-symbol'>*</code></Form.Label>
                                                    <CurrencyInputText
                                                        name="BudgetApproved"
                                                        required={true}
                                                        errorMessage="Pleae enter approved budget"
                                                        onChange={CurrencyChange}
                                                        value={data.BudgetApproved}
                                                        containerClassName=""
                                                        className="sp-curreny-input form-control"
                                                        decimalsLimit={0}
                                                        defaultValue={0}
                                                        id="BudgetApproved"
                                                        required
                                                    />

                                                    {validated && !data.BudgetApproved && <div><small className="text-danger">Please enter approved budget</small></div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                {categories && categories.map((crow, cindex) => {
                                    return (<Card className="withborder mb-2" key={crow.Id}>
                                        <Card.Header>
                                            <h5 className="d-inline-block">{(cindex + 1) + ". " + crow.CategoryName}</h5>
                                            <h6 className="d-inline-block float-end">Category Total <Badge bg={"info"}><CurrencyLabel value={crow.Total} /></Badge></h6>
                                        </Card.Header>
                                        <Card.Body>
                                            {crow.SpendingPlanDetails && crow.SpendingPlanDetails.map((spdrow, spdIndex) => {
                                                return <SPPlan
                                                    onDelete={onPlanDelete}
                                                    onChange={onPlanChange}
                                                    key={spdrow.Id + "_" + (spdrow.key ?? "")}
                                                    category={crow}
                                                    categoryIndex={cindex}
                                                    row={spdrow}
                                                    index={spdIndex}
                                                />
                                            })
                                            }
                                        </Card.Body>
                                    </Card>)
                                })}
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col md={8}>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Service<code className='required-symbol'>*</code></Form.Label>
                                            {!state.spmetadata.serviceError ? state.spmetadata.serviceLoading ? <div><Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /></div> :
                                                <Form.Select value={addValue.fkServiceId} name="fkServiceId" onChange={onAddChangeService}>
                                                    <option value="">--Select--</option>
                                                    {state.spmetadata.services.map((service) => {
                                                        return (<option key={service.Id}
                                                            data-fkspcategoryid={service.fkSPCategoryId}
                                                            data-spcategory-categoryname={service.spCategory.CategoryName}
                                                            value={service.Id}>{service.ServiceDisplayName}</option>)
                                                    })}
                                                </Form.Select>
                                                : <ErrorHandler error={state.spmetadata.serviceError} />
                                            }

                                            {addValidated && !addValue.fkServiceId && <div><small className="text-danger">Please select servie</small></div>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Provider Expense<code className='required-symbol'>*</code></Form.Label>
                                            {!state.spmetadata.providerError ? state.spmetadata.providerLoading ? <div><Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /></div> :
                                                <Form.Select name="fkProviderId" value={addValue.fkProviderId} onChange={onProviderChange}>
                                                    <option value="">--Select--</option>
                                                    {state.spmetadata.providers.map((provider) => {
                                                        return (<option key={provider.Id}
                                                            data-includestatetax={provider.IncludeStateTax}
                                                            data-includeseparatetax={provider.IncludeSeparateTax}
                                                            data-includeshipingcharge={provider.IncludeShipingCharge}
                                                            value={provider.Id}>{provider.ProviderName}</option>)
                                                    })}
                                                </Form.Select>
                                                : <ErrorHandler error={state.spmetadata.providerError} />
                                            }
                                            {addValidated && !addValue.fkProviderId && <div><small className="text-danger">Please select provider expense</small></div>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Button onClick={addPlan}>Add</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="mb-5 row"></div>
                        <div className="mb-3 row"></div>
                        <Row className="bg-light align-items-center p-3 border sp-floating">
                            <Col xs={6}>
                                <h5><span>Overall planned Budget </span><Badge bg="success" ><CurrencyLabel value={data.PlannedBudget} /></Badge></h5>
                            </Col>
                            <Col xs={3}>
                                <h5>
                                    <span>Variance </span>

                                    <Badge bg={data.Variance < 0 ? "danger" : "info"}><CurrencyLabel value={data.Variance} /></Badge></h5>
                            </Col>
                            <Col xs={12} className="text-center">

                                <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={saveLoading} className="me-2">
                                    <Icon name="BiSave" /> Save
                                    {saveLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                </Button>
                                {pdfAvailable && <Button variant="primary" type="button" onClick={DowanloadSpendingPlan} disabled={downloading} className="me-2">
                                    <Icon name="BsDownload" /> Download Spending Plan
                                    {downloading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                </Button>}
                            </Col>
                        </Row>
                    </div>
            }
        </Form>
    </ErrorBoundary>
    )
}
export default (SpendingPlanGenerator);