import React, { useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';

const ResetPasswordForm = ({ id, heading, afterReset, showCancel, onCancel }) => {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [inputValue, setInputValue] = useState({
        Id: id,
        currentPassword: "",
        password: "",
        confirmpassword: "",
    });
    useEffect(() => {
        setInputValue({
            Id: id,
            currentPassword: "",
            password: "",
            confirmpassword: "",
        })
    }, []);
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const logout = () => {
        fetchData(state.projectdetail.API + "/auth/logout", { method: "POST" }).then(() => {
            localStorage.removeItem("user");
            navigate("/");
        });
    };
    const UpdatePassword = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false || !pwdmatching || pwdweek) {
            setValidated(true);
            return;
        }
        setErrorMessage("");
        setShowErrorMessage(false);
        let data = JSON.parse(JSON.stringify(inputValue));
        data.Id = id;
        setLoading(true);
        fetchData(state.projectdetail.API + "/user/changePassword", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => res.json()).then((res) => {
            setLoading(false);
            if (res.Succeeded) {
                alert("Password changed Sucessfully", function () {
                    logout();
                })
            }
            else {
                setErrorMessage(res.Message);
                setShowErrorMessage(true);
            }

        }).catch((err) => {
            if (err && err.name === 'AbortError') return;
            //window.HandleError("ResetPasswordForm.js ==> changePassword", null, null, err);
            setLoading(false);
            setErrorMessage(state.projectdetail.errorMessage);
            setShowErrorMessage(true);
        });
    })
    const onCurrentPwdKeyUp = () => {
        checkOldPw()
    }
    const onPwdKeyUp = () => {
        checkPasswords();
        checkWeekPassword();
    }
    const [pwdmatching, setPwdMatching] = useState(true);
    const checkPasswords = () => {
        setPwdMatching(inputValue.password == inputValue.confirmpassword);
    }
    const [pwdweek, setPwdweek] = useState(false);
    const checkWeekPassword = () => {
        let reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/
        let status = !reg.test(inputValue.password);
        setPwdweek(status);
    }
    const [checkOldPwwee, setcheckOldPwweek] = useState(false);
    const checkOldPw = () => {
        let reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/
        let status = !reg.test(inputValue.currentPassword);
        setcheckOldPwweek(status);
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate validated={validated} onSubmit={UpdatePassword}>
                <h2 className='heading text-center mb-2'>{heading}</h2>
                <Row className="mb-3">
                    <Col>
                        <Alert show={showErrorMessage} variant="danger" onClose={() => setShowErrorMessage(false)} dismissible>
                            {errorMessage}
                        </Alert>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Form.Group>
                        <Form.Label>Current Password<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control onKeyUp={onCurrentPwdKeyUp} required type="password" name="currentPassword" value={inputValue.currentPassword} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Enter your Current Password</Form.Control.Feedback>
                        <div className={(checkOldPwwee ? "" : "d-none") + " invalid-msg"}>
                            <span>Password is weak. It should contain alphabets, numbers and symbols</span>
                        </div>
                    </Form.Group>
                </Row>
                <Row className="mb-4">
                    <Form.Group>
                        <Form.Label>Create Password<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control onKeyUp={onPwdKeyUp} required type="password" name="password" value={inputValue.password} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Enter your Password</Form.Control.Feedback>
                        <div className={(pwdweek ? "" : "d-none") + " invalid-msg"}>
                            <span>Password is weak. It should contain alphabets, numbers and symbols</span>
                        </div>
                    </Form.Group>
                </Row>
                <Row className="mb-4">
                    <Form.Group>
                        <Form.Label>Confirm Password<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control onKeyUp={checkPasswords} required type="password" name="confirmpassword" value={inputValue.confirmpassword} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Re-enter your Password</Form.Control.Feedback>
                        <div className={(!pwdmatching ? "" : "d-none") + " invalid-msg"}>
                            <span>Passwords do not match</span>
                        </div>
                    </Form.Group>
                </Row>
                <Row className="mb-4">
                    <Col className={showCancel ? "text-center" : ""}>
                        {showCancel ?
                            <>
                                <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                                    Cancel
                                    {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                </Button>&nbsp;&nbsp;</> : ""
                        }
                        <Button className={!showCancel ? 'w-100' : ""} variant="primary" type="submit" disabled={loading}>
                            Submit
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </ErrorBoundary>
    );
}

export default (memo(ResetPasswordForm));
