import './App.css';
import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom'
import Login from './components/pages/Login/Login';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Layout from './components/Layout';
import { Provider } from 'react-redux'
import { store } from './Reducer/store'
import User from './components/pages/User/User';
import StateTax from './components/pages/StateTax/StateTax';
import FMS from './components/pages/FMS/FMS';
import SpendingPlanCategory from './components/pages/SpendingPlanCategory/SpendingPlanCategory';

import Role from './components/pages/Role/Role';
import Profile from './components/pages/Profile/Profile';
import LayoutUnauthorized from './components/LayoutUnauthorized';
import Home from './components/pages/Home/Home';
import Client from './components/pages/Client/Client';
import PCPForm from './components/pages/PCP/PCPForm';

import PCPPersonalData from './components/pages/PCP/PCPPersonalData';
import PCPPicPage from './components/pages/PCP/PCPPicPage';
import PCPLetteroflove from './components/pages/PCP/PCPLetter';
import PCPCircleofSupport from './components/pages/PCP/PCPCircleofSupport';
import PCPProfilePicture from './components/pages/PCP/PCPProfilePicture';
import PCPWhatWorks from './components/pages/PCP/PCPWhatWorks';
import PCPHopeDreams from './components/pages/PCP/PCPHopeDreams';
import PCPDescribetheClient from './components/pages/PCP/PCPDescribetheClient';
import PCPUnmetneeds from './components/pages/PCP/PCPUnmetneeds';
import PCPGoals from './components/pages/PCP/PCPGoal';
import PCPImportanttofor from './components/pages/PCP/PCPImportanttofor';
import PCPMeeting from './components/pages/PCP/PCPMeeting';
import PCPSummary from './components/pages/PCP/PCPSummary';
import PCPFlipbook from './components/pages/PCP/PCPFlipbook';
import PCPGeneratePCP from './components/pages/PCP/PCPGeneratePCP';
import SpendingPlanGenerator from './components/pages/SpendingPlan/SpendingPlanGenerator';
import PCPFlipbookView from './components/pages/PCP/PCPFlipbookView';
import RouteErrorElement from './components/RouteErrorElement';
import PageNotFound from './components/pages/PageNotFound/PageNotFound';


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route element={<LayoutUnauthorized />}>
                <Route index path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/pcpflipbookview/:id" element={<PCPFlipbookView />} />
            </Route>
            <Route exact element={<Layout />}>
                <Route path="/admin/dashboard" element={<Dashboard />} errorElement={<RouteErrorElement name="Dashboard" />} />
                <Route path="/admin/role" element={<Role />} errorElement={<RouteErrorElement name="Role" />} />
                <Route path="/admin/user" element={<User />} errorElement={<RouteErrorElement name="User" />} />
                <Route path="/admin/statetax" element={<StateTax />} errorElement={<RouteErrorElement name="StateTax" />} />
                <Route path="/admin/FMS" element={<FMS />} errorElement={<RouteErrorElement name="FMS" />} />
                <Route path="/admin/spendingplancategory" element={<SpendingPlanCategory />} errorElement={<RouteErrorElement name="SpendingPlanCategory" />} />

                <Route path="/admin/client" element={<Client />} errorElement={<RouteErrorElement name="Client" />} />
                <Route element={<PCPForm />} >
                    <Route path="/admin/pcpform/:id/personal" element={<PCPPersonalData />} errorElement={<RouteErrorElement name="PCPPersonalData" />} />
                    <Route path="/admin/pcpform/:id/frontpage" element={<PCPPicPage />} errorElement={<RouteErrorElement name="PCPPicPage" />} />
                    <Route path="/admin/pcpform/:id/Letteroflove" element={<PCPLetteroflove />} errorElement={<RouteErrorElement name="PCPLetteroflove" />} />
                    <Route path="/admin/pcpform/:id/CircleofSupport" element={<PCPCircleofSupport />} errorElement={<RouteErrorElement name="PCPCircleofSupport" />} />
                    <Route path="/admin/pcpform/:id/Profilepage" element={<PCPProfilePicture />} errorElement={<RouteErrorElement name="PCPProfilePicture" />} />
                    <Route path="/admin/pcpform/:id/WordsthatdescribeclientandInterests" element={<PCPDescribetheClient />} errorElement={<RouteErrorElement name="PCPDescribetheClient" />} />
                    <Route path="/admin/pcpform/:id/WhatsImportantToFor" element={<PCPImportanttofor />} errorElement={<RouteErrorElement name="PCPImportanttofor" />} />
                    <Route path="/admin/pcpform/:id/WhatWorksandWhatdoesntWork" element={<PCPWhatWorks />} errorElement={<RouteErrorElement name="PCPWhatWorks" />} />
                    <Route path="/admin/pcpform/:id/HopesDreams" element={<PCPHopeDreams />} errorElement={<RouteErrorElement name="PCPHopeDreams" />} />
                    <Route path="/admin/pcpform/:id/Goals" element={<PCPGoals />} errorElement={<RouteErrorElement name="PCPGoals" />} />
                    <Route path="/admin/pcpform/:id/ContinuedServicesUnmetNeed" element={<PCPUnmetneeds />} errorElement={<RouteErrorElement name="PCPUnmetneeds" />} />
                    <Route path="/admin/pcpform/:id/Summary" element={<PCPSummary />} errorElement={<RouteErrorElement name="PCPSummary" />} />
                    <Route path="/admin/pcpform/:id/Flipbook" element={<PCPFlipbook />} errorElement={<RouteErrorElement name="PCPFlipbook" />} />
                    <Route path="/admin/pcpform/:id/Meeting" element={<PCPMeeting />} errorElement={<RouteErrorElement name="PCPMeeting" />} />
                    <Route path="/admin/pcpform/:id/spendingplangenerator" element={<SpendingPlanGenerator />} errorElement={<RouteErrorElement name="SpendingPlanGenerator" />} />
                </Route>
                <Route path="/admin/spendplan" element={<Client />} title="Spending Plan" errorElement={<RouteErrorElement name="Client" />} />
                <Route path="/admin/profile" element={<Profile />} title="Profile" errorElement={<RouteErrorElement name="Profile" />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Route>
    )
);
function App() {
    return (
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    );
}
export default App;
