import { useEffect } from "react";
import { memo, useState } from "react";
import { Form, Spinner, Dropdown } from "react-bootstrap";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";

import ErrorHandler from "../ErrorHandler";
import "./FormAutoComplete.css";
import { ErrorBoundary } from 'react-error-boundary';
const FormAutoComplete = ({ name, onChange, value, url, labelField, valueField, errorMessage, ajaxOptions, requestType, ...otherProps }) => {
    const { fetchData } = useFetchWithCancellation();
    const [matching, setMatching] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const [formValue, setFormValue] = useState({ [labelField]: "", [valueField]: 0 });
    useEffect(() => {
        setFormValue(value);
    }, [value])
    const onDataChange = (e) => {
        let changedValue = e.target.value;
        let changedRow = { [labelField]: changedValue, [valueField]: 0 };
        setFormValue(changedRow);
        if (typeof onChange == "function") {
            onChange(changedRow);
        }
        if (changedValue.trim()) {
            if (changedValue.indexOf(" ", changedValue.length - 1) == changedValue.length - 1) {
                setLoading(true);
                setShow(true);
                let urlVal = url;
                let ajaxOpts = { ...ajaxOptions }
                debugger
                if (ajaxOptions.method && ajaxOptions.method.toLowerCase() === "post") {
                    ajaxOpts.headers = {
                        "Content-Type": "application/json",
                    }
                    ajaxOpts.body = JSON.stringify({ text: changedValue, limit: 5 })                    
                }
                else {
                    urlVal = urlVal + "?text=" + changedValue;
                    urlVal = urlVal + "&limit=5"
                }

                fetchData(urlVal, ajaxOpts)
                    .then(response => response.json()).then((res) => {
                        setError(null);
                        setMatching(res.Data);
                        setShow(res.Data.length > 0);
                    }).catch((err) => {
                        if (err && err.name === 'AbortError') return;
                        window.HandleError("FormAutoComplete.js ==> fetch", null, null, err);
                        setError(err);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        }
        else {
            setMatching([]);
            setShow(false);
        }
    }
    const onSelect = (row, e) => {
        setShow(false);
        setFormValue(row);
        if (typeof onChange == "function") {
            onChange(row);
        }
    }
    const onBlur = () => {
        setTimeout(() => {
            setShow(false);
        }, 2000);
    }
    return (<>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form.Control onBlur={onBlur} value={formValue[labelField]} onChange={onDataChange} {...otherProps} />
            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
            <Dropdown.Menu className="bab-form-autocomplete-menu" show={show}>
                {loading ? <Dropdown.Item>
                    <Spinner className='dropdown-loading' as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                </Dropdown.Item> : error ? <Dropdown.Item><ErrorHandler error={error} /></Dropdown.Item> :
                    matching.map((match) => {
                        return <Dropdown.Item className="border-bottom" key={match[valueField]} onClick={(e) => {
                            onSelect(match, e)
                        }}>{match[labelField]}</Dropdown.Item>
                    })}
            </Dropdown.Menu>
        </ErrorBoundary>
    </>)
}
export default (memo(FormAutoComplete));