
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Spinner, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PhotoCropper from '../../controls/FileUpload/PhotoCropper';
import Icon from '../../controls/Icons/Icon';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import { CommingSoon } from '../../../Utils';
import { PCPAction } from '../../../Reducer/PCPReducer';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';

function PCPLetteroflove() {
    const { fetchData } = useFetchWithCancellation();
    const picphoto1Ref = useRef(null);
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const { id } = useParams();
    const picFolderPath = "client/" + id + "/pcp/letteroflove/";
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    useEffect(() => {
        if (savedSuccessfully && !state.pcp.hasUnsavedChanges) {
            navigate("/admin/pcpform/" + id + "/circleofsupport");
        }
    }, [savedSuccessfully, state.pcp.hasUnsavedChanges]);
    const [inputValue, setInputValue] = useState({
        fkLetterById: "",
        fkLanguageId: "",
        LetterContent: "",
        ProfilePhoto: "",
        Id: 0,
    });
    const [validated, setValidated] = useState(false);
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const [loading, setLoading] = useState(false);
    const SaveData = async (e, moveNext) => {
        e.preventDefault();
        e.stopPropagation();
        if (formRef.current.checkValidity() === false) {
            setValidated(true);
        }
        else {
            let obj = inputValue;
            if (!obj.fkLetterById)
                obj.fkLetterById = null;
            if (!obj.fkLanguageId)
                obj.fkLanguageId = null;
            obj.fkClientId = id;
            setLoading(true);
            fetchData(state.projectdetail.API + "/pcp/SaveLetterOfLove", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
                    setSavedSuccessfully(moveNext);
                    window.toast(res.Message);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPLetter.js ==> SaveData", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    }
    useEffect(() => {
        fetchData(state.projectdetail.API + "/pcp/GetLetterOfLove?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    let letteroflove = res[0];
                    setInputValue(letteroflove);
                    if (letteroflove.ProfilePhoto) {
                        picphoto1Ref.current.setUploadedFile(letteroflove.ProfilePhoto);
                    }
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPLetter.js ==> GetLetterOfLove", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const formatSaveParams = (filename, fieldname) => {
        let obj = inputValue;
        if (!obj.fkLetterById)
            obj.fkLetterById = null;
        if (!obj.fkLanguageId)
            obj.fkLanguageId = null;
        return {
            ...obj, [fieldname]: filename, fkClientId: id
        }
    }
    const afterSave = (filename, fieldname) => {
        setInputValue({ ...inputValue, [fieldname]: filename });
    }
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate validated={validated} ref={formRef}>
            <Row>
                <Col>
                    <h5 className="d-inline-block">Letter of Love</h5>
                </Col>
                <Col md="auto">

                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={6}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Letter By<code className='required-symbol'>*</code></Form.Label>
                                <DynamicDropdownSearchable required={true} from={state.projectdetail.API + "/PrimaryContact/GetAll"} label="PrimaryContactName" value="Id" fetchType="get" name="fkLetterById" onDropdownChange={handleChange} defaultval={inputValue.fkLetterById} />
                                {validated && !inputValue.fkLetterById && <span className="text-danger">Please select letter by</span>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Select the Language<code className='required-symbol'>*</code></Form.Label>
                                <DynamicDropdownSearchable required={true} from={state.projectdetail.API + "/Language/GetAll"} label="languageName" value="Id" fetchType="get" name="fkLanguageId" onDropdownChange={handleChange} defaultval={inputValue.fkLanguageId} />
                                {validated && !inputValue.fkLanguageId && <span className="text-danger">Please select language</span>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <h5 className="d-inline-block"> </h5>
                    </Row>
                    <Row>

                    </Row>
                </Col>
                <Col md={"auto"}>
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <PhotoCropper ref={picphoto1Ref} height={150} width={130} roundedCrop={true} uploadFolderPath={picFolderPath}
                                    autoSave={true}
                                    saveURL={state.projectdetail.API + "/pcp/SaveLetterOfLove"}
                                    afterSave={(filename) => { afterSave(filename, "ProfilePhoto") }}
                                    formatSaveParams={(filename) => { return formatSaveParams(filename, "ProfilePhoto") }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Letter
                            <code className='required-symbol'>*</code>
                            <Button variant="link" onClick={CommingSoon}><Icon className="fs-5" name="BsMicFill" /></Button>
                        </Form.Label>
                        <Form.Control rows={30} as="textarea" required type="text" name="LetterContent" value={inputValue.LetterContent} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide content</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={loading} className="float-end">
                        Save & Next
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, false)} disabled={loading} className="float-end me-2">
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ErrorBoundary>
    );
}
export default (PCPLetteroflove);