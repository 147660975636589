export const ApplicationAction = {
    setApplication: "setApplication",    
    resetApplication: "resetApplication",
}
const initialApplication = {
    Id: 0,
    ApplicationName: "",
    URL: "",
    APPID: "",
    APIKey: ""
};

const applicationReducer = (application = initialApplication, actions) => {
    switch (actions.type) {
        case ApplicationAction.setApplication:
            return { ...application, ...actions.payload };
        case ApplicationAction.resetApplication:
            return Object.assign({}, initialApplication);
        default:
            return application;
    }
}
export default applicationReducer;