import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import { AccessAction } from "../Reducer/AccessReducer";


function LayoutUnauthorized() {
    const loc = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        document.body.setAttribute("data-theme", "turquoise")
        var location = loc.pathname;
        let loggedinUser = localStorage.getItem("user");
        if (loc.pathname.indexOf("pcpflipbookview") == -1) {
            if (loggedinUser) {
                navigate("/admin/dashboard");
                return;
            }
            else {
                dispatch({ type: AccessAction.resetAccess });
                if (location == "/") {
                    navigate("/");
                }
            }
        }
    }, [])
    return (
        <Outlet />
    )
}
export default (memo(LayoutUnauthorized));