import { useEffect, useState, memo, useRef, forwardRef, useImperativeHandle } from "react";
import ErrorHandler from "../ErrorHandler";
import DataGridPagination from "./DataGridPagination";
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Table, Row, Col, Spinner, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import './DataGrid.css';
import Icon from "../Icons/Icon";
import DataGridFilter from "./DataGridFilter";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
const DataGrid = forwardRef(({ url, options, columns, refresh, customData }, ref) => {
    const { fetchData } = useFetchWithCancellation();
    useImperativeHandle(ref, () => ({
        getSelected() {
            return selectedRows;
        },
        getData() {
            return data;
        },
        refresh() {
            refreshGrid();
        },
    }));
    const [mobieView, setMobieView] = useState(false);
    const handleResize = () => {
        if (window.outerWidth <= 991) {
            setMobieView(true);
        }
        else {
            setMobieView(false);
        }
    }
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize, false);
    }, []);

    const icons = {
        refresh: <Icon name="BsArrowClockwise" />,
        sort: {
            "asc": <Icon name="BsSortDownAlt" />,
            "desc": <Icon name="BsSortDown" />
        },
        export: <Icon name="BsDownload" />
    };
    const HorizontalAlignEnum = {
        CENTER: 'center',
        LEFT: 'left',
        RIGHT: 'right',
    }
    const SortOrder = {
        ASC: "asc",
        DESC: "desc",
    };
    const [columnDefaults, setColumnDefaults] = useState({
        title: undefined,
        field: undefined,
        formatter: function (value, row, index) { return value; },
        sortable: false,
        align: HorizontalAlignEnum.LEFT,
        searchable: true,
        filterable: false,
        visible: true,
        filterOptions: {},
        checkbox: false,
    });
    const defaults = useRef({
        pagination: true,
        pageSize: 10,
        dataField: "Data",
        totalField: "TotalRecords",
        dataType: "server",
        method: "POST",
        showExport: true,
        showRefresh: true,
        sortName: false,
        search: false,
        searchPlaceHolder: "Search",
        postResponse: function (res) {

        },
        defaultSelectedData: [],
        uniqueField: "id",
        onCheckboxChange: undefined,
        showCheckedCount: true,
        filter: false,
        onDoubleClickRow: function (row) {

        },
        enabledDoubleClicked: false,
        toolbar: "",
        cardView: false,
        formatQueryParams: function (obj) {
            return obj;
        },
        noDataText: "No data exists",
        onClickNoData: function () {

        },
        requestHeader: {},
        sendExportColumns: true,
    });
    const onDoubleClickRow = (row) => {
        if (defaults.current.onDoubleClickRow && typeof defaults.current.onDoubleClickRow == "function") {
            defaults.current.onDoubleClickRow.call(null, row);
        }
    }
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [error, setError] = useState();
    const [refreshDataGrid, setRefreshDataGrid] = useState(true);

    const fetchOptions = useRef({});
    const [sort, setSort] = useState({
        sort: "",
        order: undefined,
    });
    const [searchText, setSearchText] = useState("");
    const handleSearch = (e) => {
        let ff = {};
        for (let loop = 0; loop < columns.length; loop++) {
            if (columns[loop].searchable) {
                ff[columns[loop].field] = {};
                ff[columns[loop].field] = e.target.value;
            }
        }
        setFilterObj(ff);
        setSearchText(e.target.value);
    }
    const [filterObj, setFilterObj] = useState({});



    useEffect(() => {
        try {
            setError(null);
            defaults.current = Object.assign({}, defaults.current, options);
            if (defaults.current.dataType == "server") {
                fetchOptions.current = {};
                fetchOptions.current.headers = defaults.current.requestHeader;
                if (defaults.current.pagination) {
                    fetchOptions.current.body = {};
                    fetchOptions.current.body.filter = filterObj;
                    fetchOptions.current.body.offset = ((page * defaults.current.pageSize) - (defaults.current.pageSize)) + 1;
                    fetchOptions.current.body.page = page;
                    fetchOptions.current.body.limit = defaults.current.pageSize;
                    if (defaults.current.sortName) {
                        fetchOptions.current.body.sort = defaults.current.sortName;
                        if (defaults.current.sortOrder) {
                            fetchOptions.current.body.order = defaults.current.sortOrder;
                        }
                        else {
                            fetchOptions.current.body.order = SortOrder.ASC;
                        }
                    }
                    if (sort && sort.sort) {
                        fetchOptions.current.body.sort = sort.sort;
                        if (sort.order) {
                            fetchOptions.current.body.order = sort.order;
                        }
                    }
                    if (typeof defaults.current.formatQueryParams == "function") {
                        fetchOptions.current.body = defaults.current.formatQueryParams(fetchOptions.current.body);
                    }
                    fetchOptions.current.body = JSON.stringify(fetchOptions.current.body);
                }
                fetchOptions.current.method = defaults.current.method;
                if (defaults.current.method.toLowerCase() == "post") {
                    fetchOptions.current.headers["Content-Type"] = "application/json";
                }
                setLoading(true);
                fetchData(url, fetchOptions.current).then(res => res.json()).then((res) => {                    
                    setError(null);
                    setLoading(false)
                    if (defaults.current.pagination) {
                        if (res.hasOwnProperty(defaults.current.dataField)) {
                            setData(res[defaults.current.dataField]);
                            setCount(res[defaults.current.totalField]);
                            let ddd = {};
                            let fetchOptionsBody = JSON.parse(fetchOptions.current.body);
                            ddd.count = res[defaults.current.totalField];
                            ddd.page = fetchOptionsBody.page;
                            ddd.limit = fetchOptionsBody.limit;
                            ddd.offset = fetchOptionsBody.offset + 1;
                            const paginationOptions = ddd;
                            setPagination(paginationOptions);
                        }
                    }
                    else {
                        setData(res);
                    }
                    if (defaults.current.postResponse && typeof defaults.current.postResponse) {
                        defaults.current.postResponse.call(null, res);
                    }
                }).catch((er) => {
                    if (er && er.name === 'AbortError') return;
                    window.HandleError("DataGrid.js ==> grid request", null, null, er);
                    setLoading(false)
                    setError(er)
                })
            }
            else {
                if (customData) {
                    if (defaults.current.pagination) {
                        const paginationOptions = {
                            count: customData.length,
                            page: page,
                            limit: defaults.current.pageSize,
                            offset: ((page * defaults.current.pageSize) - (defaults.current.pageSize)) + 1
                        };
                        setPagination(paginationOptions);
                        setCount(customData.length);
                        setData(paginate(customData, defaults.current.pageSize, page));
                    }
                    else {
                        setData(d => ([...customData]));
                    }

                }
                else {
                    setData([]);
                }
            }
        }
        catch (error) {
            if (error && error.name === 'AbortError') return;
            window.HandleError("DataGrid.js ==> set data", null, null, error);
            setError(error);
        }
    }, [refreshDataGrid, refresh, page, sort, filterObj, customData]);


    function paginate(array, page_size, page_number) {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }
    const refreshGrid = () => {
        setRefreshDataGrid(r => !r);
    }
    const pageChange = (value) => {
        setPage(value);
    };
    const pageLimitChange = (value) => {
        defaults.current.pageSize = value;
        setRefreshDataGrid(r => !r);
    };

    const handleExport = () => {
        fetchOptions.current.body = JSON.parse(fetchOptions.current.body);
        fetchOptions.current.body.Export = true;
        fetchOptions.current.body.paginationFilter = { Export: true };
        if (defaults.current.sendExportColumns) {
            let ExportColumns = [];
            columns.forEach(function (col) {
                ExportColumns.push({ field: col.field, title: col.title, visible: true, });
            });
            fetchOptions.current.body.paginationFilter.ExportColumns = ExportColumns;
        }
        fetchOptions.current.body = JSON.stringify(fetchOptions.current.body);
        setLoading(true)
        fetchData(url, fetchOptions.current).then(res => res.blob()).then((res) => {
            setError(null)
            // if (typeof res == "object" && !res.status) {
            //     window.alert(res.message);
            // }
            // else {
            const url = window.URL.createObjectURL(
                new Blob([res]),
            );
            const link = document.createElement('a');
            link.href = url;
            let documentName = defaults.current.exportDocumetName;
            if (documentName) {
                documentName += getDate() + ".xlsx";
            }
            else {
                documentName = getDate() + ".xlsx";
            }
            link.setAttribute(
                'download',
                documentName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            // }
        }).catch((er) => {
            if (er && er.name === 'AbortError') return;
            window.HandleError("DataGrid.js ==> export", null, null, er);
            setError(er)
        }).finally(setLoading(false));
    }
    const getDate = () => {
        let now = new Date();
        let year = "" + now.getFullYear();
        let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
        let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
        let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
        let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
        let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
        let milliseconds = "" + now.getMilliseconds(); if (milliseconds.length == 1) { milliseconds = "0" + milliseconds; }
        return day + "" + month + "" + year + "" + hour + "" + minute + "" + second + "" + milliseconds;
    };
    const handleSort = (column) => {
        if (column.sortable) {
            let field = column.field;
            if (column.hasOwnProperty("sortName") && column.sortName) {
                field = column.sortName;
            }
            let order = sort.order;
            if (!order) {
                order = defaults.current.sortOrder;
            }
            if (!order) {
                order = SortOrder.ASC;
            }
            if (order == SortOrder.ASC) {
                order = SortOrder.DESC;
            }
            else if (order == SortOrder.DESC) {
                order = SortOrder.ASC;
            }
            setSort({ sort: field, order: order })
        }
    }
    const getValue = (row, field) => {
        if (!field) {
            return row;
        }
        if (field.indexOf(".") > -1) {
            field = field.split(".");
            let value = row[field[0]];
            field.shift();
            return getValue(value, field.join("."))
        }
        else {
            return row[field]
        }
    }


    const [filterOpen, setFilterOpen] = useState(false);
    const FilterRef = useRef();
    const onFilter = (filterObj) => {
        setFilterObj({ ...filterObj });
    }
    const clearFilter = () => {
        FilterRef.current.clearFilter();
    }

    const [selectedRows, setSelectedRows] = useState([]);

    const handleCheckbox = (e, column, row) => {
        let selrows = [...selectedRows];
        if (e.target.checked) {
            setSelectedRows(c => ([...c, row]));
            selrows = [...selrows, row];
        }
        else {

            selrows = selectedRows.filter((rr) => {
                return rr[defaults.current.uniqueField] != row[defaults.current.uniqueField];
            });
            setSelectedRows(selrows);
        }
        if (typeof defaults.current.onCheckboxChange == "function") {
            defaults.current.onCheckboxChange.call(null, e.target.checked, column, row, selrows);
        }
    }
    const checkboxValue = (row) => {
        let check = selectedRows.filter((rr) => {
            return rr[defaults.current.uniqueField] == row[defaults.current.uniqueField];
        })
        return check.length > 0;
    }
    const handleCheckboxAll = (e, column) => {
        if (defaults.current.pagination == true && count > data.length) {
            let message = "Do you want to selected in all pages?";
            if (e.target.checked) {
                message = "Do you want to select all in all pages?";
            }
            else {
                message = "Do you want to unselect all in all pages?";
            }
            window.confirm(message, function () {

            }, function () {
                let sels = [...selectedRows];
                if (e.target.checked) {
                    sels = [...sels, ...data];
                }
                else {

                }
                setSelectedRows(sels);
            });
        }

    }
    const clearSelected = () => {
        setSelectedRows([]);
    }
    const openFilter = () => {
        setFilterOpen(true);
    }

    const closeFilter = () => {
        setFilterOpen(false);
    }
    if (error) {
        return <ErrorHandler error={error} />
    }
    return (
        <>
            {/* {loading ? <GridSkeleton pagination={defaults.current.pagination} refresh={defaults.current.showRefresh} exportO={defaults.current.showExport} /> : */}
            <ErrorBoundary FallbackComponent={ErrorHandler}>
                <div className={"data-grid " + (filterOpen && "filter-open")} data-enable-double-click={defaults.current.enabledDoubleClicked}>
                    <Row>
                        <Col md={(defaults.current.filter && filterOpen) ? 3 : 0} className={"data-grid-filter-secton " + (!filterOpen ? "closed" : "")}>
                            <div className={(defaults.current.filter && filterOpen) ? "" : "d-none"}>
                                <Row>
                                    <Col>
                                        <h6 className='d-inline-block'>
                                            Filters
                                        </h6>
                                        <a className='float-end' onClick={closeFilter}><Icon name="BsX" /></a>
                                    </Col>
                                </Row>
                                <DataGridFilter columns={columns} onFilter={onFilter} ref={FilterRef} />
                            </div>
                        </Col>
                        <Col md={(defaults.current.filter && filterOpen) ? 9 : 12} className="data-grid-body">
                            <Row className="data-grid-toolbar mb-2">
                                <Col>
                                    <div className="float-end">
                                        {defaults.current.toolbar ?
                                            <div className="data-grid-toolbar-additional d-inline-block me-2">
                                                {defaults.current.toolbar}
                                            </div> : ""
                                        }
                                        {defaults.current.search ?
                                            <div className="d-inline-block me-2">
                                                <input type="text" className="form-control" value={searchText} placeholder={defaults.current.searchPlaceHolder} onChange={handleSearch} />
                                            </div>
                                            : ""}
                                        {defaults.current.showExport ?
                                            <Button variant="primary" onClick={handleExport} className="me-2">{icons.export}</Button>
                                            : ""}
                                        {defaults.current.showRefresh == true ?
                                            <Button variant="primary" onClick={refreshGrid}>{icons.refresh}</Button>
                                            : ""}
                                    </div>
                                    {(defaults.current.filter && !filterOpen) &&
                                        <div className="float-start">
                                            <OverlayTrigger overlay={<Tooltip>Apply Filters</Tooltip>}>
                                                <Button onClick={openFilter} className="me-2">
                                                    <Icon name="BsFillFunnelFill" />
                                                </Button>
                                            </OverlayTrigger>
                                            {Object.keys(filterObj).length > 0 ?
                                                <OverlayTrigger overlay={<Tooltip>Clear Filters</Tooltip>}>
                                                    <Button onClick={clearFilter}>
                                                        Clear Filter
                                                    </Button>
                                                </OverlayTrigger> : ""}
                                        </div>
                                    }
                                    {(selectedRows.length > 0 && defaults.current.showCheckedCount) ?
                                        <div className="ms-2 selected-rows-summary float-start">
                                            <Badge className="">
                                                {selectedRows.length} selected &nbsp;
                                                <Icon name="BsXCircle" className="ms-2" title="Clear selected" onClick={clearSelected} />
                                            </Badge>
                                        </div>
                                        : ""}
                                </Col>
                            </Row>
                            <Row className="data-grid-content">
                                <Col>
                                    {(!defaults.current.cardView && !mobieView) ?
                                        <Table responsive className="table">
                                            <thead>
                                                <tr>
                                                    {columns.map((column, index) => {
                                                        column = Object.assign({}, columnDefaults, column);
                                                        if (column.visible) {
                                                            if (column.checkbox) {
                                                                return (<th key={index}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        onChange={(e) => { handleCheckboxAll.call(null, e, column) }} />
                                                                </th>)
                                                            }
                                                            return <th key={index} sortable={column.sortable.toString()} onClick={(e) => handleSort(column, e)} align={column.align}>
                                                                {column.title}
                                                                {column.sortable ? <span className="icon fs-4">{(column.field == sort.sort || (column.field == defaults.current.sortName && !sort.sort)) ? icons.sort[(sort.order || defaults.current.sortOrder || SortOrder.ASC)] : ""}</span> : ""}
                                                            </th>
                                                        }
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ?
                                                    <tr><td colSpan={columns.length}><div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /></div></td></tr>
                                                    : data.length == 0 ? <tr><td colSpan={columns.length} align="center" onDoubleClick={defaults.current.onClickNoData}>{defaults.current.noDataText} </td></tr> :
                                                        data.map((row, index) => {
                                                            return (
                                                                <tr key={index} onDoubleClick={() => { onDoubleClickRow.call(null, row) }}>
                                                                    {columns.map((column, colindex) => {
                                                                        column = Object.assign({}, columnDefaults, column);
                                                                        if (column.visible) {
                                                                            if (column.checkbox) {
                                                                                let checked = checkboxValue(row);
                                                                                return (<td className="align-middle" key={colindex}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        checked={checked}
                                                                                        onChange={(e) => { handleCheckbox.call(null, e, column, row) }} />
                                                                                </td>)
                                                                            }
                                                                            return (<td className="align-middle" key={colindex}>{column.formatter(getValue(row, column.field), row, index)}
                                                                            </td>)
                                                                        }
                                                                    })}
                                                                </tr>
                                                            )
                                                        })}
                                            </tbody>
                                        </Table>
                                        :
                                        <div className="data-grid-content">
                                            {loading ?
                                                <div className="data-grid-content-row p-3 mb-3">
                                                    <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /></div>
                                                </div>
                                                : data.length == 0 ?
                                                    <div className="data-grid-content-row p-3 mb-3">
                                                        <Row>
                                                            <Col md={12} className="text-center" onDoubleClick={defaults.current.onClickNoData}>
                                                                {defaults.current.noDataText}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    :
                                                    <Row>
                                                        {data.map((row, rindex) => {
                                                            return (
                                                                <Col md={6} className="" key={rindex} onDoubleClick={(e) => { onDoubleClickRow.call(null, row) }}>
                                                                    <div className="data-grid-content-row p-3 mb-3">
                                                                        {columns.map((column, index) => {
                                                                            column = Object.assign({}, columnDefaults, column);
                                                                            if (column.visible) {
                                                                                return (
                                                                                    <Row className="mb-2" key={(rindex + "" + index)}>
                                                                                        {column.title &&
                                                                                            <Col xs={12} sm={6}>
                                                                                                <b>{column.title}</b>
                                                                                            </Col>
                                                                                        }
                                                                                        <Col xs={12} sm={column.title ? 6 : 12}>
                                                                                            {column.formatter(getValue(row, column.field), row, rindex)}
                                                                                        </Col>
                                                                                    </Row>
                                                                                )
                                                                            }
                                                                            return <></>
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                            {(defaults.current.pagination && data.length > 0) ?
                                <DataGridPagination
                                    count={pagination.count}
                                    limit={pagination.limit}
                                    page={pagination.page}
                                    onPageChange={pageChange}
                                    onPageLimitChange={pageLimitChange}
                                /> : ""}
                        </Col>
                    </Row>
                </div>
            </ErrorBoundary>
            {/* } */}
        </>
    )
});
export default (memo(DataGrid));