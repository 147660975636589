import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Modal } from 'react-bootstrap'
import Icon from '../../controls/Icons/Icon';
import UserForm from '../User/UserForm';
import './Profile.css';
import ResetPasswordForm from '../Login/ResetPasswordForm';
import { AccessAction } from '../../../Reducer/AccessReducer';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
const Profile = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [enableEdit, setEnableEdit] = useState(false);
    const [inputValue, setInputValue] = useState(state.access.user);

    const enableEditing = () => {
        setEnableEdit(!enableEdit);
    }
    const afterSave = (res) => {
        if (res.Succeeded) {

            var UserStore = JSON.parse(localStorage.getItem("user"));
            UserStore = {
                ...UserStore, ...res.Data,
                RefreshToken: UserStore.RefreshToken,
                AccessTokenExpires: UserStore.AccessTokenExpires,
                AccessToken: UserStore.AccessToken,
                TokenCreated: UserStore.TokenCreated,
                TokenExpires: UserStore.TokenExpires,
            }           
            dispatch({ type: AccessAction.setUser, payload: UserStore });
            localStorage.setItem("user", JSON.stringify(UserStore));           
            setEnableEdit(!enableEdit);
        }
    }

  
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const ChangePassword = () => {
        setShowModal(true);
    }
    const onCancelPassword = () => {
        setShowModal(false);
    }
    const afterReset = () => {
        window.alert("Password changed successfully");
        handleClose();
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>

            <div className='mb-4'>
                <h4 className='form-heading d-lg-inline-block d-md-inline-block d-xl-inline-block d-block'>
                    Employee Profile
                </h4>
                <div className='d-lg-inline-block d-md-inline-block d-xl-inline-block d-block float-md-end'>
                    <Button onClick={ChangePassword}>Change Password</Button>&nbsp;
                    <Button onClick={enableEditing}>Edit &nbsp;<Icon name="AiOutlineForm" /></Button>
                </div>
            </div>
            <Card>
                <Card.Body>
                    <h5 className='form-sub-heading'>
                        {enableEdit ? "Edit " : ""}Employee Profile
                    </h5>
                    <fieldset disabled={!enableEdit} className={(enableEdit ? "show-submit" : "") + " user"}>
                        <UserForm onCancel={enableEditing} _inputValue={inputValue} afterSave={afterSave} showPassword={false} disableForProfile={true} DontClearsave={true} />
                    </fieldset>

                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose} backdrop="static">
                <Modal.Body>
                    <ResetPasswordForm id={inputValue.Id} heading={"Change Password"} afterReset={afterReset} showCancel={true} onCancel={onCancelPassword} />
                </Modal.Body>
            </Modal>
        </ErrorBoundary>
    )
}
export default (Profile);