import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import Icon from '../Icons/Icon';
import { ErrorBoundary } from 'react-error-boundary';
import "./RefreshTokenPrompt.css";
import ErrorHandler from '../ErrorHandler';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const RefreshTokenPrompt = ({ showRefreshToken }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const state = useSelector(state => state);
    const navigate = useNavigate();
    useEffect(() => {
        setShow(showRefreshToken);
    }, [showRefreshToken])
    const handleOk = async () => {
        setLoading(true);
        let loggedUser = localStorage.getItem("user");
        let objLoggedUserA = JSON.parse(loggedUser);
        let refreshModel = {
            email: objLoggedUserA.email,
            refreshToken: objLoggedUserA.RefreshToken,
            accessToken: objLoggedUserA.AccessToken
        };
        let refRes = await fetch(state.projectdetail.API + "/auth/refresh", {
            method: "POST",
            async: false,
            body: JSON.stringify(refreshModel),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (refRes && refRes.ok) {
            let refResData = await refRes.json();
            if (refResData && refResData.Succeeded) {
                refResData.Data.AccessTokenExpires = new Date(new Date().setHours(new Date().getHours() + 6)).toString();
                localStorage.setItem("user", JSON.stringify(refResData.Data));
                setShow(false);
            }
            else {
                localStorage.clear();
                navigate("/");
            }
        }
        else {
            localStorage.clear();
            navigate("/");
        }
        setLoading(false);
    }
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Modal show={show} className={"alert-message "} centered backdrop="static">
                <Modal.Body>
                    <Row className='mb-3'>
                        <Col className='alert-message-icon'>
                            <Icon name="BiTimer" />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className='alert-message-message'>
                            Your session is going to expire
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className='alert-message-buttons'>
                            <Button disabled={loading} onClick={handleOk}>
                                Stay Login
                                {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                            </Button>
                            <Button className="ms-2" disabled={loading} onClick={handleLogout}>
                                Logout
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </ErrorBoundary>
    );
}

export default (RefreshTokenPrompt);
