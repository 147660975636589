import { memo, useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MultiSelectMasterData from "../../controls/MultiSelectMasterData";
import Icon from "../../controls/Icons/Icon";
import { PCPAction } from "../../../Reducer/PCPReducer";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";

const PCPWhatsImportantToFor = () => {
    const { fetchData } = useFetchWithCancellation();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const masterDataType = 7;
    const masterDataTypeInterest = 8;

    const state = useSelector(state => state);

    const [loading, setLoading] = useState(false);

    const masterDataRef = useRef();
    const masterDataRefInterest = useRef();
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    useEffect(() => {
        if (savedSuccessfully && !state.pcp.hasUnsavedChanges) {
            navigate("/admin/pcpform/" + id + "/whatworksandwhatdoesntwork");
        }
    }, [savedSuccessfully, state.pcp.hasUnsavedChanges]);
    const formatAddParams = (params) => {
        params.fkMasterDataTypeId = masterDataType;
        return params;
    }

    const formatAddParamsInterest = (params) => {
        params.fkMasterDataTypeId = masterDataTypeInterest;
        return params;
    }

    const SaveData = async (e, moveNext) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        var Result1 = false;
        var Result2 = false;
        try {
            let response = await fetchData(state.projectdetail.API + "/pcp/SaveImportantTo?clientId=" + id, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(masterDataRef.current.getSelectedIds())
            });

            let res = await response.json()
            if (res.Succeeded)
                Result1 = true;
        }
        catch (err) {
            if (err && err.name === 'AbortError') return;
            window.HandleError("PCPImportanttofor.js ==> SaveImportantTo", null, null, err);
            window.alert(state.projectdetail.ErrorMessage);
        }

        try {
            let response = await fetchData(state.projectdetail.API + "/pcp/SaveImportantFor?clientId=" + id, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(masterDataRefInterest.current.getSelectedIds())
            });

            let res2 = await response.json()
            if (res2.Succeeded)
                Result2 = true;
        }
        catch (err) {
            if (err && err.name === 'AbortError') return;
            window.HandleError("PCPImportanttofor.js ==> SaveImportantFor", null, null, err);
            window.alert(state.projectdetail.ErrorMessage);
        }

        if (Result1 && Result2) {
            setSavedSuccessfully(moveNext);
            dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
            window.toast('What is Important To and For Saved sucessfully');
        }
        else {
            window.alert('Something went wrong', function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
        setLoading(false);
    }
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/pcp/GetImportantTo?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    let ids = res.map(function (row) {
                        return row.fkImportantToId
                    });
                    masterDataRef.current.setSelected(ids);
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPImportanttofor.js ==> GetImportantTo", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });

        fetchData(state.projectdetail.API + "/pcp/GetImportanFor?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    let ids = res.map(function (row) {
                        return row.fkImportantForId
                    });
                    masterDataRefInterest.current.setSelected(ids);
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPImportanttofor.js ==> GetImportanFor", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate ref={formRef}>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col>
                            <h5 className="d-inline-block">What Important To</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {loading ? <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
                            <MultiSelectMasterData ref={masterDataRef}
                                name="DescribeclientIds"
                                dataURL={state.projectdetail.API + "/masterdata/getall?type=" + masterDataType}
                                fetchType="GET"
                                labelField="Text"
                                valueField="Id"
                                addNewURL={state.projectdetail.API + "/masterdata/save"}
                                addNewMethodType="POST"
                                formatAddParams={formatAddParams}
                                onCheckBoxChange={() => { dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true }); }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col>
                            <h5 className="d-inline-block">What Important For</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {loading ? <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
                            <MultiSelectMasterData ref={masterDataRefInterest}
                                name="Interests"
                                dataURL={state.projectdetail.API + "/masterdata/getall?type=" + masterDataTypeInterest}
                                fetchType="GET"
                                labelField="Text"
                                valueField="Id"
                                addNewURL={state.projectdetail.API + "/masterdata/save"}
                                addNewMethodType="POST"
                                formatAddParams={formatAddParamsInterest}
                                onCheckBoxChange={() => { dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true }); }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={loading} className="float-end">
                        Save & Next
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                    <Button variant="primary" type="button" onClick={(e) => SaveData(e, false
                    )} disabled={loading} className="float-end me-2">
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ErrorBoundary>
    )
}
export default (memo(PCPWhatsImportantToFor))