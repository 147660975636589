import { Outlet, useParams, Link, useLocation } from 'react-router-dom';
import { Card, Row, Col, Nav, Button, Accordion, Spinner } from 'react-bootstrap';
import { CommingSoon, fnGetFileNameFromContentDispostionHeader } from '../../../Utils';
import { useEffect, useState } from 'react';
import Icon from "../../controls/Icons/Icon";
import "./PCPForm.css";
import { useDispatch, useSelector } from 'react-redux';
import { PCPAction } from '../../../Reducer/PCPReducer';
import FormPrompt from '../../controls/Prompt/FormPrompt';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
const PCPForm = () => {
    const { fetchData } = useFetchWithCancellation();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { id } = useParams();
    const MenuLink = "/admin/pcpform/" + id;
    const loc = useLocation();
    let menuStatus = localStorage.getItem("pcpmenuopen");
    if (!menuStatus)
        localStorage.setItem("pcpmenuopen", "0");
    const [menuOpen, setMenuOpen] = useState(menuStatus == "1");
    const disabled = id === '0';
    const toggleMenu = () => {
        setMenuOpen(a => !a);
        let menuStatus = localStorage.getItem("pcpmenuopen");
        localStorage.setItem("pcpmenuopen", menuStatus == "0" ? "1" : "0");
    };
    const [loading, setLoading] = useState(false);
    const [defaultActiveKey, setDefaultActiveKey] = useState("0");
    const [downloading, setDownloading] = useState(false);
    const pdfURL = state.projectdetail.API + "/PCP/GeneratePDF?Id=" + id;
    useEffect(() => {
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
    }, [loc.pathname]);
    const downloadFile = () => {
        window.confirm("Are you sure you want to download?", function () {
            setDownloading(true);
            fetchData(state.projectdetail.API + "/PCP/GeneratePDF?Id=" + id).then(res => res.json()).then((res) => {
                if (res.Succeeded == true) {
                    fetchData(state.projectdetail.API + "/PCP/LoadPCP?Id=" + id).then(async (res, a) => ({
                        filename: fnGetFileNameFromContentDispostionHeader(res.headers.get('content-disposition')),
                        blob: await res.blob()
                    })).then((resObj) => {

                        const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob);
                        } else {
                            const objUrl = window.URL.createObjectURL(newBlob);

                            let link = document.createElement('a');
                            link.href = objUrl;
                            link.download = resObj.filename;
                            link.click();
                            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
                        }
                    }).catch((er) => {
                        if (er && er.name === 'AbortError') return;
                        window.HandleError("PCPForm.js ==> downloadFile", null, null, er);
                        window.alert("Failed to download")
                    }).finally(() => {
                        setDownloading(false);
                    });
                }
                else {
                    alert(res.Message);
                    setDownloading(false);
                }
            }).catch((er) => {
                if (er && er.name === 'AbortError') return;
                window.HandleError("PCPForm.js ==> downloadFile 2", null, null, er);
                window.alert("Failed to download");

                setDownloading(false);
            })
        });

    }
    useEffect(() => {
        setLoading(true);
        if (id && id > 0) {
            fetchData(state.projectdetail.API + "/client/Get?Id=" + id, {
                method: "Get"
            }).then(response => response.json()).then((res) => {
                if (res) {
                    res.DOB = new Date(res.DOB);
                    dispatch({ type: PCPAction.SetPersonalData, payload: res });
                }
                else {
                    window.alert('Something went wrong', function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPForm.js ==> client Get", null, null, err);
                console.log(err)
            }).finally(() => {
                setLoading(false);
            });
        }
        if (window.location.pathname.indexOf("spendingplangenerator") > -1) {
            setDefaultActiveKey("1");
        }
        else {
            setDefaultActiveKey("0");
        }
    }, []);
    const MenuLinkRender = (icon, text) => {
        if (menuOpen)
            return (<Row>
                <Col xs="auto" className="pe-0">
                    {icon}
                </Col>
                <Col className="ps-0">
                    {text}
                </Col>
            </Row>)
        else {
            return (<div>
                {icon}
            </div>)
        }
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <FormPrompt hasUnsavedChanges={state.pcp.hasUnsavedChanges} />
            <Row className="mb-2">
                <Col>
                    {id == 0 ?
                        <h4 className='form-heading d-inline-block'>
                            <Button variant="link" className="py-0 text-light" onClick={toggleMenu}>
                                <Icon name={menuOpen ? "BsArrowBarLeft" : "BsArrowBarRight"} className="fs-6" />
                            </Button>New client
                        </h4> : state.pcp.personalData && <div className="text-light">
                            <Row className="align-items-center">
                                <Col xs="auto" className="pe-0">
                                    <Button variant="link" className="py-0 ps-0 pe-0 text-light" onClick={toggleMenu}>
                                        <Icon name={menuOpen ? "BsArrowBarLeft" : "BsArrowBarRight"} className="fs-4" />
                                    </Button>
                                </Col>
                                <Col xs="auto">
                                    <b className="d-inline-block">
                                        {state.pcp.personalData.FirstName}&nbsp;{state.pcp.personalData.LastName}
                                    </b>
                                </Col>
                                <Col xs="auto">
                                    <b className="d-inline-block">UCI Number: {state.pcp.personalData.UCINumber} </b>
                                </Col>
                            </Row>
                        </div>}
                </Col>
                <Col md="auto">
                    <Button onClick={CommingSoon} variant="warning" type="button" className="float-end">
                        <Icon name="FaRegClone" className="fs-5" /> Clone
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col sm={menuOpen ? 3 : 1} className={"pcp-menu " + (menuOpen ? "open" : "closed")}>
                    <Card>
                        <Card.Body className="p-0 pb-2">
                            <Accordion activeKey={defaultActiveKey} className="mb-2">
                                <Accordion.Item eventKey="0" onClick={() => { setDefaultActiveKey((defaultActiveKey == "0" ? "" : "0")) }}>
                                    <Accordion.Header>
                                        <Icon name="BsPeopleFill" className={"pcp-menu-icon"} />
                                        <span className="pcp-menu-text">Person Centric Plan</span>
                                    </Accordion.Header>
                                    <Accordion.Body className="px-0" onClick={(e) => {
                                        setDefaultActiveKey("0");
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>
                                        <Nav variant="pills" className="flex-column" as="ul">
                                            <Nav.Item>
                                                <Nav.Link title={"Personal Details"} key="personal" className={loc.pathname.toLowerCase() === MenuLink + "/personal" ? "active" : ""} as={Link} to={MenuLink + "/personal"} >
                                                    {MenuLinkRender(<Icon name="BsPersonCircle" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Personal Details</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="frontpage" className={loc.pathname.toLowerCase() === MenuLink + "/frontpage" ? "active" : ""} as={Link} to={MenuLink + "/frontpage"} disabled={disabled}>
                                                    {MenuLinkRender(<Icon name="ImProfile" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Front Page</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="letteroflove" className={loc.pathname.toLowerCase() === MenuLink + "/letteroflove" ? "active" : ""} as={Link} to={MenuLink + "/letteroflove"} disabled={disabled}>
                                                    {MenuLinkRender(<Icon name="IoMail" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Letter of love</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="circleofsupport" className={loc.pathname.toLowerCase() === MenuLink + "/circleofsupport" ? "active" : ""} as={Link} to={MenuLink + "/circleofsupport"} disabled={disabled}>
                                                    {MenuLinkRender(<Icon name="FaStreetView" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Circle of Support</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="profilepage" className={loc.pathname.toLowerCase() === MenuLink + "/profilepage" ? "active" : ""} as={Link} to={MenuLink + "/profilepage"} disabled={disabled}>
                                                    {MenuLinkRender(<Icon name="ImProfile" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Profile page</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="wordsthatdescribeclientandinterests" className={loc.pathname.toLowerCase() === MenuLink + "/wordsthatdescribeclientandinterests" ? "active" : ""} as={Link} to={MenuLink + "/wordsthatdescribeclientandinterests"} disabled={disabled}>
                                                    {MenuLinkRender(<Icon name="IoAccessibilityOutline" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Words that describe client & Interests</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="whatsimportanttofor" className={loc.pathname.toLowerCase() === MenuLink + "/whatsimportanttofor" ? "active" : ""} as={Link} to={MenuLink + "/whatsimportanttofor"} disabled={disabled}>
                                                    {MenuLinkRender(<Icon name="BsExclamationCircleFill" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">What's Important To & For</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="whatworksandwhatdoesntwork" className={loc.pathname.toLowerCase() === MenuLink + "/whatworksandwhatdoesntwork" ? "active" : ""} as={Link} to={MenuLink + "/whatworksandwhatdoesntwork"} disabled={disabled}>



                                                    {MenuLinkRender(<Icon name="AiFillLike" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">What Works & What doesn't Work</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="hopesdreams" className={loc.pathname.toLowerCase() === MenuLink + "/hopesdreams" ? "active" : ""} as={Link} to={MenuLink + "/hopesdreams"} disabled={disabled}>



                                                    {MenuLinkRender(<Icon name="FaHands" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Hopes & Dreams</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="goals" className={loc.pathname.toLowerCase() === MenuLink + "/goals" ? "active" : ""} as={Link} to={MenuLink + "/goals"} disabled={disabled}>


                                                    {MenuLinkRender(<Icon name="TbTargetArrow" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Goals</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="continuedservicesunmetneed" className={loc.pathname.toLowerCase() === MenuLink + "/continuedservicesunmetneed" ? "active" : ""} as={Link} to={MenuLink + "/continuedservicesunmetneed"} disabled={disabled}>


                                                    {MenuLinkRender(<Icon name="SiFuturelearn" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Continued Services & Unmet Need</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="summary" className={loc.pathname.toLowerCase() === MenuLink + "/summary" ? "active" : ""} as={Link} to={MenuLink + "/summary"} disabled={disabled}>


                                                    {MenuLinkRender(<Icon name="BiNotepad" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Summary</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="meeting" className={loc.pathname.toLowerCase() === MenuLink + "/meeting" ? "active" : ""} as={Link} to={MenuLink + "/meeting"} disabled={disabled}>


                                                    {MenuLinkRender(<Icon name="MdGroups" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Meeting</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link key="flipbook" className={loc.pathname.toLowerCase() === MenuLink + "/flipbook" ? "active" : ""} as={Link} to={MenuLink + "/flipbook"} disabled={disabled}>


                                                    {MenuLinkRender(<Icon name="GiOpenBook" className={"pcp-menu-icon"} />,
                                                        <span className="pcp-menu-text">Flipbook</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                           
                                            <Nav.Item>
                                                <Nav.Link onClick={downloadFile} disabled={disabled}>
                                                    {MenuLinkRender(<span>{downloading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : <Icon name="BsDownload" className={"pcp-menu-icon"} />}</span>,
                                                        <span className="pcp-menu-text">Download PCP </span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {id > 0 &&
                                <Nav variant="pills" className="flex-column" as="ul">
                                    <Nav.Item>
                                        <Nav.Link  key="spendingplangenerator" className={loc.pathname.toLowerCase() === MenuLink + "/spendingplangenerator" ? "active" : ""} as={Link} to={MenuLink + "/spendingplangenerator"} disabled={disabled}>
                                            <Icon name="BsFillPiggyBankFill" className={"pcp-menu-icon"} />
                                            <span className="pcp-menu-text">Spending Plan Generator</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>}
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={menuOpen ? 9 : 11} className={"pcp-form " + (menuOpen ? "pcp-form-open" : "pcp-form-closed")}>
                    <Card>
                        <Card.Body>
                            <Outlet />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ErrorBoundary>
    );
}
export default (PCPForm);
