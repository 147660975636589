import React, { memo, useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import RoleAccess from './RoleAccess';
import ServerError from '../../controls/ServerError';
import Icon from '../../controls/Icons/Icon';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
function RoleForm({ _inputValue, afterSave }) {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [serverError, setserverError] = useState("");

    const [inputValue, setInputValue] = useState({
        role: "",
        description: "",
        active: 1,
        access: [],
        Id: 0,
    });

    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);
    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetchData(state.projectdetail.API + "/role/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputValue)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    setInputValue({
                        role: "",
                        description: "",
                        active: 1,
                        access: []
                    });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("RoleForm.js ==> role save", null, null, err);
                console.log(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    })
    const accessChanged = ((data) => {
        setInputValue(c => ({ ...c, access: data }));
    });
    const moduleAccessChanged = ((data) => {
        setInputValue(c => ({ ...c, moduleAccess: data }));
    })
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Role<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="text" name="roleName" value={inputValue.roleName} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Please proive role name</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" name="description" value={inputValue.description} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Form.Select value={inputValue.active} name="active" onChange={handleChange}>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <h4>Screen Access</h4>
                        <RoleAccess accessChanged={accessChanged} role={_inputValue.Id} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <ServerError error={serverError} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={12} className="text-center">
                        <Button variant="primary" type="submit" disabled={loading}>
                            Done
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </ErrorBoundary>
    )
}
export default (memo(RoleForm));