import React from 'react';
import { Container, Row, Card, Col } from 'react-bootstrap'
import LoginForm from './LoginForm';
import { useSelector } from 'react-redux';
import './Login.css'
import ForgotPasswordForm from './ForgotPasswordForm';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
const Login = () => {
    const state = useSelector(state => state);
    const [showLogin, setShowLogin] = useState(true);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const openForgotPassword = ((e) => {
        setShowLogin(false);
        setShowForgotPassword(true);
    })
    const afterResetLogin = (() => {
        setShowLogin(true);
        setShowForgotPassword(false);
    });
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Container className='min-vh-100'>
                <Row className='d-flex min-vh-100 align-items-center'>
                    <Col md={4}></Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <div className='text-center mb-4'>
                                    <img src="logo.png" className='login-logo' />
                                </div>
                                {showLogin ?
                                    <div>
                                    </div>
                                    : null}

                                {showLogin ? <LoginForm /> : null}
                                {showForgotPassword ? <ForgotPasswordForm afterResetLogin={afterResetLogin} /> : null}
                                {/*{ showLogin ?            */}
                                {/*<Row className="mb-4">*/}
                                {/*  <Col>*/}
                                {/*    <div className='text-center'>*/}
                                {/*      <a className='link' onClick={openForgotPassword}><BsLockFill />Forgot Password ?</a>*/}
                                {/*    </div>*/}
                                {/*  </Col>*/}
                                {/*</Row>*/}
                                {/*: null }*/}
                                <Row className="mb-3">
                                    <Col>
                                        <div className='text-center'>
                                            <span className='link poweredby'>Powered By {state.projectdetail.PoweredBy}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </Container>
        </ErrorBoundary>
    );
}

export default (Login);
