import { forwardRef, useRef, useState } from "react";
import { Row, Col, Button, Modal, CloseButton, Spinner } from 'react-bootstrap';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Icon from "../Icons/Icon";
import { memo } from "react";
import { useImperativeHandle } from "react";
import { getFileNamewithDate, validateFile } from "../../../Utils";
import { useSelector } from "react-redux";
import "./PhotoCropper.css";
import { useEffect } from "react";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../ErrorHandler";
const PhotoCropper = forwardRef(({ height, width, roundedCrop, uploadFolderPath, showDelete, showEdit, autoSave, saveURL, formatSaveParams, afterSave, lessSpacingBetweenOptions }, ref) => {
    const { fetchData } = useFetchWithCancellation();
    if (showDelete == undefined || showDelete == null) {
        showDelete = true;
    }
    if (showEdit == undefined || showEdit == null) {
        showEdit = true;
    }
    const state = useSelector(state => state);
    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("");
    const [cropedBlob, setCropedBlob] = useState(null);
    const cropperRef = useRef(null);
    const fileRef = useRef(null);
    const finalRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [uploading, setUploading] = useState(false);
    const handleClose = () => {
        setShowModal(false);
        fileRef.current.value = "";
    };
    const [filename, setFilename] = useState("");
    const [uploadedFile, setUploadedFile] = useState("");

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (validateFile(files[0], ["png", "jpg", "jpeg"])) {
            let file = files[0];
            let fileNamewithDate = getFileNamewithDate(file.name);
            setFilename(fileNamewithDate);
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                setShowModal(true);
            };
            reader.readAsDataURL(files[0]);
        }
        else {
            alert("Image should be less than 3MB with extensions(jpg, jpeg, png) ");
        }

    };
    const openFile = () => {
        fileRef.current.click();
    }
    const cropImage = async () => {
        if (!autoSave)
            handleClose();
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas({ width: width, height: height }).toDataURL())
            cropperRef.current?.cropper.getCroppedCanvas({ width: width, height: height }).toBlob(async function (blob) {
                setCropedBlob(blob);
            })
            fileRef.current.value = "";
        }
    }
    const styleObject = {
        width: width,
        height: height,
        display: "inline-block",
        overflow: "hidden"
    }
    const imageStyleObject = {
        width: width,
        height: height,
        border: "3px solid #674ea7"
    }
    if (roundedCrop) {
        styleObject.borderRadius = "50%";
        imageStyleObject.borderRadius = "50%";
    }

    const upload = async () => {
        setUploading(true);
        if (cropData) {
            try {
                if (uploadedFile) {
                    await deleteFile();
                }
                const formData = new FormData();
                formData.append(uploadFolderPath + filename, cropedBlob, filename);
                let response = await fetchData(state.projectdetail.API + "/files/upload", {
                    method: "POST",
                    body: formData
                });
                let res = await response.json();
                if (res.Succeeded) {
                    setUploadedFile(res.Data.join(","));
                    setCropData("");
                }
                return res;
            }
            catch (err) {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PhotoCropper.js ==> upload", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
                return { Succeeded: false, Message: err };
            }
        }
        else {
            return { Succeeded: false, Message: "No Data found" };
        }
        setUploading(true);
    }
    const deleteFile = async () => {
        if (uploadedFile) {
            try {
                let obj = {
                    folderpath: uploadFolderPath, filenames: [uploadedFile]
                };
                let response = await fetchData(state.projectdetail.API + "/files/delete", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj)
                });
                let res = await response.json();
                if (res.Succeeded) {
                    setUploadedFile("");
                }
                return res;
            }
            catch (err) {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PhotoCropper.js ==> delete", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
                return { Succeeded: false, Message: err };
            }
        }
        else {
            if (cropData) {
                setCropData("");
                return { Succeeded: true, Message: "" };
            }
            else {

            }
            return { Succeeded: false, Message: "No file to delete" };
        }
    }
    const confirmAndDelete = async () => {
        window.confirm("Are you sure to delete this image?", async function () {
            let res = await deleteFile();
            if (res.Succeeded) {
                await callSave(true);
                setUploadedFile("");
                window.toast("Image deleted successfully");
            }
            else {
                window.alert(res.Message);
            }
        });
    }
    const getFileName = () => {
        return uploadedFile;
    }
    useImperativeHandle(ref, () => ({
        upload,
        deleteFile,
        fileData: cropData,
        setUploadedFile: setUploadedFile,
        getFileName: getFileName
    }));
    const callSave = async (isDelete) => {
        let obj = {};
        if (typeof formatSaveParams == "function") {
            obj = formatSaveParams(isDelete ? "" : filename);
        }
        if (saveURL) {
            setUploading(true);
            try {
                let saveRespo = await fetchData(saveURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj)
                });
                let saveRes = await saveRespo.json();
                if (saveRes.Succeeded) {
                    if (typeof afterSave == "function") {
                        if (isDelete)
                            afterSave("");
                        else
                            afterSave(filename);
                    }
                    window.toast("Image " + (isDelete ? "deleted" : "uploaded") + " successfully");
                    setCropData("");
                    setCropedBlob(null);
                    handleClose();
                }
                else {
                    window.alert(saveRes.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }
            catch (err) {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PhotoCropper.js ==> save", null, null, err);
                window.alert("Failed to " + (isDelete ? "delete" : "upload") + " file", function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
            setUploading(false);
        }
    }
    useEffect(() => {
        async function saveData() {
            if (autoSave && cropedBlob) {
                let uploadStatus = await upload();
                if (uploadStatus.Succeeded) {
                    callSave(false);
                }
            }
        }
        saveData();
    }, [cropedBlob])
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <div className="photo-cropper">
            {uploading && <Spinner className='photo-cropper-uploading' as="span" animation="border" size="lg" role="status" aria-hidden="true" />}
            <div className="photo-cropper-choose">
                {cropData ?
                    <div className="photo-cropper-final-image" onClick={openFile}>
                        <img src={cropData} ref={finalRef} style={imageStyleObject} />
                    </div>
                    : uploadedFile ?
                        <div className="photo-cropper-final-image" onClick={openFile}>
                            <img src={state.projectdetail.API + "/files/load?filename=" + uploadFolderPath + uploadedFile} ref={finalRef} style={imageStyleObject} />
                        </div>
                        : <div className="photo-cropper-choose-container d-flex justify-content-center align-items-center" onClick={openFile} style={imageStyleObject}>
                            <div className="text-center">
                                <b>Upload Picture here
                                    <Icon name="BsUpload" className="d-block" />
                                </b>
                            </div>
                        </div>}
                <input type="file" ref={fileRef} className="d-none" onChange={onChange} />
            </div>
            {cropData || uploadedFile ? <div className="text-center photo-cropper-options">
                {showEdit && <Button
                    className={"btn-xs p-0 " + (lessSpacingBetweenOptions ? "me-1" : "me-4")} variant="link"
                    onClick={openFile}
                ><Icon name="BsPencilFill" className='fs-6' /></Button>}
                {showDelete && <Button variant="link"
                    className={"btn-xs p-0 " + (lessSpacingBetweenOptions ? "ms-1" : "ms-4")} onClick={confirmAndDelete}
                >
                    <Icon name="BsTrash" className='fs-6' />
                </Button>}
            </div> : ""}
            <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    Photo Cropping
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col md={12} className="text-center">
                            <Button disabled={uploading} variant="primary" type="button" onClick={cropImage}>
                                Done
                                {uploading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="col-auto">
                            <div className="photo-cropper-preview" style={styleObject}>

                            </div>
                        </Col>
                    </Row>
                    {image &&
                        <Cropper
                            cropBoxResizable={false}
                            dragMode={"move"}
                            ref={cropperRef}
                            style={{ height: 350, width: "100%" }}
                            zoomTo={0.5}
                            initialAspectRatio={width / height}
                            preview=".photo-cropper-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            maxWidth={width} // 1080
                            maxHeight={height} // 720
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                            scalable={false}
                        />
                    }

                </Modal.Body>
            </Modal>

        </div>
    </ErrorBoundary>)

});
export default (memo(PhotoCropper));