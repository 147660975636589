import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, NavDropdown, Form, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ErrorHandler from '../ErrorHandler';
import MenuSkeleton from '../../skeleton/MenuSkeleton'
import Icon from '../Icons/Icon';
import "./Menu.css";
import Offcanvas from 'react-bootstrap/Offcanvas';
import LoggedUserDetail from './LoggedUserDetail';
import MenuList from './MenuList';
import { ErrorBoundary } from 'react-error-boundary';
const Menu = ({ }) => {
    const state = useSelector(state => state);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [showUserProfile, setShowUserProfile] = useState(false);
    const [useRoleMenu, setUserRoleMenu] = useState([]);
    const menuRef = useRef(null);
    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            console.log(navigator.onLine);
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);
    useEffect(() => {
        let data = state.access.authorizedMenus.filter((a) => {

            return a.tblMenu && (a.tblMenu.path == "/admin/user" || a.tblMenu.path == "/admin/role" || a.tblMenu.path == "/admin/StateTax" || a.tblMenu.path == "/admin/FMS" || a.tblMenu.path == "/admin/spendingplancategory");
        });
        setUserRoleMenu(data);
        //window.addEventListener("scroll", function (e) {
        //    console.log(window.pageYOffset);
        //});
    }, []);
    if (error) {
        return <ErrorHandler error={error} />
    }

    const handleUserProfileClose = () => setShowUserProfile(false);
    const handleUserProfileShow = () => setShowUserProfile(true);
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            {loading ? <MenuSkeleton /> :
                <div>
                    <Navbar expand="lg" ref={menuRef}>
                        <Container>
                            <Navbar.Brand>
                                <img src="../logo.png" className="logo" height="80px" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">

                                <MenuList onUserClick={handleUserProfileShow} />
                                <Nav>
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Search"
                                            className="me-0 bg-white"
                                            aria-label="Search"
                                        />
                                    </Form>
                                    {useRoleMenu.length > 0 &&
                                        <Nav.Item>
                                            <NavDropdown title={<Icon name="BsFillGearFill" />}>
                                                {useRoleMenu.map((menu) => {
                                                    return (<NavDropdown.Item key={menu.tblMenu.Id} as={Link} to={menu.tblMenu.path}>
                                                        <Icon name={menu.tblMenu.icon} />&nbsp;{menu.tblMenu.menuName}
                                                    </NavDropdown.Item>)
                                                })}
                                            </NavDropdown>
                                        </Nav.Item>}
                                    <Nav.Item>
                                        <Nav.Link onClick={handleUserProfileShow}>
                                            <span className='username'><Icon name="FaUser" /> {state.access.user && state.access.user.name}</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                    <Offcanvas placement="end" show={showUserProfile} onHide={handleUserProfileClose}>
                        <Offcanvas.Body className='p-0'>
                            <LoggedUserDetail handleClose={handleUserProfileClose} />
                        </Offcanvas.Body>
                    </Offcanvas>
                    {isOnline ? (
                        <h1 className='online'></h1>
                    ) : (
                            <h1 className='offline text-center tada'>You Are Offline</h1>
                    )}
                </div>
            }
        </ErrorBoundary>
    );
}
export default (memo(Menu));