import { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Modal, CloseButton, Form, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import DynamicDropdownSearchable from "../../controls/DynamicDropdownSearchable";
import Icon from "../../controls/Icons/Icon";
import "./PCPCircleofSupportRelatives.css";
import ErrorHandler from "../../controls/ErrorHandler";
function PCPCircleofSupportRelatives({ type }) {
    const { fetchData } = useFetchWithCancellation();
    const relationshipText = type == 1 ? "family members" : type == 2 ? "friend or kith and kin" : "profossionals";
    const state = useSelector(state => state);
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const [relatives, setRelatives] = useState([]);
    const [tobeDeleted, setTobeDeleted] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [savedData, setSavedData] = useState([]);
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/pcp/getCircleOfSupportRelative?clientId=" + id + "&type=" + type)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res)) {
                    setRelatives(res);
                    setSavedData(res);
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPCircleofSupportRelatives.js ==> getCircleOfSupportRelative", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, [refresh, showModal]);

    const handleChange = (row, index, e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setRelatives(relatives.map((rrow, rindex) => {
            if (row.Id && rrow.Id == row.Id) {
                return { ...rrow, [name]: value };
            }
            if (rindex == index) {
                return { ...rrow, [name]: value };
            }
            return rrow;
        }));
    }
    const addNewRelationship = () => {
        setRelatives(c => [...c, { Id: 0, fkClientId: id, RelativeName: "", RelationshipType: type, RelationshipOtherName: "" }]);
    }
    const deleteRelative = (row, index) => {
        if (row.Id) {
            setTobeDeleted(c => [...c, row.Id]);
            setRelatives(relatives.filter((r) => {
                return r.Id != row.Id;
            }));
        }
        else {
            setRelatives(relatives.filter((r, rindex) => {
                return rindex != index;
            }));
        }
    }
    const saveCircleData = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            let obj = { Relatives: relatives, DeletedRelatives: tobeDeleted };
            setSaveLoading(true);
            fetchData(state.projectdetail.API + "/pcp/SaveCircleOfSupportRelative", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    window.toast(res.Message);
                    setValidated(false);
                    setRefresh(c => !c);
                    setShowModal(false);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPCircleofSupportRelatives.js ==> SaveCircleOfSupportRelative", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setSaveLoading(false);
            });
        }
    }
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <div className="circleofsupport-relative-wrapper d-flex justify-content-center align-items-center">
            <div className="circleofsupport-relative-inner p-2 d-flex justify-content-center align-items-center">
                <Row>
                    <Col className="circleofsupport-relative-wrapper-choose" onClick={() => { setShowModal(true) }}>
                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
                        {savedData.length == 0 && <b>Click here to enter the Name and the relationship</b>}
                        {savedData && savedData.map((row, index) => {
                            return (
                                <div key={index} className="circleofsupport-relative-item mt-1">
                                    <div className="circleofsupport-relative-item-name"><b>{row.RelativeName}</b></div>
                                    {(row.fkRelationshipId == 15 || row.fkRelationshipId == 16 || row.fkRelationshipId == 17) ?
                                        <div className="circleofsupport-relative-item-relative"><b>({row.RelationshipOtherName}) </b></div>
                                        : row.relationship && row.relationship.RelationshipName && <div className="circleofsupport-relative-item-relative"><b>({row.relationship.RelationshipName}) </b></div>}
                                </div>
                            )
                        })}
                    </Col>
                </Row>
            </div>
            <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    <h4><span className="text-capitalize">{relationshipText}</span> Details</h4>
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={saveCircleData}>
                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
                        <div className="border p-3 mb-2">
                            {relatives.map((row, index) => {
                                return (<Row key={index} className="mb-2 justify-content-center align-items-center">
                                    <Col xs={5}>
                                        <Form.Group>
                                            <Form.Label>Name <code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control maxLength={20} required name="RelativeName" value={row.RelativeName} onChange={(e) => { handleChange(row, index, e) }} />
                                            <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={5}>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Relationship</Form.Label>
                                                    <DynamicDropdownSearchable from={state.projectdetail.API + "/relationship/GetByRelationshipType?type=" + type} label="RelationshipName" value="Id" fetchType="get" name="fkRelationshipId" onDropdownChange={(e) => { handleChange(row, index, e) }} defaultval={row.fkRelationshipId} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {(row.fkRelationshipId == 15 || row.fkRelationshipId == 16 || row.fkRelationshipId == 17) && <Row className="mt-2">
                                            <Col>
                                                <Form.Group>
                                                    <Form.Control placholder="Type relationship here" maxLength={20} name="RelationshipOtherName" value={row.RelationshipOtherName} onChange={(e) => { handleChange(row, index, e) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>}
                                    </Col>

                                    <Col xs={2}>
                                        <Form.Label>&nbsp;</Form.Label>
                                        <div>
                                            <Button onClick={() => { deleteRelative(row, index) }} className="p-0" variant={"link"}>
                                                <Icon name="BsTrash" className='fs-5' />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>);
                            })}
                            {relatives.length == 0 && <Row>
                                <Col className="text-center">
                                    <b>No {relationshipText} found</b>
                                </Col>
                            </Row>}
                        </div>
                        {relatives.length <= 3 && <Row className="mb-2">
                            <Col className="float-end">
                                <Button onClick={addNewRelationship} title={"Add " + relationshipText} className="float-end p-0" variant={"link"}>
                                    <Icon name="AiOutlineUserAdd" className='fs-4' />
                                </Button>
                            </Col>
                        </Row>}
                        <Row>
                            <Col className="text-center">
                                <Button type="submit">
                                    Add to circle
                                    {saveLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    </ErrorBoundary>)
}
export default (PCPCircleofSupportRelatives);