import React from 'react'
import { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import ErrorHandler from '../../controls/ErrorHandler';
import { Form, Spinner, Table } from 'react-bootstrap'
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
function RoleAccess(props) {
    const { fetchData } = useFetchWithCancellation();
    const [data, setData] = useState([]);
    const state = useSelector(state => state);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        fetchData(state.projectdetail.API + "/roleaccess/getByRole?role=" + (props.role || "0"))
            .then(res => res.json())
            .then((res) => {
                setError(null)
                props.accessChanged(res);
                setData(res);
            })
            .catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("RoleAccess.js ==> getByRole", null, null, err);
                setError(err);
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    if (error) {
        return <ErrorHandler error={error} />
    }
    const handleChange = ((row, e) => {
        const name = e.target.name;
        let dd = JSON.parse(JSON.stringify(data));
        let rr = dd.find((rr) => ((rr.Id != 0 && rr.Id == row.Id) || rr.tblMenu.Id == row.tblMenu.Id));
        if (e.target.type == "checkbox") {
            let value = e.target.checked;
            rr[name] = value;
            setData(dd);
            props.accessChanged(dd);
        }
    });
    const checkUnCheckAll = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        let dd = JSON.parse(JSON.stringify(data));
        dd = dd.map((row) => {
            return { ...row, [name]: checked }
        });
        setData(dd);
        props.accessChanged(dd);
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Table responsive className="table table-striped">
                <thead>
                    <tr>
                        <th>Menu</th>
                        <th>
                            View
                            <Form.Check onChange={checkUnCheckAll} type='checkbox' name="view" />
                        </th>
                        <th>Add  <Form.Check onChange={checkUnCheckAll} type='checkbox' name="add" /></th>
                        <th>Edit <Form.Check onChange={checkUnCheckAll} type='checkbox' name="edit" /></th>
                        <th>Delete <Form.Check onChange={checkUnCheckAll} type='checkbox' name="delete" /></th>
                        <th>Export <Form.Check onChange={checkUnCheckAll} type='checkbox' name="export" /></th>
                    </tr>
                </thead>
                <tbody>

                    {loading ? <tr><td colSpan={6} align='center'><Spinner as="span" animation="border" size='md' role="status" aria-hidden="true" /></td></tr> :
                        data.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td>{row.tblMenu.menuName}</td>
                                    <td>
                                        {row.tblMenu.view && <Form.Check onChange={(e) => handleChange(row, e)} checked={row.view} type="checkbox" variant="danger" name="view" />}
                                    </td>
                                    <td>
                                        {row.tblMenu.add && <Form.Check onChange={(e) => handleChange(row, e)} checked={row.add} type="checkbox" variant="danger" name="add" />}
                                    </td>
                                    <td>
                                        {row.tblMenu.edit && <Form.Check onChange={(e) => handleChange(row, e)} checked={row.edit} type="checkbox" variant="danger" name="edit" />}
                                    </td>
                                    <td>
                                        {row.tblMenu.delete && <Form.Check onChange={(e) => handleChange(row, e)} checked={row.delete} type="checkbox" variant="danger" name="delete" />}
                                    </td>
                                    <td>
                                        {row.tblMenu.export && <Form.Check onChange={(e) => handleChange(row, e)} checked={row.export} type="checkbox" variant="danger" name="export" />}
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
        </ErrorBoundary>
    )
}
export default (memo(RoleAccess));