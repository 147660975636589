import { useState } from 'react';
import { useCallback, useEffect } from 'react';
function fetchWithCancellation(url, options) {
    return new Promise(async (resolve, reject) => {
        try {
            //const response = await axios({
            //    url: url,
            //    ...options
            //})
            const response = await fetch(url, options);
            resolve(response);
        } catch (error) {
            if (error.name === 'AbortError') {                
                //reject(error);
            } else {
                debugger
                reject(error);
            }
        }
    });
}

function useFetchWithCancellation(from) {    
    const [controller, setController] = useState(new AbortController());
    useEffect(() => {
        setController(new AbortController());
        return () => {
            return controller.abort();
        };
    }, []);

    const fetchData = useCallback((url, options) => {        
        let opts = {};
        if (options)
            opts = options;
        return fetchWithCancellation(url, { ...opts, signal: controller.signal });
    }, []);
    return {
        fetchData
    }
}
export default useFetchWithCancellation;