import React from 'react';
import { Spinner } from 'react-bootstrap';

import "./PageSkeleton.css";
const PageSkeleton = () => {
    return (
        <div className="sk-page">
            <div className="text-center">
                <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
            </div>            
        </div>
    );
}

export default (PageSkeleton);
