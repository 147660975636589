
import { useRef, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Spinner, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../controls/Icons/Icon';

import { useEffect } from 'react';
import { CommingSoon } from '../../../Utils';
import { PCPAction } from '../../../Reducer/PCPReducer';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
function PCPSummary() {
    const { fetchData } = useFetchWithCancellation();
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const { id } = useParams();

    const formRef = useRef(null);
    const [inputValue, setInputValue] = useState({
        Summary: "",
        Id: 0,
    });
    const [validated, setValidated] = useState(false);
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    useEffect(() => {
        if (savedSuccessfully && !state.pcp.hasUnsavedChanges) {
            navigate("/admin/pcpform/" + id + "/meeting");
        }
    }, [savedSuccessfully, state.pcp.hasUnsavedChanges]);
    const SaveData = async (e, moveNext) => {
        e.preventDefault();
        e.stopPropagation();
        if (formRef.current.checkValidity() === false) {
            setValidated(true);
        }
        else {
            let obj = inputValue;
            obj.fkClientId = id;

            setLoading(true);
            fetchData(state.projectdetail.API + "/pcp/SaveSummary", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setSavedSuccessfully(moveNext);
                    dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
                    window.toast(res.Message);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPSummary.js ==> SaveData", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    }
    useEffect(() => {
        fetchData(state.projectdetail.API + "/pcp/GetSummary?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    let letteroflove = res[0];
                    setInputValue(letteroflove);

                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPSummary.js ==> GetSummary", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate validated={validated} ref={formRef}>
                <Row>
                    <Col>
                        <h5 className="d-inline-block">Summary</h5>
                    </Col>
                    <Col md="auto">

                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Summary<code className='required-symbol'>*</code>
                                <Button variant="link" onClick={CommingSoon}><Icon className="fs-5" name="BsMicFill" /></Button>
                            </Form.Label>
                            <Form.Control rows={30} as="textarea" required type="text" name="Summary" value={inputValue.Summary} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Please provide Summary</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2  mb-bottom fixed-bottom" >
                    <Col md={12} className="text-center">
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, true)} disabled={loading} className="float-end">
                            Save & Next
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, false)} disabled={loading} className="float-end me-2">
                            Save
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </ErrorBoundary>
    );
}
export default (PCPSummary);