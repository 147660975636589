import { useRef, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';

function PCPGeneratePCP() {

    const formRef = useRef(null);
    const [validated, setValidated] = useState(false);

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Form noValidate validated={validated} ref={formRef}>
                <Row>
                    <Col>
                        <h5 className="d-inline-block">Generate PCP</h5>
                    </Col>
                </Row>

                <Row>
                    <Col>

                    </Col>
                </Row>
            </Form>
        </ErrorBoundary>
    );
}
export default (PCPGeneratePCP);