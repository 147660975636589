import { useEffect, useRef, useState } from 'react';
import { Row, Col,  Button } from 'react-bootstrap';
import PhotoCropper from '../../controls/FileUpload/PhotoCropper';
import { useParams, useNavigate } from "react-router-dom";
import "./PCPCircleofSupport.css";
import PCPCircleofSupportRelatives from './PCPCircleofSupportRelatives';
import { useSelector } from 'react-redux';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../../controls/ErrorHandler';
function PCPCircleofSupport() {
    const { fetchData } = useFetchWithCancellation();
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const { id } = useParams();
    const uploadFolderPath = "client/" + id + "/pcp/circleofsupport/";
    const picphoto1Ref = useRef(null);
    const picphoto2Ref = useRef(null);
    const picphoto3Ref = useRef(null);
    const picphoto4Ref = useRef(null);
    const picphoto5Ref = useRef(null);
    const picphoto6Ref = useRef(null);
    const picphoto7Ref = useRef(null);
    const picphoto8Ref = useRef(null);
    const picphoto9Ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState({ Id: 0, fkClientId: id });
    const SaveData = () => {
        navigate("/admin/pcpform/" + id + "/profilepage");
    }
    const formatSaveParams = (filename, fieldname) => {
        return {
            ...inputValue, [fieldname]: filename, fkClientId: id
        }
    }
    const afterSave = (filename, fieldname) => {
        setInputValue({ ...inputValue, [fieldname]: filename });
    }
    useEffect(() => {
        fetchData(state.projectdetail.API + "/pcp/GetCircleOfSupport?clientId=" + id)
            .then(response => response.json()).then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    let obj = res[0];
                    setInputValue(obj);
                    if (obj.CSPic1) {
                        picphoto1Ref.current.setUploadedFile(obj.CSPic1);
                    }
                    if (obj.CSPic2) {
                        picphoto2Ref.current.setUploadedFile(obj.CSPic2);
                    }
                    if (obj.CSPic3) {
                        picphoto3Ref.current.setUploadedFile(obj.CSPic3);
                    }
                    if (obj.CSPic4) {
                        picphoto4Ref.current.setUploadedFile(obj.CSPic4);
                    }
                    if (obj.CSPic5) {
                        picphoto5Ref.current.setUploadedFile(obj.CSPic5);
                    }
                    if (obj.CSPic6) {
                        picphoto6Ref.current.setUploadedFile(obj.CSPic6);
                    }
                    if (obj.CSPic7) {
                        picphoto7Ref.current.setUploadedFile(obj.CSPic7);
                    }
                    if (obj.CSPic8) {
                        picphoto8Ref.current.setUploadedFile(obj.CSPic8);
                    }
                    if (obj.CSPic9) {
                        picphoto9Ref.current.setUploadedFile(obj.CSPic9);
                    }
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPCircleofSupport.js ==> GetCircleOfSupport", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Row>
                <Col>
                    <h5 className="d-inline-block">Circle of Support</h5>
                </Col>
            </Row>
            <div className="circleofsupport-wrapper-outer">
                <div className="circle-big">
                    <PhotoCropper
                        lessSpacingBetweenOptions={true}
                        height={250} width={250}
                        ref={picphoto1Ref}
                        roundedCrop={true}
                        autoSave={true}
                        saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                        uploadFolderPath={uploadFolderPath}
                        afterSave={(filename) => { afterSave(filename, "CSPic1") }}
                        formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic1") }} />
                </div>
                <div className="circleofsupport-wrapper">
                    <div className="circle small deg-26">
                        <PhotoCropper ref={picphoto2Ref} height={100} width={100}
                            lessSpacingBetweenOptions={true}
                            roundedCrop={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic2") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic2") }} />
                    </div>
                    <div className="circle small deg-52">
                        <PhotoCropper ref={picphoto3Ref} height={100} width={100} roundedCrop={true}
                            lessSpacingBetweenOptions={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic3") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic3") }} />
                    </div>
                    <div className="circle callout deg-96" style={{ marginTop: "-55px" }}>
                        <PCPCircleofSupportRelatives type={1} data-identify="bottom" />
                    </div>
                    <div className="circle small deg-130">
                        <PhotoCropper ref={picphoto4Ref} height={100} width={100} roundedCrop={true}
                            lessSpacingBetweenOptions={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic4") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic4") }} />
                    </div>
                    <div className="circle small deg-156">
                        <PhotoCropper ref={picphoto5Ref} height={100} width={100} roundedCrop={true}
                            lessSpacingBetweenOptions={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic5") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic5") }} />
                    </div>
                    <div className="circle callout deg-198" style={{ marginLeft: "-64px" }}>
                        <PCPCircleofSupportRelatives type={2} data-identify="left" />
                    </div>
                    <div className="circle small deg-234">
                        <PhotoCropper ref={picphoto6Ref} height={100} width={100} roundedCrop={true}
                            lessSpacingBetweenOptions={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic6") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic6") }} />
                    </div>
                    <div className="circle small deg-260">
                        <PhotoCropper ref={picphoto7Ref} height={100} width={100} roundedCrop={true}
                            lessSpacingBetweenOptions={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic7") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic7") }} />
                    </div>
                    <div className="circle small deg-286">
                        <PhotoCropper ref={picphoto8Ref} height={100} width={100} roundedCrop={true}
                            lessSpacingBetweenOptions={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic8") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic8") }} />
                    </div>
                    <div className="circle small deg-312">
                        <PhotoCropper ref={picphoto9Ref} height={100} width={100} roundedCrop={true}
                            lessSpacingBetweenOptions={true}
                            autoSave={true}
                            saveURL={state.projectdetail.API + "/pcp/SaveCircleOfSupport"}
                            uploadFolderPath={uploadFolderPath}
                            afterSave={(filename) => { afterSave(filename, "CSPic9") }}
                            formatSaveParams={(filename) => { return formatSaveParams(filename, "CSPic9") }} />
                    </div>
                    <div className="circle callout deg-343" style={{ marginLeft: "-101px" }}>
                        <PCPCircleofSupportRelatives type={3} data-identify="right" />
                    </div>
                </div>
            </div>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Button variant="primary" type="button" onClick={SaveData} disabled={loading} className="float-end">
                        Next
                    </Button>
                </Col>
            </Row>
        </ErrorBoundary>
    );
}
export default (PCPCircleofSupport);