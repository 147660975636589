import { memo } from "react";

import "./CurrencyLabel.css";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "./ErrorHandler";
const CurrencyLabel = ({ value }) => {
    let val = value
    if (!val || val == "NaN") {
        val = "0";
    }
    if (typeof val == "number") {
        val = val.toFixed(2);
    }
    var res = [val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")];


    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <span className="currency-label">{res.join(".")}</span>
        </ErrorBoundary>
    )
}

export default (memo(CurrencyLabel));