export const SPAction = {
    AddCategory: "ADDSPCATEGORY",
    SetSP: "SETSP",
}
const initialSP = {
    fkClientId: "",
    DurationBeginsDate: "",
    DurationEndsDate: "",
    categories: [],
    fkFMSId: 0,
    fms: {},
};

const SPReducer = (pcp = initialSP, actions) => {
    switch (actions.type) {
        case SPAction.AddCategory:
            let data = pcp.categories.filter((row) => {
                return row.fkSpendingPlanCategoryId == actions.payload.fkSpendingPlanCategoryId
            })
            if (data && data.length > 0) {
                data[0].plans.push(actions.payload)
            }
            else {
                let objPlan = { ...actions.payload };
                delete objPlan.spendingPlanCategory;
                data.push({
                    fkSpendingPlanCategoryId: actions.payload.fkSpendingPlanCategoryId,
                    spendingPlanCategory: actions.payload.spendingPlanCategory,
                    Total: 0,
                    plans: [
                        { ...objPlan }
                    ]
                });
            }
            return { ...pcp, categories: data };
        case SPAction.SetSP:
            return { ...pcp, ...actions.payload };
        default:
            return pcp;
    }
}
export default SPReducer;