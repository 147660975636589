import { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import DynamicDropdownSearchable from "../../controls/DynamicDropdownSearchable";
import Icon from "../../controls/Icons/Icon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate } from 'react-router-dom'
import { CommingSoon, getDateSaveFormat } from "../../../Utils.js";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { PCPAction } from "../../../Reducer/PCPReducer";
import useFetchWithCancellation from "../../../customhooks/useFetchWithCancellation";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "../../controls/ErrorHandler";

function PCPPersonalData() {
    const { fetchData } = useFetchWithCancellation();
    const { id } = useParams();
    const navigate = useNavigate();
    const formRef = useRef();
    const state = useSelector(state => state);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    const [navigateNext, setNavigateNext] = useState(false);
    const [navigateSP, setnavigateSP] = useState(false);
    const [inputValue, setInputValue] = useState({
        FirstName: "",
        LastName: "",
        UCINumber: "",
        fkGenderId: "",
        DOB: null,
        fkZodiacSignId: null,
        FatherName: "",
        MotherName: "",
        fkParentStatusId: null,
        Address: "",
        ZipCode: "",
        State: "",
        MobileNumber: "",
        AlternativeMobileNumber: "",
        EmailID: "",
        ReceiveMobileNumber: false,
        ReceiveAlternativeMobileNumber: false,
        Diagnosis: "",
        fkPrimaryContactId: 0,
        Id: 0,
    });
    useEffect(() => {
        if (savedSuccessfully && !state.pcp.hasUnsavedChanges && inputValue.Id != 0) {
            if (navigateNext == 1) {
                if (id == 0) {
                    navigate("/admin/pcpform/" + inputValue.Id + "/personal");
                    window.location.reload();
                }

            }
            else if (navigateNext == 2) {
                navigate("/admin/pcpform/" + inputValue.Id + "/frontpage");
            }
            else {
                navigate("/admin/pcpform/" + inputValue.Id + "/spendingplangenerator");
            }
        }
    }, [savedSuccessfully, state.pcp.hasUnsavedChanges, inputValue.Id, navigateNext]);


    useEffect(() => {
        if (id && id > 0) {
            fetchData(state.projectdetail.API + "/client/Get?Id=" + id, {
                method: "Get"
            }).then(response => response.json()).then((res) => {
                if (res) {
                    res.DOB = new Date(res.DOB);
                    setInputValue(res);
                    dispatch({ type: PCPAction.SetPersonalData, payload: res });
                }
                else {
                    window.alert('Something went wrong', function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }

            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPPersonalData.js ==> client Get", null, null, err);
                console.log(err)
            }).finally(() => {
                //setLoading(false);
            });
        }
    }, []);


    const SaveData = ((e, movenext, flag) => {
        e.preventDefault();
        e.stopPropagation();
        if (formRef.current.checkValidity() === false) {
            setValidated(true);
        }
        else {
            if (!inputValue.DOB) {
                setValidated(true);
                return false;
            }

            if (inputValue.MobileNumber && !isValidPhoneNumber(inputValue.MobileNumber)) {
                return false;
            }
            if (inputValue.AlternativeMobileNumber && !isValidPhoneNumber(inputValue.AlternativeMobileNumber)) {
                if (inputValue.AlternativeMobileNumber != "")
                    return false;
            }
            if (inputValue.Address && !inputValue.Address.trim()) {
                setValidated(true);
                setInputValue(c => ({ ...c, ["Address"]: inputValue.Address.trim() }));
                return false;
            }
            let obj = JSON.parse(JSON.stringify(inputValue));
            obj.DOB = getDateSaveFormat(inputValue.DOB);
            if (!obj.fkZodiacSignId || obj.fkZodiacSignId == "") {
                obj.fkZodiacSignId = null;
            }


            setLoading(true);
            fetchData(state.projectdetail.API + "/client/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    res.Data.DOB = new Date(res.Data.DOB);
                    setInputValue(res.Data);
                    setNavigateNext(movenext);
                    dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: false });
                    dispatch({ type: PCPAction.SetPersonalData, payload: res.Data });
                    setSavedSuccessfully(true);
                    window.toast("Client saved sucessfully");
                    setValidated(false);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                if (err && err.name === 'AbortError') return;
                window.HandleError("PCPPersonalData.js ==> client save", null, null, err);
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    });
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
        dispatch({ type: PCPAction.SetPCPHasUnsavedChanges, payload: true });
    });
    const handlePhoneNumberChange = ((value, name) => {
        var result = inputValue;
        result[name] = value;
        if ((value == "" || value == undefined) && name == "AlternativeMobileNumber") {
            result.ReceiveAlternativeMobileNumber = false;
        }
        setInputValue(result);
    });
    const handleDatePickerChange = ((value) => {
        setInputValue(c => ({ ...c, ["DOB"]: value }));
    })
    return (<ErrorBoundary FallbackComponent={ErrorHandler}>
        <Form noValidate validated={validated} ref={formRef}>
            <Row>
                <Col>
                    <h5 className="d-inline-block">Personal Details</h5>
                </Col>
                <Col md="auto">

                </Col>
            </Row>
            <Row className="mb-2">
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>First Name<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control required type="text" name="FirstName" value={inputValue.FirstName} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide first name</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Last Name<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control required type="text" name="LastName" value={inputValue.LastName} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide last name</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>UCI Number</Form.Label>
                        <Form.Control type="text" name="UCINumber" value={inputValue.UCINumber} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide UCI number</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Gender<code className='required-symbol'>*</code></Form.Label>
                        <DynamicDropdownSearchable required={true} from={state.projectdetail.API + "/gender/GetAll"} label="GenderType" value="Id" fetchType="get" name="fkGenderId" onDropdownChange={handleChange} defaultval={inputValue.fkGenderId} />
                        {validated && !inputValue.fkGenderId && <small className="text-danger">Please provide gender</small>}
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>DOB<code className='required-symbol'>*</code></Form.Label>
                        <DatePicker className="form-control"
                            dateFormat="MM-dd-yyyy"
                            selected={inputValue.DOB}
                            showYearDropdown
                            showMonthDropdown
                            onChange={handleDatePickerChange}
                        />
                        {validated && !inputValue.DOB && <small className="text-danger">Please provide DOB</small>}
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Zodiac Sign</Form.Label>
                        <DynamicDropdownSearchable from={state.projectdetail.API + "/ZodiacSign/GetAll"} label="ZodiacSignType" value="Id" fetchType="get" name="fkZodiacSignId" onDropdownChange={handleChange} defaultval={inputValue.fkZodiacSignId} />
                        {validated && !inputValue.fkZodiacSignId && <small className="text-danger"></small>}
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Father's Name<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control required type="text" name="FatherName" value={inputValue.FatherName} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide father's name</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Mother's Name<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control required type="text" name="MotherName" value={inputValue.MotherName} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide mother's name</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Parent's Status<code className='required-symbol'>*</code></Form.Label>
                        <DynamicDropdownSearchable required={true} from={state.projectdetail.API + "/ParentStatus/GetAll"} label="ParentStatusType" value="Id" fetchType="get" name="fkParentStatusId" onDropdownChange={handleChange} defaultval={inputValue.fkParentStatusId} />
                        {validated && !inputValue.fkParentStatusId && <small className="text-danger">Please provide parent's status</small>}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 >Primary Contact Information</h5>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md={3}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Primary Contact<code className='required-symbol'>*</code></Form.Label>
                                <DynamicDropdownSearchable required={true} from={state.projectdetail.API + "/PrimaryContact/GetAll"} label="PrimaryContactName" value="Id" fetchType="get" name="fkPrimaryContactId" onDropdownChange={handleChange} defaultval={inputValue.fkPrimaryContactId} />
                                {validated && !inputValue.fkPrimaryContactId && <small className="text-danger">Please provide primary contact</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Address<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control required={true} rows={4} as="textarea" type="text" name="Address" value={inputValue.Address} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide address</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>State<code className='required-symbol'>*</code></Form.Label>
                                <Form.Control required={true} type="text" name="State" value={inputValue.State} onChange={handleChange} autoComplete="off" />
                                <Form.Control.Feedback type="invalid">Please provide state</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Zip Code<code className='required-symbol'>*</code></Form.Label>
                                <Form.Control required={true} type="number" name="ZipCode" maxLength={5} value={inputValue.ZipCode} onChange={handleChange} autoComplete="off" />
                                <Form.Control.Feedback type="invalid">Please provide zip code</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Mobile Number<code className='required-symbol'>*</code>
                                </Form.Label>
                                <PhoneInput required
                                    placeholder="Enter phone number"
                                    inputComponent={Form.Control}
                                    value={inputValue.MobileNumber}
                                    onChange={(val) => { handlePhoneNumberChange(val, "MobileNumber") }}
                                />
                                {/*<Form.Control required type="text" name="MobileNumber" value={inputValue.MobileNumber} onChange={handleChange} autoComplete="off" />*/}
                                {validated && !inputValue.MobileNumber && <small className="text-danger">Please provide mobile number</small>}
                                {inputValue.MobileNumber && (!isValidPhoneNumber(inputValue.MobileNumber)) && <small className="text-danger"> Invalid mobile number</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Alternative Mobile Number
                                </Form.Label>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    inputComponent={Form.Control}
                                    value={inputValue.AlternativeMobileNumber}
                                    onChange={(val) => { handlePhoneNumberChange(val, "AlternativeMobileNumber") }} />
                                {/*<Form.Control type="text" name="AlternativeMobileNumber" value={inputValue.AlternativeMobileNumber} onChange={handleChange} autoComplete="off" />*/}
                                {inputValue.AlternativeMobileNumber && (!isValidPhoneNumber(inputValue.AlternativeMobileNumber)) && <small className="text-danger"> Invalid mobile number</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Email ID<code className='required-symbol'>*</code></Form.Label>
                        <Form.Control required type="email" name="EmailID" value={inputValue.EmailID} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide email ID</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Select number to receive message</Form.Label>
                        <Form.Group>
                            <Form.Check aria-label="option 1" label="Mobile Number" onChange={handleChange} id="ReceiveMobileNumber" name="ReceiveMobileNumber" checked={inputValue.ReceiveMobileNumber} />
                            <Form.Check aria-label="option 1" disabled={!inputValue.AlternativeMobileNumber} enable={inputValue.AlternativeMobileNumber} label="Alternative Mobile Number" onChange={handleChange} id="ReceiveAlternativeMobileNumber" name="ReceiveAlternativeMobileNumber" checked={inputValue.ReceiveAlternativeMobileNumber} />
                        </Form.Group>
                    </Form.Group>
                </Col>
            </Row>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <h5>Client's Problem</h5>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row className="mb-2">
                <Col md={10}>
                    <Form.Group>
                        <Form.Label>Client's Diagnosis<code className='required-symbol'>*</code><Button variant="link" onClick={CommingSoon}><Icon className="fs-5" name="BsMicFill" /></Button></Form.Label>
                        <Form.Control as="textarea" row="8" required type="text" name="Diagnosis" value={inputValue.Diagnosis} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please provide Client's Diagnosis</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-2  mb-bottom fixed-bottom" >
                <Col md={12} className="text-center">
                    <Form.Group>
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, 3)} disabled={loading} className="float-end me-2">
                            Go to Spending Plan
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, 2)} disabled={loading} className="float-end me-2">
                            Save & Next
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>
                        <Button variant="primary" type="button" onClick={(e) => SaveData(e, 1)} disabled={loading} className="float-end me-2">
                            Save
                            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                        </Button>

                    </Form.Group>
                </Col>
            </Row>
        </Form>
    </ErrorBoundary>
    )
}
export default (PCPPersonalData);