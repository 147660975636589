import { useState, useEffect, memo } from 'react';
import { Pagination, DropdownButton, Dropdown, Row, Col } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary'

import ErrorHandler from '../ErrorHandler';
import Icon from '../Icons/Icon';

const DataGridPagination = ({ count, limit, page, offset, pageLimitList, onPageChange, onPageLimitChange }) => {  
    const [pageLimits, setPageLimits] = useState([10, 25, 50, 100]);    
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);

    useEffect(() => {
        if (pageLimitList) {
            setPageLimits(pageLimits);
        }
    }, [pageLimitList])

    useEffect(() => {
        let tpages = Math.ceil(count / limit);
        let pgs = [];
        if (tpages <= 5) {
            for (let ploop = 1; ploop <= tpages; ploop++) {
                pgs.push(ploop);
            }
        }
        else if (tpages > 5) {
            pgs.push(1);
            if (currentPage <= 2) {
                pgs.push(2);
                pgs.push(3);
                pgs.push(-1);
            }
            else if (currentPage == tpages) {
                pgs.push(-1);
                pgs.push(currentPage - 1);
            }
            else if (currentPage > tpages - 2) {
                pgs.push(-1);
                pgs.push(currentPage - 1);
                pgs.push(currentPage);
                if (currentPage + 1 < tpages) {
                    pgs.push(currentPage + 1);
                }
            }
            else {
                pgs.push(-1);
                pgs.push(currentPage - 1);
                pgs.push(currentPage);
                pgs.push(currentPage + 1);
                pgs.push(-1);
            }
            pgs.push(tpages);
        }
        setPages(pgs);
        if (pgs == 1) {
            setFrom(((page * (limit)) - limit) + 1)
            let t = (page * limit);
            if (t > count) {
                t = count;
            }
            setTo(t);
        }
        else {
            setFrom(((currentPage * (limit)) - limit) + 1);
            let t = (currentPage * limit);
            if (t > count) {
                t = count;
            }
            setTo(t);
        }
    }, [currentPage, limit, count])
    const pageChange = (value, e) => {
        onPageChange.call(this, value);
    }
    const pageLimitChange = (value, e) => {
        onPageLimitChange.call(this, value);
    }
    const setPage = (page) => {
        setCurrentPage(page);
        onPageChange.call(this, page);
    }
    const onPrevPage = () => {
        setPage(currentPage - 1);
    }
    const onNextPage = () => {
        setPage(currentPage + 1);
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Row className='data-grid-pagniation'>
                <Col md={6}>
                    {count > 0 ? (<span>Showing {from} to {to} of {count} rows</span>) : ""}
                    &nbsp;
                    <DropdownButton title={limit || ""} className='d-inline'>
                        {pageLimits.map((pagelimit, index) => {
                            return <Dropdown.Item onClick={pageLimitChange.bind(null, pagelimit)} key={index}>{pagelimit}</Dropdown.Item>;
                        })}
                    </DropdownButton> rows per page
                </Col>
                <Col md={6}>
                    {pages.length > 1 ? (
                        <Pagination className='float-end'>
                            <Pagination.Prev disabled={currentPage == 1} onClick={onPrevPage}><Icon name="BsChevronLeft" /></Pagination.Prev>
                            {pages.map((page) => {
                                if (page == -1) {
                                    return <Pagination.Ellipsis key={page} />
                                }
                                return <Pagination.Item active={currentPage == page} onClick={() => { setPage(page) }} key={page}>{page}</Pagination.Item>
                            })
                            }
                            <Pagination.Next disabled={currentPage == pages[pages.length - 1]} onClick={onNextPage}><Icon name="BsChevronRight" /></Pagination.Next>
                        </Pagination>
                    ) : ""}
                </Col>
            </Row>
        </ErrorBoundary>
    )
}
export default (memo(DataGridPagination));