import React, { memo } from 'react';
import { Row, Col, CloseButton } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useFetchWithCancellation from '../../../customhooks/useFetchWithCancellation';

import { AccessAction } from '../../../Reducer/AccessReducer';
import Icon from '../Icons/Icon';
import "./LoggedUserDetail.css";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from '../ErrorHandler';
const LoggedUserDetail = ({ handleClose }) => {
    const { fetchData } = useFetchWithCancellation();
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const logout = () => {
        window.confirm("Do you want to logout?", function () {
            fetchData(state.projectdetail.API + "/auth/logout", { method: "POST" }).then(() => {
                localStorage.removeItem("user");
                navigate("/");
            });
        });
    };
    const setTheme = (e) => {
        let value = e.target.value;
        let obj = state.access;
        obj.user.theme = value;
        dispatch({ type: AccessAction.setUser, payload: { ...obj } });
    }
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <div className='logged-in-user'>
                <div className='logged-in-user-info mb-3'>
                    <div className='container p-3'>
                        <Row>
                            <Col>
                                <h5 className='d-inline-block'>
                                    Settings
                                </h5>
                                <CloseButton className='float-end' onClick={handleClose} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'>
                                {state.access.user.photo ? <img src={("../profile/" + state.access.user.photo)} width="55" /> : <span className='profile-icon'><Icon name="BsPersonCircle" /></span>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center fw-bold'>
                                {state.access.user && state.access.user.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center fw-light'>
                                {state.access.user && state.access.user.email}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center fw-light'>
                                +1 {state.access.user && state.access.user.mobile}
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col className='col-auto'>
                                <Link onClick={handleClose} to={(state.access.user && state.access.user.fkRoleId == 2) ? "/admin/profile" : "/admin/profile"} className='text-primary'>
                                    <Icon name="BsPerson" />&nbsp;Profile
                                </Link>
                            </Col>
                            <Col className='col-auto'>
                                <a onClick={logout} href="javascript:void(0)" className="text-danger">
                                    <Icon name="BsPower" />&nbsp;Logout
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    )
}
export default (memo(LoggedUserDetail));